import React, { useState } from 'react'
import { Button, useUI } from 'app/shared'
import { PeopleIcon } from 'app/shared/components/Icons/PeopleIcon'
import { FolderIcon } from 'app/shared/components/Icons/FolderIcon'
import { CloseIcon } from 'app/shared/components/Icons/CloseIcon'
import { MenuIcon } from 'app/shared/components/Icons/MenuIcon'
import { useTransition, animated } from 'react-spring'
import { TicketIcon } from 'app/shared/components/Icons/TicketIcon'
import { MyClasses } from '../GroupClass/MyClasses'
import { MyEvents } from '../Events/MyEvents'
import { TeacherIcon } from 'app/shared/components/Icons/TeacherIcon'

const tabsList = ['Classes', 'Events', 'Tickets', 'Account'] as const
type Tabs = typeof tabsList[number]

type Props = {
  tab?: Tabs
}

export const ProfilePage = (props: Props) => {
  const { isMobile } = useUI()

  const [sidebarOpen, setSidebarOpen] = useState(!isMobile)
  const [currentTab, setCurrentTab] = useState<Tabs>(props.tab || 'Account')

  // useEffect(() => {
  //   redirectTo('/profile/' + currentTab)
  // }, [currentTab])

  const getTabIcon = (tab: Tabs) => {
    if (tab === 'Classes') return <TeacherIcon />
    if (tab === 'Events') return <FolderIcon />
    if (tab === 'Tickets') return <TicketIcon />
    if (tab === 'Account') return <PeopleIcon />
  }

  const animation = useTransition(sidebarOpen, {
    from: {
      opacity: 0,
      transform: 'translate3d(-120%, 0, 0)',
    },
    enter: {
      opacity: 1,
      transform: 'translate3d(0, 0, 0)',
    },
    leave: {
      opacity: 0,
      transform: 'translate3d(-120%, 0, 0)',
    },
  })

  const selected =
    'bg-indigo-200 text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md'
  const notSelected =
    'cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md'

  return (
    <div className='z-0 overflow-hidden'>
      <div className='flex justify-center w-screen h-20 bg-gray-800'></div>
      <div className='flex items-center space-x-2'>
        <Button onClick={() => setSidebarOpen((v) => !v)}>
          {sidebarOpen ? <CloseIcon /> : <MenuIcon />}
        </Button>
      </div>
      <div className='flex'>
        {animation(
          (styles, isOpen) =>
            isOpen && (
              <animated.div
                style={styles}
                className='min-h-large md:relative fixed flex flex-col h-screen bg-gray-200'>
                <nav className='md:w-72 px-2 mt-5 space-y-1 bg-gray-200'>
                  {tabsList.map((tab) => (
                    <button
                      className={tab === currentTab ? selected : notSelected}
                      onClick={() => setCurrentTab(tab)}>
                      {getTabIcon(tab)}
                      <span className='md:block'>{tab}</span>
                    </button>
                  ))}
                </nav>

                {/* <Mask isVisible={sidebarOpen} /> */}
              </animated.div>
            ),
        )}
        <div className='flex-grow-1 sm:p-12 min-h-large w-full p-1 pt-8'>
          {currentTab === 'Classes' && <MyClasses onClose={() => ''} />}
          {currentTab === 'Events' && <MyEvents onClose={() => ''} />}
          {/* {currentTab === 'My Account' && <Products />} */}
          {/* {currentTab === 'Dashboard' && <Products />} */}
        </div>
      </div>
    </div>
  )
}

export default ProfilePage
