import React, { useEffect, useState } from 'react'
import { usePagination } from './hooks/usePagination'
import { fuzzySearch } from './helpers/search.helpers'
import {
  getDisplayedColumns,
  generateTableData,
} from './helpers/tableData.helpers'
import { DataTableProps } from './types'
import { Search } from 'app/shared'
import { Pagination } from './components/Pagination'
import { prop, sortBy, reverse } from 'ramda'
import { OptionNode } from './components/NestedOptionsMenu'
import { parseDate } from 'chrono-node'

export function DataTable<T extends Record<string, any>>(
  props: DataTableProps<T>,
) {
  const { rowsPerPage = 8 } = props
  const [searchTerm, setSearchTerm] = useState('')
  const [schemaState, setSchemaState] = useState(props.tableSchema)
  const [tableData, setTableData] = useState<any[]>([])
  const [tableFilters /** setTableFilters */] = useState<OptionNode[]>([])
  const [sortProperty, setSortProperty] = useState<any>(
    props.defaultSort || 'name',
  )
  const [sortType, setSortType] = useState<string>('asc')

  const pagination = usePagination(rowsPerPage)

  useEffect(() => {
    setSchemaState(props.tableSchema)
  }, [props.tableSchema])

  useEffect(() => {
    if (!props.recordsList) return

    const records = sortBy((recordProp) => {
      const data = recordProp[sortProperty]
      if (typeof data === 'number') return data
      if (parseDate(data)) return new Date(parseDate(data))
      return data
    }, props.recordsList)
    //   searchTerm,
    // )

    const rows = generateTableData<T>({
      recordsList: sortType === 'desc' ? records : reverse(records),
      isSelected: props.isSelected,
      tableSchema: schemaState,
      tableFilters,
      searchTerm,
    })

    setTableData(rows)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.recordsList,
    schemaState,
    searchTerm,
    props.tableSchema,
    tableFilters,
    sortProperty,
    sortType,
  ])

  return (
    <div
      className={`min-w-full w-full h-full m-0 sm:h-[700px] max-h-screen overflow-x-auto flex flex-col justify-between items-stretch overflow-hidden`}>
      {/* Header Of Table */}
      <div className='sm:min-w-full grow-0'>
        {props.title && props.title}
        <div
          className={`overflow-hidden sm:min-w-full p-2 bg-gray-200 flex items-center space-x-2 grow-0 sm:border-none`}>
          {/* Search Bar */}
          <Search onSearch={setSearchTerm} />

          {/* Custom Filters */}
          {props.tableFilters && props.tableFilters}
        </div>
      </div>

      {/* Table Itself, Fixed Between Header/Footer */}
      <div className='w-screen sm:w-full sm:min-w-full overflow-auto grow'>
        <table className='divide-y divide-gray-200 w-full relative'>
          <thead className='bg-gray-50'>
            <tr className=''>
              {getDisplayedColumns(schemaState).map((columnTitle) => (
                <th
                  key={columnTitle}
                  scope='col'
                  className='px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase resize sticky top-0 bg-gray-50 border-b'>
                  {columnTitle}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className='bg-white divide-y divide-gray-200'>
            {pagination
              .getItemsOnPage(
                fuzzySearch(
                  tableData.map((data) => data.rowData),
                  searchTerm,
                ),
                pagination.currentPage,
              )
              .map((rowData, i) => (
                <tr key={i}>
                  {rowData.map((data: any) => (
                    <td className='whitespace-nowrap px-6 py-4 text-sm text-gray-500'>
                      {data}
                    </td>
                  ))}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Footer, Fixed to Bottom of Container */}
      <div className='w-screen  sm:w-full sm:min-w-full grow-0 bg-white border-t'>
        <Pagination
          usePagination={pagination}
          perPage={rowsPerPage}
          items={fuzzySearch(tableData.map(prop('rowData')), searchTerm)}
        />
      </div>
    </div>
  )
}
