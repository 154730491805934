import React, { useEffect } from 'react'
import { useFirestore } from 'app/firebase'
import { PageHeading } from 'app/layout/PageHeading'
import { EventCard } from '../components/Events/EventCard'
import { propEq } from 'ramda'
import { useRouter } from 'app/shared/hooks'
import { useAnalytics } from 'app/shared/hooks/useAnalytics'
import { addHours, compareAsc, isBefore } from 'date-fns'
import { Loading } from 'app/shared/components/Loading/Loading'

type Props = {}

export const EventsPage = (props: Props) => {
  const { list: events } = useFirestore('events')

  const { pageViewEvent } = useAnalytics()
  const { history } = useRouter()

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    })
    return () => {
      unlisten()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    pageViewEvent('Events Page')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <section className='relative z-0 w-screen' tabIndex={0}>
      <PageHeading title='Social Dances & Events' />

      {!events && (
        <div className='h-96 flex justify-center w-screen'>
          <Loading />
        </div>
      )}

      {/* {allEvents
        .filter(propEq('type', 'Monthly'))
        .filter(
          (event) => event && isAfter(new Date(event.dateTime), new Date()),
        ).length > 0 && <Panel bgColor='dark'>Unique Events</Panel>} */}

      {/* <div className='md:grid md:grid-cols-2 sm:max-w-6xl sm:px-10 lg:px-12 px-2 mx-auto'></div> */}

      <div className='md:grid md:grid-cols-2 sm:max-w-6xl sm:px-10 lg:px-12 px-2 mx-auto'>
        {events
          ?.filter((event) => !event.draft)
          ?.filter(
            ({ type }) =>
              type === 'Monthly' ||
              type === 'Chelsea Reed' ||
              type === 'Harmonizers Dance',
          )
          .filter(({ dateTime }) =>
            isBefore(new Date(), addHours(new Date(dateTime), 2)),
          )
          .sort((a, b) =>
            compareAsc(new Date(a.dateTime), new Date(b.dateTime)),
          )
          .map((event) => (
            <EventCard event={event} />
          ))}

        {events
          ?.filter(propEq('type', 'Weekly'))
          .filter(({ dateTime }) =>
            isBefore(new Date(), addHours(new Date(dateTime), 2)),
          )
          .sort((a, b) =>
            compareAsc(new Date(a.dateTime), new Date(b.dateTime)),
          )
          .map((event) => (
            <EventCard event={event} />
          ))}
      </div>
    </section>
  )
}

export default EventsPage
