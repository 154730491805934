import { DocumentDefaults } from 'app/shared'

export type FAQ = {
  name: string
  answer: string
  link?: string
  topics: FaqTopics[]
} & DocumentDefaults

export const faqTopics = ['Group Classes', 'Events', 'General'] as const
export type FaqTopics = typeof faqTopics[number]
