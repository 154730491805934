import { PaymentType } from 'app/firebase/functions/stripe.functions'
import {
  ActionsMenu,
  Button,
  User,
  useFirestore,
  Modal,
  Select,
  Option,
} from 'app/shared'
import { getUrl } from 'app/shared/components/Stripe/StripePaymentButton'
import { GroupClass } from 'app/stlswing/components/GroupClass/groupClass.types'
import { ProductInfo } from 'app/stlswing/pages/DashboardPage'
import { PRICE_IDS } from 'app/stlswing/stlswing.constants'
import { Event } from 'app/stlswing/stlswing.types'
import { UserSelect } from 'app/users/components/UserSelect'
import { prop } from 'lodash/fp'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

interface Props {
  isOpen: boolean
  onClose: () => void
  productId?: string
}

/**
 * THIS ONLY WORKS FOR GROUP CLASSES
 */
export const AddAttendeeForm = ({ isOpen, onClose, ...props }: Props) => {
  const { createWithId: createOrderWithId } = useFirestore('orders')
  const [user, setUser] = useState<User<true>>()
  const [paymentType, setPaymentType] = useState<PaymentType>('Full Class')

  const getPaymentType = (user: User<true>): PaymentType => {
    if (user.member) return 'Member Signup'
    if (user.studentDiscount) return 'Discounted Class'
    return paymentType || 'Full Class'
    // const type = getProductType(product)
    // if (type === 'groupClass') return paymentType
    // if (type === 'Monthly') return 'Monthly Event'
    // if (type === 'Weekly') return 'Weekly Event'
    return 'Teacher Added'
  }

  const getProductType = (product: GroupClass | Event<true>) => {
    if ('dates' in product) return 'groupClass'
    return product.type
  }

  const getStripePriceId = (product: GroupClass | Event<true>) => {
    const type = getProductType(product)
    if (type === 'groupClass')
      // @ts-ignore
      return PRICE_IDS.PROD.GROUP_CLASS[product.dates.length]
    if (type === 'Weekly') return PRICE_IDS.PROD.EVENT.WEEKLY
    if (type === 'Monthly') return PRICE_IDS.PROD.EVENT.MONTHLY
    return ''
  }

  const onAddAttendee = (user: User<true>) => {
    if (!user || !props.productId) return
    createOrderWithId({
      date: new Date().toISOString(),
      amount: 0,
      internalPaymentType: getPaymentType(user),
      // internalProductType: 'dates' in product ? 'groupClasses' : 'events',
      internalProductType: 'groupClasses',
      internalUserId: user.id,
      stripeCustomerId: user.stripeId,
      quantity: 1,
      internalProductId: props.productId,
      // @ts-ignore
      stripePriceId: getStripePriceId(product),
      internalProductUrl:
        // getUrl('dates' in product ? 'groupClasses' : 'events') +
        getUrl('groupClasses') + '/' + props.productId,
      discounts: [],
    })
  }

  return (
    <Modal
      title='Add Attendees To Class'
      type='right-panel'
      className='w-full sm:w-1/3'
      isOpen={isOpen}
      onClose={onClose}>
      <div className='p-4'>
        <UserSelect userId={user?.id} selectUser={setUser} />

        <Select<PaymentType>
          label='Payment Type'
          name='payment'
          onSelect={setPaymentType}>
          <Option label='Full Class' value='Full Class' />
          <Option label='First Half Of Class' value='First Half Of Class' />
          <Option label='Second Half Of Class' value='Second Half Of Class' />
          <Option label='Remaining Classes' value='Remaining Classes' />
          <Option label='Weekly Event' value='Weekly Event' />
          <Option label='Monthly Event' value='Monthly Event' />
          <Option label='A La Carte' value='A La Carte' />
        </Select>
      </div>

      <div className='border-t m-4 pt-2'>
        <Button
          onClick={() => {
            if (!user) {
              toast.error('Choose a User')
              return
            }
            onAddAttendee(user)
          }}>
          Add Chosen Attendee
        </Button>
      </div>
    </Modal>
  )
}
