import React, { useState, useEffect, useRef } from 'react'
import { animated, useSpring, useTransition } from 'react-spring'
import { TicketIcon } from '../Icons/TicketIcon'
import useMeasure from 'react-use-measure'

type Props = {
  text: string
  formVisible: boolean
  openForm: () => void
}

export const FloatingActionButton = ({
  text,
  formVisible,
  openForm,
}: C<Props>) => {
  const [isHovered, setIsHovered] = useState(false)
  const [hideText, setHideText] = useState(false)

  const iconAnimation = useSpring({
    transform: isHovered ? 'translate(5px)' : 'translate(0px)',
  })

  const [buttonRef, { width: buttonWidth }] = useMeasure()

  const animateContentWidth = useSpring({
    to: { width: buttonWidth },
  })

  if (formVisible) return null
  return (
    <animated.div
      className='fixed hover:scale-105 bottom-10 left-10 bg-blue-600 items-center flex cursor-pointer justify-center min-w-fit text-white px-4 py-2 rounded-full'
      style={{ ...animateContentWidth, zIndex: 100 }}
      onClick={openForm}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <div
        ref={buttonRef}
        className='flex items-center text-white justify-between text-center'>
        <p className='pr-2'>{text}</p>
        {/* {textAnimation(
          (style, hideText) =>
            !hideText && (
              <animated.p style={style} className='overflow-hidden text-white'>
                {text}
              </animated.p>
            ),
        )} */}
        <animated.div style={iconAnimation}>
          <TicketIcon className={`text-white ${hideText && 'w-5 h-5'}`} />
        </animated.div>
      </div>
    </animated.div>
  )
}
