import React from 'react'
import { StripePaymentButton } from 'app/shared/components/Stripe/StripePaymentButton'
import { GroupClass } from 'app/stlswing/stlswing.types'
import { PaymentType } from 'app/firebase/functions/stripe.functions'
import { PROD_PRICE_IDS } from 'app/stlswing/stlswing.constants'

type Props = {
  groupClass: GroupClass<true>
  amountOfClasses: number
  price: number
  discount: boolean
  paymentType: PaymentType
}
export const MembershipPaymentButton = ({
  groupClass,
  discount,
  amountOfClasses,
  price,
  paymentType,
}: Props) => (
  <StripePaymentButton
    credits={amountOfClasses}
    stripePriceId={PROD_PRICE_IDS.MEMBERSHIP}
    buttonClasses={
      'block w-full py-2 mt-8 text-3xl font-extrabold text-center text-white bg-gray-800 border border-gray-800 rounded-md hover:bg-gray-900'
    }
    internalProductId={groupClass.id}
    internalProductType='groupClasses'
    internalPaymentType={paymentType}
    quantity={1}
    className='hover:bg-gray-900 block w-full py-2 mt-8 text-3xl font-extrabold text-center text-white bg-gray-800 border border-gray-800 rounded-md'>
    <span className={`${discount && 'line-through pr-2 text-red-600'}`}>
      ${price} / Mo
    </span>
  </StripePaymentButton>
)
