import React, { useEffect, useState } from 'react'
import { Loading, Modal } from 'app/shared'
import { H3 } from 'app/shared/components/Layout/HTMLElements'
import { formatDate } from 'app/stlswing/helpers/date.helpers'
import { useUsers } from 'app/stlswing/hooks'
import { GroupClass } from 'app/stlswing/stlswing.types'
import { compareAsc, formatDistance, isPast } from 'date-fns'
import { groupBy, prop, uniqBy } from 'ramda'
import { useFirestore, usePermissions } from 'app/firebase'
import { DetailsSection } from '../Shared/DetailsSection'
import { AttendeesTable } from '../Shared/AttendeesTable/AttendeesTable'
import { RecapsModal } from './Recaps'
import { GroupClassSignupModal } from './components/GroupClassSignup/GroupClassSignupModal'
import { ModalHeader } from 'app/shared/components/Modal/components/ModalHeader'

type Props = {
  onClose: () => void
}
export const MyClasses = (props: Props) => {
  const [currentClasses, setCurrentClasses] = useState<GroupClass<true>[]>()
  const [pastClasses, setPastClasses] = useState<GroupClass<true>[]>()
  const { currentUserGroupClassess, userHasGroupClass } = useUsers()
  const [selectedClass, setSelectedClass] = useState<GroupClass<true>>()
  const [selectedRecaps, setSelectedRecaps] = useState<GroupClass<true>>()
  const { hasAnyRole } = usePermissions()
  const { list: orders } = useFirestore('orders')
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    // This timeout solves the animation issue.  For some reason, the javascript is blocking the animation causing huge performance issues.
    setTimeout(() => {
      const { current, past } = groupBy((groupClass: GroupClass<true>) => {
        if (isPast(new Date(groupClass.dates[groupClass.dates.length - 1])))
          return 'past'
        return 'current'
      }, uniqBy(prop('id'), currentUserGroupClassess))

      setLoading(false)
      setCurrentClasses(current || [])
      setPastClasses(past || [])
    }, 1000)
  }, [currentUserGroupClassess])

  if (loading || !currentClasses)
    return <Loading className='w-96 h-96 flex justify-center text-red-800' />
  return (
    <>
      <div className='flow sm:max-w-5xl h-full px-2 mt-3 overflow-hidden overflow-y-auto'>
        {currentUserGroupClassess.length === 0 ? (
          <p>You don't have any classes yet</p>
        ) : (
          <ul className='h-full'>
            {currentClasses && (
              <div className='h-1/2 overflow-y-auto border-b'>
                <H3 className='align-start justify-start'>Current Classes</H3>
                <div className='divide-y divide-gray-200'>
                  {currentClasses.map((groupClass) => (
                    <ClassListItem
                      onClose={props.onClose}
                      hasClass={userHasGroupClass}
                      groupClass={groupClass}
                      type='current'
                      setDetailsOpen={() => setSelectedClass(groupClass)}
                      setChatOpen={() => {}}
                      setRecapsOpen={
                        !!groupClass?.recaps
                          ? () => setSelectedRecaps(groupClass)
                          : undefined
                      }
                    />
                  ))}
                </div>
              </div>
            )}

            {!hasAnyRole(['employee', 'admin']) && (
              <div className='h-2/5 mt-3 overflow-y-auto'>
                <H3 className='align-start justify-start'>Past Classes</H3>
                <div className='h-full overflow-y-auto divide-y divide-gray-200'>
                  {pastClasses &&
                    pastClasses
                      .sort((a, b) =>
                        compareAsc(
                          new Date(b.dates[b.dates.length - 1]),
                          new Date(a.dates[a.dates.length - 1]),
                        ),
                      )
                      .map((groupClass) => (
                        <ClassListItem
                          onClose={props.onClose}
                          hasClass={userHasGroupClass}
                          groupClass={groupClass}
                          type='past'
                          setDetailsOpen={() => setSelectedClass(groupClass)}
                        />
                      ))}
                </div>
              </div>
            )}
          </ul>
        )}
      </div>

      {/* Details Modal */}
      <Modal
        type='left-panel'
        hideTitle
        disallowRouting
        title='Class Details'
        onClose={() => setSelectedClass(undefined)}
        className='w-screen sm:w-full h-screen overflow-y-auto overflow-x-hidden'
        isOpen={!!selectedClass}>
        {hasAnyRole(['admin', 'employee']) ? (
          selectedClass && (
            <div>
              <AttendeesTable
                productId={selectedClass?.id}
                attendees={
                  orders
                    ? orders
                        ?.filter(
                          (o) => o.internalProductId === selectedClass?.id,
                        )
                        .map((i) => i.internalUserId)
                    : []
                }
                header={
                  <ModalHeader
                    onClose={() => setSelectedClass(undefined)}
                    title={`${selectedClass?.name} Attendees`}
                  />
                }
              />
            </div>
          )
        ) : (
          <DetailsSection hideTitle {...selectedClass} />
        )}
      </Modal>

      {selectedRecaps && (
        <RecapsModal
          isOpen={!!selectedRecaps}
          onClose={() => setSelectedRecaps(undefined)}
          recaps={selectedRecaps.recaps}
        />
      )}
    </>
  )
}

const ClassListItem = ({
  groupClass,
  type,
  setDetailsOpen,
  setChatOpen,
  setRecapsOpen,
  hasClass,
  onClose,
}: {
  groupClass: GroupClass<true>
  type: 'past' | 'current'
  setDetailsOpen: () => void
  setChatOpen?: () => void
  setRecapsOpen?: () => void
  onClose: () => void
  hasClass: (id: string) => boolean
}) => {
  const [signUpOpen, setSignupOpen] = useState(false)
  const { hasAnyRole } = usePermissions()
  // const { navigateTo } = useRouter()
  const buttonClasses =
    'inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50'

  return (
    <li key={groupClass.id} className='px-2 py-4'>
      <GroupClassSignupModal
        groupClass={groupClass}
        isVisible={signUpOpen}
        onClose={() => setSignupOpen(false)}
      />
      <div className='flex items-center space-x-4'>
        <div className='flex-1 min-w-0'>
          <p className='text-sm font-medium text-gray-900 truncate'>
            {groupClass.name}
          </p>
          <p className='text-sm text-gray-500 truncate'>
            {type === 'current'
              ? formatDate('day', groupClass.dates[0]) +
                's at ' +
                formatDate('time', groupClass.dates[0])
              : formatDistance(
                  new Date(groupClass.dates[groupClass.dates.length - 1]),
                  new Date(),
                  {
                    addSuffix: true,
                  },
                )}
          </p>
        </div>
        <div className='mt-2 space-x-1'>
          {/* <button
            className={buttonClasses}
            onClick={() => {
              navigateTo(`/swing-dance-classes/groupClasses/${groupClass.id}`)
              onClose()
            }}>
            View Page
          </button> */}
          {!!setDetailsOpen && (
            <button className={buttonClasses} onClick={setDetailsOpen}>
              {hasAnyRole(['employee', 'admin']) ? 'Attendees' : 'Details'}
            </button>
          )}
          {!!setRecapsOpen && (
            <button className={buttonClasses} onClick={setRecapsOpen}>
              Recaps
            </button>
          )}
          {type === 'current' && hasClass(groupClass.id) && !!setRecapsOpen && (
            <button className={buttonClasses} onClick={setRecapsOpen}>
              Recaps
            </button>
          )}
        </div>
        {
          hasClass(groupClass.id) && type === 'current' && (
            <div className='mt-2 space-x-1'>
              {!!setRecapsOpen && (
                <button className={buttonClasses} onClick={setRecapsOpen}>
                  Recaps
                </button>
              )}
            </div>
          )
          // (
          //   <div className='mt-2 space-x-1'>
          //     <button
          //       className={buttonClasses}
          //       onClick={() => setSignupOpen(true)}>
          //       Buy Another Ticket
          //     </button>
          //     {!!setRecapsOpen && (
          //       <button className={buttonClasses} onClick={setRecapsOpen}>
          //         Recaps
          //       </button>
          //     )}
          //   </div>
          // )
        }
      </div>
    </li>
  )
}
