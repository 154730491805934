import {
  ProcessReaderPaymentRequest,
  StartReaderTransactionParams,
} from 'app/firebase/functions/firebase.functions'
import { CartItem } from './terminal.types'

export const a = ''

export const ALCHOHOL_TAX_RATE = 0.09679
export const TEST_READER_ID = 'tmr_EmPVIgZhoBMfEa'

export const getTotalTaxAmount = (cartItems: CartItem[]) =>
  Number(
    cartItems
      .filter((item) => item.type === 'alchohol')
      .reduce(
        (totalTax, currentItem) =>
          currentItem.amount * ALCHOHOL_TAX_RATE + totalTax,
        0,
      )
      .toFixed(2),
  )

export const getCartTotal = (cartItems: CartItem[]) =>
  Number(
    cartItems.reduce((total, { amount }) => total + amount, 0).toFixed(2),
  ) + getTotalTaxAmount(cartItems)

export const getReaderRequestParams = (
  readerId: string,
  cartItems: CartItem[],
) => {
  const tax = getTotalTaxAmount(cartItems)
  const total = getCartTotal(cartItems)

  const startReaderParams: StartReaderTransactionParams = {
    readerId,
    tax,
    total,
    cartItems: cartItems.map((i) => ({ ...i, quantity: 1 })),
  }

  const processReaderPaymentParams: ProcessReaderPaymentRequest = {
    readerId,
    amount: total,
    description: 'All Items',
  }

  return { startReaderParams, processReaderPaymentParams }
}
