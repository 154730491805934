import React, { useContext, useEffect, useState } from 'react'
import { CurrentUserContext, useFirestore } from 'app/firebase'
import { PageHeading } from 'app/layout/PageHeading'
import { Button, Loading, useRouter } from 'app/shared'
import { GroupClass } from 'app/stlswing/stlswing.types'
import { Ticket } from '../Shared/Ticket'

export const GroupClassPaymentSuccess = () => {
  const { routeParams, redirectTo } = useRouter<{ id: string }>()
  const { user } = useContext(CurrentUserContext)
  const { collectionMap: groupClassMap } = useFirestore('groupClasses')
  const [groupClass, setGroupClass] = useState<GroupClass<true>>()

  useEffect(() => {
    if (!groupClassMap || !routeParams.id) return
    setGroupClass(groupClassMap[routeParams.id])
  }, [groupClassMap, routeParams.id])

  if (!groupClass) return <Loading />
  return (
    <div className='relative z-0'>
      <PageHeading
        title={
          user
            ? user?.member
              ? 'Signup Successful'
              : 'Payment Successful!'
            : 'loading...'
        }
        image='images/lhsl-home-cover.jpg'
      />

      <div className='sm:px-1/3 px-4 pt-0 pb-10 mt-0 text-white bg-gray-800'>
        <div className='mx-auto max-w-3xl flex flex-col items-center justify-center gap-5'>
          <h2 className='py-4 pt-4 text-2xl font-bold text-center'>
            Your Ticket
          </h2>
          <p className='text-md pt-2 pb-8 max-w-md'>
            Show this to the teachers when you arrive to class. You can view
            this ticket code at any time by clicking the floating 'My Tickets'
            button at the bottom of any page. (You must be logged in to view
            your tickets)
          </p>
          <Ticket
            qrCode={groupClass.qrCode}
            productName={groupClass.name}
            product={groupClass}
          />

          <div className='flex justify-center'>
            <Button
              variant='raised'
              onClick={() =>
                redirectTo(
                  '/swing-dance-classes/groupClasses/' + routeParams.id,
                )
              }>
              Back to Class Page
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
