import React, { useState, useEffect, useMemo, useContext } from 'react'
import { useFirestore, User } from 'app/firebase'
import {
  ExpansionPanel,
  Search,
  AvatarHeading,
  DataTable,
  TableSchema,
  useUI,
  fuzzySearch,
  useWindowSize,
} from 'app/shared'
import { CheckboxSolidIcon } from 'app/shared/components/Icons/CheckboxSolidIcon'
import { UserForm } from 'app/users/components/UserForm'
import { prop, isEmpty, omit } from 'ramda'
import { Modal } from 'app/shared/components'
import { Order } from '../../Accounting/finance.types'
import { formatDate } from 'app/stlswing/helpers/date.helpers'
import { SendEmailsForm } from '../SendEmailsForm'
import { FirestoreContext } from 'app/firebase/firestore/FirestoreContext'
import { PaymentForm } from '../../Accounting/modals/OrdersForm'
import {
  AttendeeActionsColumn,
  getActionColumn,
} from './components/AttendeeActionsColumn'
import { AttendeeVaccinationColumn } from './components/AttendeeVaccinationColumn'
import {
  AttendeeTableFilters,
  filterAttendeePredicate,
} from './helpers/attendeesTable.helpers'
import { AttendeesTableActions } from './components/Header/AttendeesTableActions'
import { AttendeesTableFilters } from './components/Header/AttendeesTableFilters'
import { AttendeesInfo } from './components/Header/AttendeesInfo'
import { DataTableModal } from 'app/shared/components/Table/DataTableModal'
import { ProductInfo } from 'app/stlswing/pages/DashboardPage'
import { GroupClass, GroupClassLevels } from '../../GroupClass/groupClass.types'
import { Event } from 'app/stlswing/stlswing.types'
import { AddAttendeeForm } from './components/Header/AddAttendeeForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
const getUserOrder = (
  uid: string,
  orders: Order[],
  productId?: string | undefined,
) =>
  orders
    ?.filter((order) => order.internalUserId === uid)
    .find((order) => order.internalProductId === productId)

type Props = {
  attendees?: string[]
  productInfo?: ProductInfo<Event<true>> | ProductInfo<GroupClass>
  header?: React.ReactNode
  productId?: string
  order?: Order
  remove?: (attendeeId: string) => void
}

export const AttendeesTable = (props: C<Props>) => {
  const [selectedUser, setSelectedUser] = useState<User<true>>()
  const [selectedOrders, setSelectedOrders] = useState<any>()
  const { map: userMap, update: updateUser } = useFirestore('users')
  const { list: orders, update: updateOrder } = useFirestore('orders')
  const { apiState } = useContext(FirestoreContext)
  const { isMobile } = useUI()
  const [orderToEdit, setOrderToEdit] = useState<Order>()
  const [emailReceivers, setEmailRecievers] = useState<User<true>[]>()
  const [attendeesList, setAttendeesList] = useState<User<true>[]>([])
  const [addAttendeesModalOpen, setAddAttendeesModalOpen] = useState(false)

  const [height, width] = useWindowSize()

  const [filters, setFilters] = useState<AttendeeTableFilters>({
    position: [],
    experience: [],
    userType: [],
    orderType: [],
  })

  const users = useMemo(
    () =>
      userMap && props.attendees
        ? props.attendees?.map((id) => userMap[id])
        : [],
    [props.attendees, userMap],
  )

  useEffect(() => {
    if (!props.attendees || !userMap) return
    setAttendeesList(props.attendees?.map((id) => userMap[id]))
  }, [props.attendees, userMap])

  useEffect(() => {
    // If filters are empty, don't do anything
    if (Object.values(filters).every(isEmpty)) return setAttendeesList(users)

    if (!orders) return
    const predicate = (user: User<true>) =>
      filterAttendeePredicate(
        user,
        filters,
        getUserOrder(user.id, orders, props.productId),
      )

    setAttendeesList(users.filter(predicate))
  }, [filters, users])
  // prettier-ignore

  // prettier-ignore
  type columnNames = | 'Name' | 'Discount' | 'Payment' | 'Vacc' | 'Edit' | 'Info' | 'Orders' | 'Actions' | 'Role' | 'Experience'
  const makeTableSchema = (
    getActionColumn: (user: User<true>) => React.ReactNode,
    orders: Order[] | undefined,
    productId?: string,
    productInfo?: ProductInfo<GroupClass | Event<true>>,
  ): TableSchema<User<true>, columnNames> => ({
    Name: (user) => (
      <AvatarHeading
        width='180'
        title={user.displayName}
        subText={user.email}
        image={user.photoUrl}
      />
    ),
    Actions: getActionColumn,
    Role: (user) => user.position,
    Experience: (user) => user.experience,
    Payment: (user) => {
      if (productInfo) {
        return productInfo?.orders.filter(
          (order) => order.internalUserId === user.id,
        )
      } else {
        return (
          productId &&
          orders &&
          getUserOrder(user.id, orders, productId)?.internalPaymentType
        )
      }
    },
    Discount: (user) =>
      user.studentDiscount && (
        <CheckboxSolidIcon className='w-8 h-8 text-green-800' />
      ),
  })

  const getUserActionColumn = (user: User<true>) =>
    getActionColumn({
      user,
      setEmailRecievers: setEmailRecievers,
      setOrderToEdit: setOrderToEdit,
      setUserOrderHistory: setSelectedOrders,
      setUserToEdit: setSelectedUser,
      userOrders:
        orders?.filter(({ internalUserId }) => internalUserId === user.id) ||
        [],
    })

  const toOmit = users.every((u) => u?.vaccinated) ? ['Vacc'] : []

  if (attendeesList.length === 0) return null
  return (
    <>
      {/* Container */}
      <div
        style={{ height: height, maxHeight: height }}
        className='w-screen sm:max-w-screen overflow-hidden overflow-y-auto sm:pb-0'>
        {attendeesList && (
          <DataTable<User<true>>
            title={
              <div>
                <div>{props.header && props.header}</div>
                {props.productId && (
                  <ExpansionPanel
                    title={`Attendees Info (${props.attendees?.length} total / ${attendeesList.length} showing)`}>
                    <AttendeesInfo
                      attendees={users}
                      orders={orders || []}
                      productId={props.productId}
                    />
                  </ExpansionPanel>
                )}
              </div>
            }
            tableSchema={omit(
              toOmit,
              makeTableSchema(
                getUserActionColumn,
                orders || [],
                props.productId,
              ),
            )}
            hideSort
            recordsList={attendeesList}
            tableFilters={
              <>
                <div className='rounded-full bg-white border flex justify-center items-center'>
                  <AttendeesTableActions
                    setEmailReceivers={setEmailRecievers}
                    addAttendeeToClass={() => setAddAttendeesModalOpen(true)}
                    allAttendees={users}
                  />
                </div>
                <div className='rounded-full bg-white border  flex justify-center items-center'>
                  <AttendeesTableFilters
                    filters={filters}
                    setFilters={setFilters}
                    attendeesList={attendeesList}
                  />
                </div>
              </>
            }
          />
        )}
      </div>

      <DataTableModal<Order>
        recordsList={selectedOrders}
        tableSchema={{
          Name: (order) => {
            const orderState = apiState[order.internalProductType]
            if (!orderState) return 'unknown'
            const productName = orderState[order.internalProductId]
            if (!productName) return 'unknown'
            return productName.name
          },
          Type: prop('internalPaymentType'),
          Amount: prop('amount'),
          Date: (order) =>
            formatDate('readable-date-and-time', new Date(order.date)),
        }}
        isOpen={!!selectedOrders}
        onClose={() => setSelectedOrders(undefined)}
      />

      <Modal
        title='User Orders'
        isOpen={!!selectedOrders}
        className='w-screen overflow-y-scroll'
        onClose={() => setSelectedOrders(undefined)}
        type='left-panel'>
        <DataTable<Order>
          recordsList={selectedOrders}
          tableSchema={{
            Name: (order) => {
              const orderState = apiState[order.internalProductType]
              if (!orderState) return 'unknown'
              const productName = orderState[order.internalProductId]
              if (!productName) return 'unknown'
              return productName.name
            },
            Credits: (order) => (
              <div className='flex flex-col items-center justify-center space-y-2'>
                <button
                  onClick={() => {
                    if (!order.id) return
                    updateOrder(order.id, {
                      ...order,
                      credits: (order?.credits || 0) + 1,
                    })
                  }}>
                  <FontAwesomeIcon icon={faChevronUp} />
                </button>
                <p>{!order.credits ? 0 : order.credits}</p>

                <button
                  onClick={() => {
                    if (!order.id) return
                    updateOrder(order.id, {
                      ...order,
                      credits: (order?.credits || 0) + 1,
                    })
                  }}>
                  <FontAwesomeIcon icon={faChevronDown} />
                </button>
              </div>
            ),
            Type: prop('internalPaymentType'),
            Amount: prop('amount'),
            Date: (order) =>
              formatDate('readable-date-and-time', new Date(order.date)),
          }}
        />
      </Modal>

      <AddAttendeeForm
        isOpen={addAttendeesModalOpen}
        onClose={() => setAddAttendeesModalOpen(false)}
        productId={props.productId}
      />

      <SendEmailsForm
        title='Send An Email To Students'
        isOpen={!!emailReceivers}
        closeForm={() => setEmailRecievers(undefined)}
        emailAddresses={emailReceivers}
      />

      <UserForm
        isVisible={!!selectedUser}
        onClose={() => setSelectedUser(undefined)}
        user={selectedUser}
      />

      <PaymentForm
        isOpen={!!orderToEdit}
        onClose={() => setOrderToEdit(undefined)}
        order={orderToEdit}
      />
    </>
  )
}
