import React, { useEffect, useState } from 'react'
import { ButtonProps, Button, Loading } from 'app/shared'

type Props = {
  onClick: () => void
} & ButtonProps

export const ConfirmationButton = ({ onClick, children, ...props }: Props) => {
  const [confirming, setConfirming] = useState(false)

  useEffect(() => {
    if (!confirming) return
    setTimeout(() => setConfirming(false), 2000)
  }, [confirming])

  return (
    <Button
      variant={confirming ? 'warn' : 'raised'}
      type='button'
      onClick={(e) => {
        e.stopPropagation()
        if (confirming) {
          onClick()
        } else {
          setConfirming(true)
        }
      }}>
      <div className='flex items-center space-x-2'>
        {!confirming ? children : 'confirm'}
      </div>
    </Button>
  )
}
