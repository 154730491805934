import { Option, Select } from 'app/shared'
import { CalendarHeader } from 'app/shared/components/Calendar/components'
import { DayPicker } from 'app/shared/components/Field/Date/DatePicker/DayPicker'
import { MultipleDayPicker } from 'app/shared/components/Field/Date/DatePicker/MultipleDayPicker'
import { InfoBox } from 'app/shared/components/Layout/Cards/InfoBox'
import { Step, Stepper } from 'app/shared/components/Stepper'
import { addOrRemove } from 'app/shared/utils/arrays'
import { formatDate } from 'app/stlswing/helpers/date.helpers'
import { addHours, addMinutes, compareAsc } from 'date-fns'
import { set, setHours } from 'date-fns/fp'
import { format } from 'path'
import { fromPairs, range } from 'ramda'
import { useEffect, useState } from 'react'

export type Availability = Record<string, Date[]>

interface Props {
  onSubmit: (availability: Availability) => void
}
export const AvailabilityForm = (props: Props) => {
  const [month, setMonth] = useState(new Date())
  const [days, setDays] = useState<Date[]>([])
  const [availability, setAvailability] = useState<Availability>({})

  useEffect(() => {
    setAvailability((prevAvailability) =>
      createAvailabilityDict(days, prevAvailability),
    )
  }, [days])

  return (
    <div className='bg-white'>
      <Stepper fullPage>
        <Step
          title='Days Available'
          disabled={days.length < 1}
          className='h-full'>
          <InfoBox
            title='Select the days you are available to teach private lessons.'
            description=' You will choose the times you have available on the next page.'
          />
          <div className='h-full w-full min-h-full'>
            <CalendarHeader date={month} setDate={setMonth}></CalendarHeader>
            <MultipleDayPicker dates={days} setDates={setDays} month={month} />
          </div>
        </Step>
        <Step title='Times Available' className=''>
          <InfoBox
            title='For each day selected, you must add the hours you are available to teach.'
            description='Each of these times will be available for students to sign up for.'
          />

          <div className='grid grid-cols-2 h-full sm:grid-cols-4 gap-4 p-4 items-center'>
            {days.sort(compareAsc).map((day, i) => (
              <div
                className='flex space-x-2 justify-center items-center'
                key={`${day.toISOString()}-${i}`}>
                <Select<Date>
                  name=''
                  multiple={true}
                  defaultValues={availability[day.toISOString()] || []}
                  label={formatDate('condensed-readable', day)}
                  placeholder='Select available times'
                  // @ts-ignore
                  onSelect={(times: Date[]) => {
                    setAvailability((availability) => ({
                      ...availability,
                      [day.toISOString()]: times,
                    }))
                  }}
                  className=''>
                  {getTimes(day).map((time) => (
                    <Option
                      label={`${formatDate('time', time)} - ${formatDate(
                        'time',
                        addMinutes(time, 55),
                      )}`}
                      value={time}
                    />
                  ))}
                </Select>
              </div>
            ))}
          </div>
        </Step>

        <Step title='Confirm' onNext={() => props.onSubmit(availability)}>
          <InfoBox
            type='warning'
            title='Are You Sure?'
            description={`
              You are Adding ${Object.values(availability).length} hours of
              availability. Upon clicking complete, these will all be available
              on the website for any person to sign up and pay for. You are
              expected to be availabile during these times. Are you sure?
          `}
          />
        </Step>
      </Stepper>
    </div>
  )
}

const getTimes = (date: Date) =>
  range(8, 21).map((hour) => setHours(hour, date))

const createAvailabilityDict = (days: Date[], availability: Availability) => {
  return fromPairs(
    days.map((day) => {
      const isoDay = day.toISOString()
      const timesAvailable = availability[isoDay]
      return [isoDay, timesAvailable || []] as [string, Date[]]
    }),
  )
}
