import React from 'react'
import { PanelContent } from './PanelContent'

type Props = { title: string; panelClasses?: string }

export const ExpansionPanel = ({
  children,
  title,
  className,
  panelClasses,
}: C<Props>) => {
  return (
    <div
      className={
        'mx-auto border border-gray-300 shadow-sm rounded-md' + className
      }>
      <PanelContent title={title} className={panelClasses}>
        {children}
      </PanelContent>
    </div>
  )
}
