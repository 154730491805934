import React, { useEffect, useState } from 'react'
import { GroupClass } from 'app/stlswing/stlswing.types'
import { AttendeesTable } from '../../Shared/AttendeesTable/AttendeesTable'
import { Order } from '../finance.types'
import { allPass } from 'ramda'
import {
  Button,
  DataTable,
  TableSchema,
  useFirestore,
  Modal,
  useRouter,
  ConfirmationButton,
} from 'app/shared/'
import { classIsCurrent, hasFinished } from 'app/stlswing/helpers/date.helpers'
import { OptionNode } from 'app/shared/components/Table/components/NestedOptionsMenu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons'
import { ActionsMenu } from 'app/shared/components/Menu/ActionsMenu'
import { GroupClassFormModal } from '../..'
import { Ticket } from '../../Shared/Ticket'
import { RowFilter } from 'app/shared/components/Table/components/RowFilter'
import { AdminMobileHeader } from 'app/stlswing/pages'

export const GroupClassesDashboard = () => {
  const { list: orders } = useFirestore('orders')
  const { list, remove: removeClass } = useFirestore('groupClasses')

  const [groupClasses, setGroupClasses] = useState<GroupClass<true>[]>([])
  const [createModalOpen, setCreateModalOpen] = useState(false)
  const [classTicketViewing, setClassTicketViewing] =
    useState<GroupClass<true>>()

  const [tableFilters] = useState<OptionNode[]>([])
  const [selectedGroupClass, setSelectedGroupClass] =
    useState<GroupClass<true>>()
  const [groupClassToEdit, setGroupClassToEdit] = useState<GroupClass<true>>()
  const [showPast] = useState(true)
  const [showInSession, setShowInSession] = useState(false)

  const { redirectTo } = useRouter()

  useEffect(() => {
    if (!list) return
    // if (tableFilters.length === 0) return setGroupClasses(list)

    const dateFilters = tableFilters
      .filter(({ type }) => type === 'date')
      .map((filter) => filter.value || true)

    const filteredGroupClasses = list.filter((groupClass) => {
      const matchesDateFilter = allPass(dateFilters)(
        new Date(groupClass.dates[groupClass.dates.length - 1]),
      )

      return matchesDateFilter
    })

    if (showInSession) {
      setGroupClasses(filteredGroupClasses.filter((c) => !classIsCurrent(c)))
      return
    }
    if (showPast) {
      setGroupClasses(filteredGroupClasses.filter((c) => !hasFinished(c.dates)))
      return
    }

    setGroupClasses(filteredGroupClasses)
  }, [list, tableFilters, showPast, showInSession])

  const getAttendees = (
    groupClass: (GroupClass<true> | GroupClass<true>) | undefined,
    orders?: Order[],
  ) => {
    if (!groupClass || !orders) return []
    return orders
      .filter((o) => o.internalProductId === groupClass?.id)
      .map((i) => i.internalUserId)
  }

  type Columns = 'Name' | 'Attendees' | 'Type' | 'Date' | 'Actions' | 'Link'
  const groupClassesTableSchema: TableSchema<GroupClass<true>, Columns> = {
    Name: (groupClass) => groupClass?.name || 'N/A',
    Attendees: (groupClass) => (
      <Button
        className='text-blue-600'
        onClick={() => setSelectedGroupClass(groupClass)}>
        {getAttendees(groupClass, orders).length || 0}
      </Button>
    ),
    Actions: (groupClass) => (
      <ActionsMenu>
        <div className='w-screen sm:w-52 flex flex-col space-y-2 p-2'>
          <Button onClick={() => setGroupClassToEdit(groupClass)}>
            Edit Class
          </Button>
          <Button
            onClick={() =>
              redirectTo('/swing-dance-classes/groupClasses/' + groupClass.id)
            }>
            Go To Class Page
          </Button>
          <Button onClick={() => setClassTicketViewing(groupClass)}>
            View Ticket
          </Button>
          <ConfirmationButton onClick={() => removeClass(groupClass.id)}>
            Delete Class
          </ConfirmationButton>
        </div>
      </ActionsMenu>
    ),
  }

  return (
    <div className=' w-full overflow-hidden'>
      <AdminMobileHeader title='Group Classes'>
        <div className='p-4'>
          <Button variant='raised' onClick={() => setCreateModalOpen(true)}>
            Create Class
          </Button>
        </div>
      </AdminMobileHeader>

      <Modal
        disallowRouting
        type='right-panel'
        className='w-screen overflow-auto'
        isOpen={!!selectedGroupClass}
        onClose={() => setSelectedGroupClass(undefined)}
        title={`Attendees For ${
          selectedGroupClass?.name + ' - ' + selectedGroupClass?.id.slice(0, 3)
        }`}>
        <AttendeesTable
          productId={selectedGroupClass?.id}
          order={orders?.find(
            (order) => order.internalProductId === selectedGroupClass?.id,
          )}
          attendees={getAttendees(selectedGroupClass, orders)}
          remove={() => ''} // TODO: add this
        />
      </Modal>

      <GroupClassFormModal
        title={
          'Edit' +
          ' ' +
          groupClassToEdit?.name +
          ' - ' +
          groupClassToEdit?.id.slice(0, 5)
        }
        closeForm={() => setGroupClassToEdit(undefined)}
        isOpen={!!groupClassToEdit}
        groupClass={groupClassToEdit}
      />

      <GroupClassFormModal
        title='Create'
        closeForm={() => setCreateModalOpen(false)}
        isOpen={!!createModalOpen}
      />

      <Modal
        isOpen={!!classTicketViewing}
        disallowRouting
        onClose={() => setClassTicketViewing(undefined)}
        title='View Ticket'>
        {classTicketViewing && (
          <Ticket
            product={classTicketViewing}
            qrCode={classTicketViewing.qrCode}
            productName={classTicketViewing.name}
          />
        )}
      </Modal>

      <DataTable<GroupClass<true>>
        tableSchema={groupClassesTableSchema}
        recordsList={groupClasses}
        defaultSort='createdAt'
      />
    </div>
  )
}
