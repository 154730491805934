import { CurrentUserContext, useFirestore } from 'app/firebase'
import { Modal } from 'app/shared/components'
import React, { useContext, useState } from 'react'

import { PROD_PRICE_IDS } from 'app/stlswing/stlswing.constants'
import { Checkbox } from 'app/shared/components/Field/Checkbox'
import { GroupClassPaymentButton } from '../../../../GroupClassPaymentButton'
import {
  getTiers,
  GroupClassPricingTier,
} from './components/GroupClassPricingTier'
import { classIsCurrent } from 'app/stlswing/helpers/date.helpers'
import { MembershipPaymentButton } from '../../../../MembershipPaymentButton'
import { LoggedInGuard } from 'app/auth/LoggedInGuard'
import { GroupClass } from 'app/stlswing/components/GroupClass/groupClass.types'

type Props = {
  groupClass: GroupClass
}

export const PricingOptionsStep = ({ groupClass }: C<Props>) => {
  const { user } = useContext(CurrentUserContext)
  const [hasStudentDiscount, setHasStudentDiscount] = useState(
    user?.studentDiscount,
  )
  const { update: updateUser } = useFirestore('users')

  const onCheck = (studentDiscount: boolean) => {
    setHasStudentDiscount((v) => !v)
    if (!user) return
    updateUser(user.id, { studentDiscount })
  }

  return (
    <div className='flex flex-col items-center justify-center mx-4 mb-20'>
      {/* Experience Level */}
      <h1 className='sm:text-center my-5 text-4xl font-extrabold text-gray-900'>
        Pricing Options
      </h1>

      <div className='bg-white'>
        <div className='max-w-7xl sm:px-6 lg:px-8 pb-4 mx-auto'>
          <div className='sm:mt-8 sm:space-y-0 sm:flex sm:flex-col sm:gap-6 mt-4 space-y-4'>
            {getTiers(groupClass).map((tier) => (
              <div
                key={tier.name}
                className='divide-y divide-gray-200 rounded-lg shadow-sm'>
                <GroupClassPricingTier
                  description={tier.description}
                  included={tier.included}
                  name={tier.name}
                  price={tier.price}
                  priceId={tier.priceId}>
                  {groupClass && (
                    <GroupClassPaymentButton
                      paymentType={tier.paymentType}
                      groupClass={groupClass}
                      amountOfClasses={tier.amount}
                      price={tier.price}
                      discount={!!hasStudentDiscount}
                    />
                  )}
                </GroupClassPricingTier>
              </div>
            ))}

            <GroupClassPricingTier
              description={'Take any / all of our classes'}
              included={['All Weekly Social Dances', 'All Classes']}
              name={'STL Swing Membership'}
              price={120}
              priceId={PROD_PRICE_IDS.MEMBERSHIP}>
              {groupClass && (
                <MembershipPaymentButton
                  paymentType={'Membership'}
                  groupClass={groupClass}
                  amountOfClasses={groupClass.dates.length}
                  price={120}
                  discount={false}
                />
              )}
            </GroupClassPricingTier>
          </div>
        </div>
      </div>
    </div>
  )
}
