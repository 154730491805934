import { Assets } from 'app/shared'
import { AdminPage } from 'app/stlswing/pages'
import { AttendeesPage } from 'app/stlswing/pages/AttendeesPage'
import { CardReader } from 'app/stlswing/pages/CardReader'
import Users from 'app/users/Users'
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import { ChangelogDashboard } from '../Accounting/sections/ChangelogDashboard'
import { DatabaseHistory } from '../Accounting/sections/DatabaseHistory'
import { EventsDashboard } from '../Accounting/sections/EventsDashboard'
import { GroupClassesDashboard } from '../Accounting/sections/GroupClassesDashboard'
import { MembersDashboard } from '../Accounting/sections/MembersDashboard'
import { Orders } from '../Accounting/sections/Orders'
import { PrivateLessonsDashboard } from '../Accounting/sections/PrivateLessonsDashboard'
import { TeachersDashboard } from '../Accounting/sections/TeachersDashboard'
import { FaqsDashboard } from '../FAQ/FaqDashboard'
// const DashboardPage = React.lazy(() => import('../../pages/DashboardPage'))
import { DashboardPage } from '../../pages/DashboardPage'

export const AdminRoutes = () => {
  return (
    <>
      <div>
        <Switch>
          {/* Admin Routes */}
          {/* <Route path='/admin/Assets'>
            <AdminPage tab='Assets' />
          </Route> */}

          <Route path='/admin/Users'>
            <Users />
          </Route>

          <Route path='/admin/Changelog'>
            <ChangelogDashboard />
          </Route>

          <Route path='/admin/CardReader'>
            <CardReader />
          </Route>

          {/* <Route path='/admin/Costs'>
            <AdminPage tab='Costs' />
          </Route> */}

          <Route path='/admin/PrivateLessons'>
            <PrivateLessonsDashboard />
          </Route>

          <Route path='/admin/Members'>
            <MembersDashboard />
          </Route>

          <Route path='/admin/Teachers'>
            <TeachersDashboard />
          </Route>

          <Route path='/admin/attendees/:productType/:productId'>
            <AttendeesPage />
          </Route>

          <Route path='/admin/History'>
            <DatabaseHistory />
          </Route>

          <Route path='/admin/Orders'>
            <Orders />
          </Route>

          <Route path='/admin/Events'>
            <EventsDashboard />
          </Route>

          <Route path='/admin/Faq'>
            <FaqsDashboard />
          </Route>

          <Route path='/admin/Classes'>
            <GroupClassesDashboard />
          </Route>

          <Route path='/admin'>
            <DashboardPage />
          </Route>

          <Route path='/admin/Dashboard'>
            <DashboardPage />
          </Route>
        </Switch>
      </div>
    </>
  )
}

export default AdminRoutes
