import React, { useMemo } from 'react'
import { useFirestore } from 'app/shared'
import { anyPass, equals, not } from 'ramda'
import { useQuery } from 'react-query'
import { getFirestoreCollection } from 'app/firebase/firestore/firestore.helpers'
import { User, Permissions, Role } from 'app/shared'

export const useGetTeachers = () => {
  const { data: teachers } = useQuery(
    'teachers-info',
    async () => await getTeachers(),
  )

  return teachers || ([] as FullUserInfo[])
}

export const useGetAdmins = () => {
  const { data: admins } = useQuery(
    'admins-info',
    async () => await getAdmins(),
  )

  return admins || ([] as FullUserInfo[])
}

export type FullUserInfo = User<true> & Permissions<true>
const getTeachers = () => getUsersOfRole('employee')
const getAdmins = () => getUsersOfRole('admin')

const getUsersOfRole = async (role: Role) => {
  const teacherPermissions = await getFirestoreCollection<Permissions<true>>(
    'permissions',
    {
      where: [['role', '==', role]],
    },
  )

  const teacherIds = teacherPermissions.map(({ id }) => id)

  if (teacherIds.length === 0) return []

  const teachers = await getFirestoreCollection<User<true>>('users', {
    where: [['id', 'in', teacherIds.slice(0, 10)]],
  })

  const teacherInfo = teachers.map((teacher) => {
    const permissions = teacherPermissions.find(({ id }) => teacher.id === id)
    return {
      ...permissions,
      ...teacher,
    }
  })

  if (not(teacherInfo.every((info) => 'role' in info)))
    return [] as FullUserInfo[]

  return teacherInfo
}
