import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
  useFirestore,
  User,
  Permissions,
  Role,
  Clearance,
  usePermissions,
  USER_POSITION,
  CurrentUserContext,
} from '../../firebase'
import {
  Input,
  Select,
  Option,
  FormModal,
  InlineDescriptionSlider,
  Slider,
} from 'app/shared/components'
import { EXPERIENCE_LEVEL } from 'app/stlswing/stlswing.constants'
import { DanceExperienceSelect } from 'app/stlswing/components/GroupClass/components/GroupClassSignup/components/AttendeeInfoFormStep/components/DanceExperienceSelect'
import { DancePositionSelect } from 'app/stlswing/components/GroupClass/components/GroupClassSignup/components/AttendeeInfoFormStep/components/DancePositionSelect'
import { useUI } from 'app/shared'
import { UserUpdatesFields } from './UserUpdatesFields'

type Props = {
  user?: User<true>
  isVisible: boolean
  onClose: () => void
}

export const UserForm = ({ user, ...props }: Props) => {
  const { update: updateUser } = useFirestore('users')
  const { update: updatePermissions, map: permissionsMap } =
    useFirestore('permissions')
  const { hasRole, hasAnyRole } = usePermissions()

  const { isMobile } = useUI()

  const [currPermissions, setCurrPermissions] = useState<Permissions>()

  const form = useForm()

  useEffect(() => {
    if (!user || !permissionsMap) return
    setCurrPermissions(permissionsMap[user.id])
  }, [permissionsMap, user])

  type FormData = {
    role: Role
    clearance: Clearance
  } & Pick<
    User,
    // prettier-ignore
    | 'displayName'
    | 'email'
    | 'member'
    | 'studentDiscount'
    | 'position'
    | 'experience'
  >

  const onSubmit = async ({ role, clearance, ...formData }: FormData) => {
    if (!user || !currPermissions) return

    updatePermissions(user.id, {
      role,
      clearance,
    })

    updateUser(user.id, {
      ...user,
      ...formData,
    })

    props.onClose()
  }

  return (
    <FormModal
      className='sm:w-1/2 w-screen overflow-auto'
      title={'Edit ' + user?.displayName || 'Unnamed User'}
      onSubmit={form.handleSubmit<FormData>(onSubmit)}
      isOpen={props.isVisible}
      onClose={props.onClose}
      form={form}>
      <div className='p-8'>
        <ul role='list' className='mt-2 divide-y divide-gray-200'>
          <div className='pb-2 flex items-center justify-between'>
            <p className='h-full hidden sm:flex font-medium text-sm items-center w-full'>
              Display Name
            </p>
            <Input
              name='displayName'
              hideLabel={!isMobile}
              className='w-full'
              label='Display Name'
              form={form}
              defaultValue={user?.displayName ? user.displayName : ''}
            />
          </div>

          <div className='pb-2 flex items-center justify-between'>
            <p className='h-full font-medium text-sm hidden sm:flex items-center w-full'>
              Email
            </p>
            <Input
              className='w-full'
              hideLabel={!isMobile}
              name='email'
              label='Email'
              form={form}
              defaultValue={user?.email ? user.email : 'no email'}
            />
          </div>

          <Slider
            form={form}
            label='Membership'
            name='member'
            description='a member pays a recurring fee and receives entrance into all of our weekly classes'
            defaultValue={user?.member}
          />

          <Slider
            form={form}
            name='vaccinated'
            label='Vaccinated'
            defaultValue={user?.vaccinated}
            description='Has someone checked their vaccination card?'
          />

          <Slider
            form={form}
            label='Student Discount'
            name='studentDiscount'
            defaultValue={user?.studentDiscount}
            description='is this user elligible for a student discount'
          />

          <div className='pb-2 flex items-center justify-between'>
            <p className='h-full font-medium text-sm flex items-center w-full'>
              Experience
            </p>
            <Select
              form={form}
              noLabel
              label='Time Spent Dancing'
              name='experience'
              defaultValues={user?.experience ? [user.experience] : []}>
              {EXPERIENCE_LEVEL.map((exp) => (
                <Option value={exp} label={exp} />
              ))}
            </Select>
          </div>
          <div className='pb-2 flex items-center justify-between'>
            <p className='h-full font-medium text-sm flex items-center w-full'>
              Position
            </p>
            <Select
              form={form}
              noLabel
              label='Position'
              name='position'
              defaultValues={user?.position ? [user.position] : []}>
              {USER_POSITION.map((position) => (
                <Option value={position} label={position} />
              ))}
            </Select>
          </div>
        </ul>
      </div>
      {hasRole('admin') && currPermissions && (
        <div className='pb-8 border-b'>
          <h2 className=' text-xl font-medium text-center'>Admin Controls</h2>
          <p>{user?.id}</p>
          <div className='grid grid-cols-2 gap-1'>
            <Select
              form={form}
              label='Permissions'
              name='role'
              defaultValues={[currPermissions.role]}>
              <Option value='customer' label='Student' />
              <Option value='employee' label='Teacher' />
              <Option value='admin' label='Administrator' />
            </Select>
            <Select
              form={form}
              label='Clearance'
              name='clearance'
              defaultValues={['' + currPermissions.clearance]}>
              <Option value='1' label='1' />
              <Option value='2' label='2' />
              <Option value='3' label='3' />
              <Option value='4' label='4' />
            </Select>
          </div>
          <div className='flex space-x-3'></div>
        </div>
      )}
      {/* {hasAnyRole(['employee', 'admin']) &&
        currPermissions?.role === 'employee' && <UserUpdatesFields />} */}
    </FormModal>
  )
}
