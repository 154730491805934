import React, { useState, useContext } from 'react'
import { User, CurrentUserContext, useFirestore } from 'app/firebase'
import Camera from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'

import { set } from 'lodash/fp'
import { ActualFileObject } from 'filepond'

import { uploadFile } from 'app/shared/components/Assets/helpers/asset.helpers'
import { head } from 'lodash'
import { toast } from 'react-toastify'
import { FirestoreImage } from 'app/shared/components/Assets/components/FirestoreImage'
import { FilePond } from 'react-filepond'

interface Props {
  user: User<true>
}

export const StudentDiscountSelect = (props: Props) => {
  const { user, setUser } = useContext(CurrentUserContext)
  const { update: updateUser } = useFirestore('users')
  const [isStudent, setIsStudent] = useState(props.user.studentDiscount)
  const [files, setFiles] = useState<ActualFileObject[]>([])

  const updateUserDiscount = (studentDiscount: boolean) => {
    if (!user) return
    setUser(set('studentDiscount', studentDiscount))
    updateUser(user.id, { studentDiscount })
  }

  const onUploadStudentId = async (file: File) => {
    if (!user) return
    await uploadFile(file)
      .then(async ({ ref }) => {
        console.log('ref', ref)
        await updateUser(user.id, { studentIdImagePath: ref.fullPath })
        setUser(set('studentIdImagePath', ref.fullPath))
        toast.success('Updated your account with new student ID')
      })
      .catch((err) => toast.error(err))
  }

  return (
    <div>
      <div className='flex w-full items-stretch h-16 space-x-1 p-2'>
        <button
          onClick={() => updateUserDiscount(true)}
          className={`border rounded-md p-1 w-full flex cursor-pointer justify-center items-center ${
            user?.studentDiscount ? 'bg-blue-300' : 'hover:bg-blue-50'
          }`}>
          <p className={`font-semibold text-xl mx-auto`}>Yes</p>
        </button>
        <button
          onClick={() => updateUserDiscount(false)}
          className={`border rounded-md p-1 w-full flex cursor-pointer justify-center items-center ${
            !user?.studentDiscount ? 'bg-blue-300' : 'hover:bg-blue-50'
          }`}>
          <p className={`font-semibold text-xl mx-auto`}>No</p>
        </button>
      </div>

      {user?.studentDiscount && !user.studentIdImagePath && (
        <>
          {files.length === 0 && (
            <p className='mx-auto font-semibold w-full flex justify-center p-2'>
              You must add a photo of your student ID to continue
            </p>
          )}
          <FilePond
            files={files}
            acceptedFileTypes={['image/*']}
            onupdatefiles={(filePondFiles) => {
              // @ts-ignore
              const file = head(filePondFiles.map(({ file }) => file)) as File
              onUploadStudentId(file)
            }}
            allowMultiple={false}
            allowReplace
            allowRevert
            fileRenameFunction={(fileData) => props.user.id + fileData.name}
            maxFiles={1}
            name='files'
            labelIdle='Drag & Drop your student ID or <span class="filepond--label-action">Browse</span>'
            // @ts-ignore
            credits={false}
          />
        </>
      )}

      {/* <Camera onTakePhoto={console.log} /> */}

      {user?.studentIdImagePath && (
        <FirestoreImage
          fullPath={user.studentIdImagePath}
          type='image'
          alt='student id picture'
        />
      )}
    </div>
  )
}
