import { toast } from 'react-toastify'
import {
  useRouter,
  useFirestore,
  Loading,
  CurrentUserContext,
  UIContext,
  usePermissions,
  Button,
} from 'app/shared'

import { useContext, useEffect, useState } from 'react'
import { Order } from '../Accounting/finance.types'
import { Ticket } from '../Shared/Ticket'
import { GroupClass } from '../GroupClass/groupClass.types'
import { Event } from 'app/stlswing/stlswing.types'
import { ViewAsMenu } from 'app/layout/header'
import { GoogleOAuth } from 'app/auth/GoogleOauth'
import { useUsers } from 'app/stlswing/hooks'

export const ViewTicket = () => {
  const { getSingle } = useFirestore('orders')
  const { hasAnyRole, isLoggedIn } = usePermissions()

  const { routeParams } = useRouter<{
    orderId: string
  }>()

  const [order, setOrder] = useState<Order>()
  const { getSingle: getEvent } = useFirestore('events')
  const { getSingle: getGroupClass } = useFirestore('groupClasses')

  const [product, setProduct] = useState<GroupClass | Event<true>>()
  const { orders: userOrders } = useUsers()
  const { user, logout, permissions, userLoading } =
    useContext(CurrentUserContext)
  const { setShowFooter, setShowHeader } = useContext(UIContext)
  const [notLoggedInDelay, setNotLoggedInDelay] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setNotLoggedInDelay(true)
    }, 3000)
  }, [])

  useEffect(() => {
    if (!routeParams.orderId) return
    // @ts-ignore
    getSingle(routeParams.orderId).then(setOrder)
  }, [routeParams])

  useEffect(() => {
    setShowFooter(false)
    setShowHeader(false)

    return () => {
      setShowFooter(true)
      setShowHeader(true)
    }
  }, [])

  useEffect(() => {
    if (!order) return
    if (order.internalProductType === 'groupClasses') {
      // @ts-ignore
      getGroupClass(order.internalProductId).then(setProduct)
    }
    if (order.internalProductType === 'events') {
      // @ts-ignore
      getEvent(order.internalProductId).then(setProduct)
    }
  }, [order])

  const isTicketForUser = () => {
    return product && order && user && user.id === order.internalUserId
  }

  if (!order && !product && userLoading && !user)
    return (
      <div className='h-screen w-screen flex items-center justify-center p-4'>
        <Loading className='h-64 w-64 ' color='purple' />
      </div>
    )

  if (!isLoggedIn() && notLoggedInDelay)
    return (
      <div className='h-screen w-screen flex items-center justify-center p-4'>
        <div className='bg-gray-800 p-10 rounded-lg w-full flex max-w-md space-y-4 flex-col mx-auto justify-center items-center'>
          <p className='font-bold text-center text-red-400 text-2xl '>
            To view your ticket, please sign in.
          </p>

          <p className=' text-red-300 text-xs max-w-sm pb-2'>
            Make sure to use the same gmail account that you initially logged in
            with.
          </p>

          <GoogleOAuth afterLogin={() => {}} />
        </div>
      </div>
    )
  return (
    <div className='h-screen w-screen flex items-center justify-center p-4'>
      {/* {!isTicketForUser() ? (
        <div className='h-full w-full flex max-w-md space-y-4 flex-col mx-auto justify-center items-center'>
          <p className='font-bold text-center text-red-800 text-xl '>
            This ticket does not belong to the currently logged in account.
            Please try a different account.
          </p>
          <div className='flex bg-gray-800'>
            <Button variant='raised' onClick={logout}>
              Logout
            </Button>
            {hasAnyRole(['employee', 'admin']) && <ViewAsMenu />}
          </div>
        </div>
      ) : ( */}
      {product && (
        <Ticket
          product={product}
          productName={product.name}
          quantity={
            userOrders?.filter(
              (order) => order.internalProductId === product.id,
            ).length || 1
          }
          qrCode={product?.qrCode}
        />
      )}
    </div>
  )
}
