import { toast } from 'react-toastify'
import {
  useRouter,
  CurrentUserContext,
  usePermissions,
  useFirestore,
  Loading,
} from 'app/shared'

import { useContext, useEffect, useState } from 'react'
import { Order } from '../Accounting/finance.types'
import { CheckboxSolidIcon } from 'app/shared/components/Icons/CheckboxSolidIcon'
import { WarningIcon } from 'app/shared/components/Icons/WarningIcon'

export const TicketRedemptionPage = () => {
  const { getSingle, update } = useFirestore('orders')
  const { hasAnyRole } = usePermissions()
  const { user, permissions } = useContext(CurrentUserContext)
  const { routeParams, redirectTo } = useRouter<{
    orderId: string
  }>()
  const [order, setOrder] = useState<Order>()
  const [valid, setValid] = useState<boolean>()
  const [infoText, setInfoText] = useState<string>()
  const [updated, setUpdated] = useState(false)

  useEffect(() => {
    if (!routeParams.orderId) return
    // @ts-ignore
    getSingle(routeParams.orderId).then(setOrder)
  }, [routeParams])

  useEffect(() => {
    if (!order) return
    if (updated) return
    // if valid is not undefined, that means we have set it.
    // we only want to run this function once, so we stop it.
    if (typeof valid !== 'undefined') return

    // We don't want to axe the function completely
    // if they are a visitor, since it's possible
    // that the U.I. hasn't updated and the Admin is
    // waiting on a network request to get their permissions.
    if (hasAnyRole(['visitor'])) {
      return
    }

    // If they are a customer, they can't redeem their ticket.
    if (hasAnyRole(['customer'])) {
      toast.error('Only an admin can redeem a ticket.')
      redirectTo('/')
      return
    }

    // Some orders don't have an ID as a result of the stripe webhook being used in incorrect ways.
    // If that is the case, none of this will work so we axe the function.
    if (!order.id) {
      setValid(false)
      setInfoText(
        'This order does not have an ID.  It has corrupted data.  Email info@stlswing.dance or talk to Cameron Brouk at one of our events for a solution.',
      )
      return
    }

    // If the credits object does not exist, it means it's an old ticket.
    if (typeof order.credits === 'undefined') {
      setValid(false)
      setInfoText(
        'This Ticket is deprecated and uses our old system.  Email info@stlswing.dance or talk to Cameron Brouk at one of our events for a solution.',
      )
      return
    }

    // If they do not have any credits in their order, the ticket is expired
    if (order.credits === 0) {
      setValid(false)
      setInfoText('This Ticket is Expired')
      return
    }

    if (order.credits > 0) {
      setValid(true)
      setInfoText(
        `Ticket Redeemed! This ticket has ${order.credits - 1} uses remaining.`,
      )
      setUpdated(true)
      update(order.id, {
        ...order,
        credits: order.credits - 1,
      })
      return
    }
  }, [order, permissions.role])

  return (
    <div className='w-screen h-screen mx-auto flex flex-col items-center mt-28 bg-white'>
      {(!order || !infoText) && (
        <Loading className='h-64 w-64 ' color='purple' />
      )}

      {order && valid && infoText && (
        <CheckboxSolidIcon className='h-52  w-52 bg-green-400 rounded-full' />
      )}

      {order && !valid && infoText && (
        <WarningIcon className='h-64 w-64 text-yellow-400 rounded-full' />
      )}

      <p className='p-4 text-lg'>{infoText}</p>
    </div>
  )
}
