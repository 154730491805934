import { any } from 'ramda'
import { TEACHERS_MAP, GROUP_CLASS_LEVELS } from '../../../stlswing.constants'
import { GroupClass } from '../groupClass.types'

export type ClassFilters = {
  level: GroupClass['level'][]
  instructors: string[]
  danceForm: GroupClass['danceForm'][]
}

export const groupClassFilterDefaults: ClassFilters = {
  level: [],
  instructors: [],
  danceForm: [],
}

export const groupClassPredicate = (
  classFilters: ClassFilters,
  groupClass: GroupClass,
) => {
  const { level, instructors, danceForm } = classFilters
  if (level.includes(groupClass.level)) return false
  if (
    any(
      (instructor) => instructors.includes(instructor),
      groupClass.instructors,
    )
  )
    return false
  if (danceForm.includes(groupClass.danceForm)) return false
  return true
}
