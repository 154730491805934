import { useUI } from 'app/shared'
import React, { useState } from 'react'
import { OptionsList } from './OptionsList'
import { OptionsMenu } from './OptionsMenu'
import { OptionsPanel } from './OptionsPanel'
import { Selection } from '../Select'

type Props = {
  isVisible: boolean
  label: string
  search?: boolean
  setVisible: SetState<boolean>
  setSearchTerm: SetState<string>
  handleSelect: (selection: Selection<any>) => void
  selections: Selection<any>[]
}

export const SelectOptions = (props: C<Props>) => {
  const { isMobile } = useUI()

  if (!isMobile)
    return (
      <div className='relative w-full'>
        <OptionsMenu
          search={props.search}
          isVisible={props.isVisible}
          setVisible={props.setVisible}
          setSearchTerm={props.setSearchTerm}>
          {props.children}
        </OptionsMenu>
      </div>
    )

  return (
    <OptionsPanel
      label={props.label}
      search={props.search}
      isVisible={props.isVisible}
      setVisible={props.setVisible}
      setSearchTerm={props.setSearchTerm}>
      {props.children}
    </OptionsPanel>
  )
}
