import React, { useContext, useEffect, useState } from 'react'
import {
  Auth,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  signInWithRedirect,
  signInWithPopup,
  UserCredential,
} from 'firebase/auth'
import { Permissions } from 'app/shared'
import { firebaseApp } from 'environments'
import { CurrentUserContext, Loading, User } from 'app/shared'

export const ANONYMOUS_STRIPE_CUSTOMER_ID = 'cus_MsxX0NYUb3Zp3C'

export const createDefaultUser = (data: {
  uid: string
  displayName?: string | null
  email?: string | null
  photoURL?: string | null
  phoneNumber?: string | null
}): User<true> => ({
  id: data.uid,
  stripeId: ANONYMOUS_STRIPE_CUSTOMER_ID,
  createdAt: new Date().toISOString(),
  updatedAt: new Date().toISOString(),
  displayName: data?.displayName || '',
  email: data?.email || '',
  emailVerified: false,
  photoUrl: data.photoURL || '',
  phone: data.phoneNumber || '',
  createdBy: '',
  updatedBy: '',
})

export const getUserRedirectData = async (auth: Auth) => {
  const result = await getRedirectResult(auth)
  if (!result) return

  const defaultPermissions: Permissions = {
    role: 'customer',
    clearance: 1,
    groups: [],
  }

  const { uid, email, displayName, photoURL } = result.user
  const defaultUser = createDefaultUser({
    uid,
    email,
    displayName,
    photoURL,
  })

  return { defaultUser, defaultPermissions }
}

type Props = {
  afterLogin: () => void
}

export const GoogleOAuth = (props: C<Props>) => {
  const { setPermissions, setUser } = useContext(CurrentUserContext)
  const [hasClicked, setHasClicked] = useState(false)

  const signInWithGoogle = async () => {
    if (hasClicked) return
    setHasClicked(true)
    const auth = getAuth(firebaseApp)
    const provider = new GoogleAuthProvider()
    const result = await signInWithRedirect(auth, provider).then(
      props.afterLogin,
    )

    props.afterLogin()
  }

  return (
    <button
      onClick={signInWithGoogle}
      // onClick={() => {
      //   if (hasClicked) return
      //   setHasClicked(true)
      //   const auth = getAuth(firebaseApp)
      //   const provider = new GoogleAuthProvider()
      //   signInWithPopup(auth, provider).then(props.afterLogin)
      //   // signInWithRedirect(auth, provider).then(props.afterLogin)
      // }}
      className='bg-gray-50 hover:bg-gray-200 flex w-full p-2 m-2 text-gray-800 transition-all duration-300 border border-gray-200 rounded-lg shadow-lg cursor-pointer'>
      <img
        className='w-8 h-8'
        alt='google login'
        src='https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg'
      />
      <div className='flex items-center justify-between pl-2 font-medium text-center w-full'>
        <p>Sign In With Google</p>
        {hasClicked && <Loading className='w-8 h-8' color='purple' />}
      </div>
    </button>
  )
}

// // type _ObjThenKeys<O extends any, K extends string> = {[K in keyof P] }
// type RecursiveKeyof<T> = keyof {
//   [P in keyof T as T[P] extends any[]
//     ? never
//     : T[P] extends object
//     ? RecursiveKeyof<T[P]>
//     : keyof T]: string
// }

// // type _ObjThenKeys<O extends any, K extends string> = {[K in keyof P] }
// type _RecursiveKeyof<Object> = {
//   [Key in keyof Object as Object[Key] extends any[]
//     ? never // if array, set key as never
//     : Object[Key] extends object // otherise, if key is object
//     ? RecursiveKeyof<Object[Key]> // recursively run this
//     : keyof Object]: Object[Key] extends any[] // otherwise, it's a keyof current object
//     ? never
//     : Object[Key] extends object
//     ? never
//     : Pick<{ [T in keyof Object]: Object[T] }, Key>
// }

// const person = {
//   name: 'Cameron',
//   age: 28,
//   props: {
//     name: 'Cameron',
//     age: 28,
//     friend: ['test'],
//     top: {
//       bottom: 'string',
//       left: {
//         right: 'string',
//       },
//     },
//     test: (any: any) => any,
//   },
// }

// type Person = typeof person

// type a = _RecursiveKeyof<Person>
