import React, { useState, useEffect } from 'react'
import { Video } from '../../Media/Video'
import { Asset } from '../Assets.types'
import { getImageSrc } from '../helpers/asset.helpers'

type Props = {
  fullPath: string
  type: Asset['type']
  alt?: string
  className?: string
}
export const FirestoreImage = ({ fullPath, type, ...props }: Props) => {
  const [src, setSrc] = useState<string>()

  useEffect(() => {
    getImageSrc(fullPath).then(setSrc)
  }, [])

  if (!src) return null
  if (type === 'image') return <img src={src} {...props} />
  if (type === 'video')
    return (
      <video
        autoPlay={false}
        preload='auto'
        muted
        loop
        poster={'/src/assets/images/lhsl-home-cover.jpg'}
        playsInline
        src={src}
        className={`object-cover w-full h-full`}
      />
    )
  return null
}
