import { CurrentUserContext, useFirestore } from 'app/firebase'
import { Modal } from 'app/shared/components'
import React, { useContext, useState } from 'react'
import { GroupClass } from '../../../../stlswing.types'

import { PROD_PRICE_IDS } from 'app/stlswing/stlswing.constants'
import { Checkbox } from 'app/shared/components/Field/Checkbox'
import { GroupClassPaymentButton } from '../../GroupClassPaymentButton'
import {
  getTiers,
  GroupClassPricingTier,
} from './components/PricingOptionsStep/components/GroupClassPricingTier'
import { classIsCurrent } from 'app/stlswing/helpers/date.helpers'
import { MembershipPaymentButton } from '../../MembershipPaymentButton'
import { LoggedInGuard } from 'app/auth/LoggedInGuard'
import { GroupClassSignup } from './GroupClassSignup'

type Props = {
  isVisible: boolean
  onClose: () => void
  groupClass: GroupClass<true>
}

export const GroupClassSignupModal = ({
  isVisible,
  onClose,
  groupClass,
}: C<Props>) => {
  return (
    <Modal
      type='right-panel'
      hideTitle
      title='Class Signup'
      className='w-screen sm:w-2/5'
      onClose={onClose}
      isOpen={isVisible}>
      <LoggedInGuard afterLogin={() => {}}>
        <GroupClassSignup groupClass={groupClass} />
      </LoggedInGuard>
    </Modal>
  )
}

export const _GroupClassSignupModal = ({
  isVisible,
  onClose,
  groupClass,
}: C<Props>) => {
  const { user } = useContext(CurrentUserContext)
  const [hasStudentDiscount, setHasStudentDiscount] = useState(
    user?.studentDiscount,
  )
  const { update: updateUser } = useFirestore('users')

  const onCheck = (studentDiscount: boolean) => {
    setHasStudentDiscount((v) => !v)
    if (!user) return
    updateUser(user.id, { studentDiscount })
  }

  return (
    <Modal
      type='right-panel'
      title='Class Signup'
      className='w-screen sm:w-1/3 overflow-y-scroll'
      onClose={onClose}
      allowRouting
      isOpen={isVisible}>
      <LoggedInGuard afterLogin={() => ''}>
        <div className='flex flex-col items-center justify-center mx-4 mb-20'>
          {/* Experience Level */}
          <h1 className='sm:text-center my-5 text-5xl font-extrabold text-gray-900'>
            Pricing Options
          </h1>

          {classIsCurrent(groupClass) && (
            <div className='flex flex-col items-start justify-center'>
              <p className='text-lg font-extrabold text-red-500'>
                This class is currently in Session!
              </p>
              <p className='mt-3 font-medium text-red-500'>Only sign up if:</p>
              <ul className='mx-2 mt-2 space-y-2 text-gray-800'>
                <li>You have teacher approval</li>
                <li>You have taken the previous classes as drop-ins</li>
                <li>
                  You are confident in your ability to join without context
                </li>
              </ul>
            </div>
          )}

          <div className='bg-white'>
            <div className='max-w-7xl sm:px-6 lg:px-8 pb-4 mx-auto'>
              <div className='sm:mt-8 sm:space-y-0 sm:flex sm:flex-col sm:gap-6 mt-4 space-y-4'>
                <Checkbox
                  name='discount'
                  label='I am elligible for the student discount'
                  onChange={onCheck}
                  checked={hasStudentDiscount}
                />
                {hasStudentDiscount && (
                  <p className='text-red-700'>
                    NOTE: your student ID will be checked before class
                  </p>
                )}
                {getTiers(groupClass).map((tier) => (
                  <div
                    key={tier.name}
                    className='divide-y divide-gray-200 rounded-lg shadow-sm'>
                    <GroupClassPricingTier
                      description={tier.description}
                      included={tier.included}
                      name={tier.name}
                      price={tier.price}
                      priceId={tier.priceId}>
                      {groupClass && (
                        <GroupClassPaymentButton
                          paymentType={tier.paymentType}
                          groupClass={groupClass}
                          amountOfClasses={tier.amount}
                          price={tier.price}
                          discount={!!hasStudentDiscount}
                        />
                      )}
                    </GroupClassPricingTier>
                  </div>
                ))}

                <GroupClassPricingTier
                  description={'Take any / all of our classes'}
                  included={['All Weekly Social Dances', 'All Classes']}
                  name={'STL Swing Membership'}
                  price={120}
                  priceId={PROD_PRICE_IDS.MEMBERSHIP}>
                  {groupClass && (
                    <MembershipPaymentButton
                      paymentType={'Membership'}
                      groupClass={groupClass}
                      amountOfClasses={groupClass.dates.length}
                      price={120}
                      discount={false}
                    />
                  )}
                </GroupClassPricingTier>
              </div>
            </div>
          </div>
        </div>
      </LoggedInGuard>
    </Modal>
  )
}
