import React, { useContext, useEffect, useRef, useState } from 'react'
import { CurrentUserContext, useFirestore } from 'app/firebase'
import { PageHeading } from 'app/layout/PageHeading'
import { Button } from 'app/shared/components'
import { useRouter } from 'app/shared/hooks'
import { Event } from 'app/stlswing/stlswing.types'
import { Ticket } from '../Shared/Ticket'
import { sendEmails } from 'app/firebase/functions/sendgrid/sendEmails'
import { useUsers } from 'app/stlswing/hooks'
import { Orders } from '../Accounting/sections/Orders'
import { addMinutes, isAfter } from 'date-fns'
import { sortBy } from 'lodash'
import { Order } from '../Accounting/finance.types'

export const EventPaymentSuccess = () => {
  const { routeParams, redirectTo } = useRouter<{ id: string }>()
  const { collectionMap: eventsMap } = useFirestore('events')
  const [event, setEvent] = useState<Event<true>>()
  const { orders: userOrders } = useUsers()

  const ref = useRef(new Date())

  useEffect(() => {
    if (!routeParams.id || !eventsMap) return
    setEvent(eventsMap[routeParams.id])
  }, [eventsMap, routeParams.id])

  const getQuantity = (event: Event<true>) => {
    const orderAmount = userOrders?.filter(
      (order) => order.internalProductId === event.id,
    ).length
    const amount = orderAmount || 1
    if (!userOrders) return 1
    if (amount === 1) return amount
    return amount + 1

    // if (isAfter(new Date(), addMinutes(ref?.current, 3))) {
    //   return orderAmount || 1
    // } else {
    //   if (!orderAmount) return 1
    //   return orderAmount + 1
    // }
  }

  return (
    <div className=' relative z-0'>
      <PageHeading
        title='Payment Successful!'
        image={event?.photo ? 'images/' + event?.photo + '.jpg' : undefined}
        video={event?.video ? 'videos/' + event?.video : undefined}
      />

      <div className='px-4 pt-0 pb-10 mt-0 text-white bg-gray-800'>
        <div className='mx-auto max-w-3xl flex flex-col items-center justify-center gap-5'>
          <h2 className='pt-4 text-3xl font-bold text-center'>
            A ticket will be sent to your email shortly!
          </h2>
          <p className='text-md pt-2 pb-8'>
            The email will include a link to a ticket with a QR code. Show this
            code from your phone at the door when you arrive to the event. You
            can view this ticket code at any time by clicking the floating 'My
            Tickets' button on the bottom of any page. (You must be logged in to
            view your tickets)
          </p>

          {/* {event && (
            <Ticket
              qrCode={event?.qrCode}
              productName={event?.name}
              quantity={getQuantity(event)}
              product={event}
            />
          )} */}

          <div className='flex justify-center'>
            <Button
              variant='raised'
              onClick={() =>
                redirectTo('/swing-dance-events/' + routeParams.id)
              }>
              Back to Event Page
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
