import React, { useEffect, useState } from 'react'
import { Modal } from 'app/shared'
import { H3 } from 'app/shared/components/Layout/HTMLElements'
import { formatDate } from 'app/stlswing/helpers/date.helpers'
import { useUsers } from 'app/stlswing/hooks'
import { Event } from 'app/stlswing/stlswing.types'
import { compareAsc, formatDistance, isBefore } from 'date-fns'
import { groupBy, prop, uniqBy, without } from 'ramda'
import { useFirestore, usePermissions } from 'app/firebase'
import { DetailsSection } from '../Shared/DetailsSection'
import { AttendeesTable } from '../Shared/AttendeesTable/AttendeesTable'
import { AlbumModal } from './Album'

type Props = {
  onClose: () => void
}

export const MyEvents = (props: Props) => {
  const [currentEvents, setCurrentEvents] = useState<Event<true>[]>()
  const [pastEvents, setPastEvents] = useState<Event<true>[]>()
  const { currentUserEvents } = useUsers()
  const [selectedEvent, setSelectedEvent] = useState<Event<true>>()
  const [selectedAlbum, setSelectedAlbum] = useState<Event<true>>()
  const { hasAnyRole } = usePermissions()
  const { update: updateEvent } = useFirestore('events')
  const { list: orders } = useFirestore('orders')

  useEffect(() => {
    const { current, past } = groupBy((event) => {
      if (isBefore(new Date(), new Date(event.dateTime))) return 'current'

      return 'past'
    }, currentUserEvents)

    setCurrentEvents(current)
    setPastEvents(past)
  }, [currentUserEvents])

  return (
    <div className='flow h-full px-2 mt-3'>
      {currentUserEvents.length === 0 ? (
        <p>You don't have any events yet</p>
      ) : (
        <ul className='h-full'>
          {currentEvents && (
            <div className='h-1/2 overflow-y-auto border-b'>
              <H3 className='align-start justify-start'>Current Events</H3>
              <div className='divide-y divide-gray-200'>
                {uniqBy(prop('id'), currentEvents).map((event) => (
                  <EventListItem
                    event={event}
                    onClose={props.onClose}
                    type='current'
                    setDetailsOpen={() => setSelectedEvent(event)}
                  />
                ))}
              </div>
            </div>
          )}

          <div className='h-2/5 mt-3 overflow-y-auto'>
            <H3 className='align-start justify-start'>Past Events</H3>
            <div className='divide-y divide-gray-200'>
              {pastEvents &&
                uniqBy(prop('id'), pastEvents)
                  .sort((a, b) =>
                    compareAsc(new Date(a.dateTime), new Date(b.dateTime)),
                  )
                  .map((event) => (
                    <EventListItem
                      event={event}
                      type='past'
                      onClose={props.onClose}
                      setDetailsOpen={() => setSelectedEvent(event)}
                      // setAlbumOpen={() => setSelectedAlbum(event)}
                    />
                  ))}
            </div>
          </div>
        </ul>
      )}

      {/* Details Modal */}
      <Modal
        type='left-panel'
        title='Event Details'
        className='w-full overflow-y-auto overflow-x-hidden'
        onClose={() => setSelectedEvent(undefined)}
        isOpen={!!selectedEvent}>
        {hasAnyRole(['admin', 'employee']) ? (
          selectedEvent && (
            <div>
              <AttendeesTable
                productId={selectedEvent?.id}
                attendees={orders
                  ?.filter(
                    (order) => order.internalProductId === selectedEvent?.id,
                  )
                  .map((order) => order.internalUserId)}
              />
            </div>
          )
        ) : (
          <DetailsSection hideTitle {...selectedEvent} />
        )}
      </Modal>

      <AlbumModal
        isOpen={!!selectedAlbum}
        onClose={() => setSelectedAlbum(undefined)}
        albumName='GrandOpening'
      />
    </div>
  )
}

const EventListItem = ({
  event,
  type,
  onClose,
  setDetailsOpen,
  setAlbumOpen,
}: {
  event: Event<true>
  type: 'past' | 'current'
  onClose: () => void
  setDetailsOpen: () => void
  setAlbumOpen?: () => void
}) => {
  // const { navigateTo } = useRouter()
  const { hasAnyRole } = usePermissions()
  const buttonEvents =
    'inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50'

  return (
    <li key={event.id} className='px-2 py-4'>
      <div className='flex items-center space-x-4'>
        <div className='flex-1 min-w-0'>
          <p className='text-sm font-medium text-gray-900 truncate'>
            {event.name}
          </p>
          <p className='text-sm text-gray-500 truncate'>
            {type === 'current'
              ? formatDate('day', event.dateTime) +
                ' at ' +
                formatDate('time', event.dateTime)
              : formatDistance(new Date(event.dateTime), new Date(), {
                  addSuffix: true,
                })}
          </p>
        </div>
        <div className='mt-2 space-x-1'>
          <button
            className={buttonEvents}
            onClick={() => {
              // navigateTo(`/swing-dance-events/${event.id}`)
              setDetailsOpen()
            }}>
            {hasAnyRole(['admin', 'employee']) ? 'Attendees' : 'Details'}
          </button>

          {setAlbumOpen && (
            <button className={buttonEvents} onClick={setAlbumOpen}>
              Photos
            </button>
          )}
        </div>
      </div>
    </li>
  )
}
