import {
  filter,
  tap,
  lensProp,
  update,
  flatten,
  pipe,
  map,
  any,
  includes,
} from 'ramda'
import { useFirestore } from 'app/firebase'
import { PageHeading } from 'app/layout/PageHeading'
import { Calendar } from 'app/shared/components/Calendar/Calendar'
import React, { useContext, useEffect, useState } from 'react'
import { EventCard } from '../components/Events/EventCard'
import { Event, GroupClass } from '../stlswing.types'
import { GroupClassCard } from '../components/GroupClass/GroupClassCard'
import {
  ExpansionPanel,
  InlineDescriptionSlider,
  Loading,
  UIContext,
  useUI,
} from 'app/shared'
import {
  GROUP_CLASS_LEVELS,
  TEACHERS_MAP,
  TEACHERS,
} from '../stlswing.constants'
import { addOrRemove } from 'app/shared/utils/arrays'
import { keyBy } from 'lodash/fp'
import { groupClassPredicate } from '../components/GroupClass/GroupClassFilters/groupClassFilters.helpers'
import { GroupClassFilters } from '../components/GroupClass/GroupClassFilters/GroupClassFilters'

type ClassFilters = {
  level: GroupClass['level'][]
  instructors: string[]
  danceForm: GroupClass['danceForm'][]
}

export const CalendarPage = () => {
  const { list: socialDances } = useFirestore('events')
  const { list: groupClasses } = useFirestore('groupClasses')
  const [globalFilters, setGlobalFilters] = useState<('events' | 'classes')[]>(
    [],
  )
  const { setShowFooter } = useContext(UIContext)
  const [classFilters, setClassFilters] = useState<ClassFilters>({
    level: [],
    instructors: [],
    danceForm: [],
  })

  useEffect(() => {
    setShowFooter(false)

    return () => {
      setShowFooter(true)
    }
  }, [])

  const socialDanceEvents = (dances: Event<true>[]) => {
    if (globalFilters.includes('events')) return []

    return (
      dances?.map((event) => ({
        ...event,
        date: new Date(event.dateTime),
        color: 'bg-yellow-500',
      })) || []
    )
  }

  const getColorFromClass = ({ instructors }: GroupClass<true>) => {
    const instructor1 = TEACHERS_MAP[instructors[0]]
    const color1 = ' bg-' + instructor1?.color
    return color1
  }

  const groupClassEvents = (groupClasses: GroupClass<true>[]) => {
    if (globalFilters.includes('classes')) return []

    return pipe(
      map((groupClass: GroupClass<true>) =>
        groupClass.dates.map((date) => ({
          ...groupClass,
          date: new Date(date),
          color: getColorFromClass(groupClass),
        })),
      ),
      flatten,
      filter((groupClass: GroupClass<true>) =>
        groupClassPredicate(classFilters, groupClass),
      ),
      filter((groupClass: GroupClass<true>) => groupClass.draft === false),
    )(groupClasses)
  }

  return (
    <section className='relative z-0' tabIndex={0}>
      <PageHeading title='STL Swing Calendar'></PageHeading>

      {!socialDances && !groupClasses && (
        <div className='fixed z-20 flex items-center bg-red-200 text-red-800 p-4 rounded-full space-x-2 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
          <Loading className='h-6 w-6' color='purple' />{' '}
          <p>loading events...</p>
        </div>
      )}

      <Calendar<Event<true> | GroupClass<true>>
        date={new Date()}
        events={[
          ...socialDanceEvents(socialDances || []),
          ...groupClassEvents(groupClasses || []),
        ]}
        renderEventItem={(event) => {
          if ('dates' in event) return <GroupClassCard groupClass={event} />
          if ('dateTime' in event) return <EventCard event={event} />
        }}
        sidebarComponent={
          <div className='flex flex-col pb-20'>
            <p className='text-lg font-semibold pl-4 pt-4'>Global Filters</p>
            <div className='border-b p-4'>
              <InlineDescriptionSlider
                name='Social Dances'
                description='Meet new people and have a blast dancing to swing music.'
                onClick={() =>
                  setGlobalFilters((prev) => addOrRemove('events', prev))
                }
                checked={!globalFilters.includes('events')}
              />
              <InlineDescriptionSlider
                name='Dance Classes'
                description='A group of students learns to dance from a pair of instructors.'
                onClick={() =>
                  setGlobalFilters((prev) => addOrRemove('classes', prev))
                }
                checked={!globalFilters.includes('classes')}
              />
            </div>

            <GroupClassFilters
              filters={classFilters}
              setFilters={setClassFilters}
            />
            {/*
              <p className='text-lg font-semibold pl-4 pt-4'>Event Type</p>
              <div className='border-b p-4'>
                <InlineDescriptionSlider
                  name={'DJ'}
                  description=''
                  onClick={() => {}}
                  checked={true}
                />
                <InlineDescriptionSlider
                  name={'Live Music'}
                  description=''
                  onClick={() => {}}
                  checked={true}
                />
              </div> */}
          </div>
        }
      />
    </section>
  )
}

export default CalendarPage
