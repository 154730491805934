import { sendEmails } from 'app/firebase/functions/sendgrid/sendEmails'
import googleDocLink from '../../../../../assets/images/google-doc-link.svg'
import {
  Button,
  DocumentDefaults,
  FormModal,
  Input,
  Textarea,
  useFirestore,
  CurrentUserContext,
  TableSchema,
  DataTable,
  Modal,
  Select,
  Option,
} from 'app/shared'
import { Image } from 'app/shared/components/Media/Image'
import { formatDate } from 'app/stlswing/helpers/date.helpers'
import { useGetTeachers } from 'app/stlswing/hooks'
import { AdminMobileHeader } from 'app/stlswing/pages'
import { compareAsc } from 'date-fns'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'

const VERSIONS = ['Major', 'Minor', 'Breaking'] as const
// Major -> Not Knowing could potentially make you feel out of the loop
// Minor -> It doesn't Affect you, but it effects the scene
// Breaking -> A Fundamental Change has been made to how we do things
const TYPES = [
  'Marketing',
  'Finances',
  'Venue Setup',
  'Rules / Policy',
  'Website',
  'Group Classes',
  'Private Lessons',
  'DJing',
  'General',
] as const

export type Changelog = {
  name: string
  description: string
  version: typeof VERSIONS[number]
  type: typeof TYPES[number][]
  googleDoc?: string
  image?: string
} & DocumentDefaults

export const ChangelogDashboard = () => {
  const { list: changelogs } = useFirestore('changelog')
  const [openCreate, setOpenCreate] = useState(false)
  const [viewDetails, setViewDetails] = useState<Changelog>()
  const [logToEdit, setLogToEdit] = useState<Changelog>()
  const { user } = useContext(CurrentUserContext)

  type Columns =
    | 'Overview'
    | 'Details'
    | 'Created At'
    | 'Edit'
    | 'Version'
    | 'Type'
  const schema: TableSchema<Changelog, Columns> = {
    Overview: (log) => (
      <p className='font-semibold text-gray-800'>{log.name}</p>
    ),
    Details: (log) => (
      <Button onClick={() => setViewDetails(log)} variant='raised'>
        View Details
      </Button>
    ),
    Version: (log) => log?.version,
    Type: (log) => log?.type?.join(' and '),
    'Created At': (log) =>
      formatDate('readable-date-and-time', new Date(log.createdAt)),
    Edit: (log) => <Button onClick={() => setLogToEdit(log)}>Edit</Button>,
  }

  return (
    <div>
      <AdminMobileHeader title='Updates'>
        <div className='p-4'>
          <Button onClick={() => setOpenCreate(true)}>Add Update</Button>
        </div>
      </AdminMobileHeader>

      <DataTable<Changelog>
        tableSchema={schema}
        defaultSort={'createdAt'}
        recordsList={changelogs}
        hideColumnFilter></DataTable>

      <Modal
        disallowRouting
        title='Log Info'
        className='w-full sm:w-1/2 overflow-y-auto'
        isOpen={!!viewDetails}
        onClose={() => setViewDetails(undefined)}>
        <h2 className='text-xl p-4 text-semibold'>
          {user?.displayName} made a {viewDetails?.version} change to{' '}
          {viewDetails?.type.join(' and ')}
        </h2>
        <div className='p-4 border-t'>
          {/* <h2 className='text-xl pb-4 pt-2'>Description</h2> */}
          <pre className='text-lg max-w-full whitespace-pre-wrap'>
            {viewDetails?.description}
          </pre>
        </div>

        <div className='p-4 border-t'>
          {viewDetails?.googleDoc && (
            <a
              href={viewDetails.googleDoc}
              target='_blank'
              className='flex items-center justify-center shadow-xl max-w-fit p-2 space-x-2 bg-gray-100 rounded-full border px-4'>
              <svg
                viewBox='0 0 87.3 78'
                xmlns='http://www.w3.org/2000/svg'
                className='w-8 h-8'>
                <path
                  d='m6.6 66.85 3.85 6.65c.8 1.4 1.95 2.5 3.3 3.3l13.75-23.8h-27.5c0 1.55.4 3.1 1.2 4.5z'
                  fill='#0066da'
                />
                <path
                  d='m43.65 25-13.75-23.8c-1.35.8-2.5 1.9-3.3 3.3l-25.4 44a9.06 9.06 0 0 0 -1.2 4.5h27.5z'
                  fill='#00ac47'
                />
                <path
                  d='m73.55 76.8c1.35-.8 2.5-1.9 3.3-3.3l1.6-2.75 7.65-13.25c.8-1.4 1.2-2.95 1.2-4.5h-27.502l5.852 11.5z'
                  fill='#ea4335'
                />
                <path
                  d='m43.65 25 13.75-23.8c-1.35-.8-2.9-1.2-4.5-1.2h-18.5c-1.6 0-3.15.45-4.5 1.2z'
                  fill='#00832d'
                />
                <path
                  d='m59.8 53h-32.3l-13.75 23.8c1.35.8 2.9 1.2 4.5 1.2h50.8c1.6 0 3.15-.45 4.5-1.2z'
                  fill='#2684fc'
                />
                <path
                  d='m73.4 26.5-12.7-22c-.8-1.4-1.95-2.5-3.3-3.3l-13.75 23.8 16.15 28h27.45c0-1.55-.4-3.1-1.2-4.5z'
                  fill='#ffba00'
                />
              </svg>
              <p>Google Doc Link</p>
            </a>
          )}
        </div>
      </Modal>

      <ChangelogForm
        isOpen={openCreate}
        closeForm={() => {
          setOpenCreate(false)
          setLogToEdit(undefined)
        }}
      />

      <ChangelogForm
        closeForm={() => setLogToEdit(undefined)}
        changelog={logToEdit}
        isOpen={!!logToEdit}
      />
    </div>
  )
}

type ChangelogFormProps = {
  isOpen: boolean
  closeForm: () => void
  changelog?: Changelog
}
export const ChangelogForm = (props: ChangelogFormProps) => {
  const form = useForm()
  const { user } = useContext(CurrentUserContext)
  const teachers = useGetTeachers()

  const { createWithId, update } = useFirestore('changelog')

  type FormData = Changelog
  const onSubmit = async (formData: FormData) => {
    if (!props.changelog) {
      createWithId(formData)
      sendEmails({
        // subject: `[STLSWING]: ${user?.displayName} has made a ${
        //   formData.version
        // } change to ${formData.type.join(' and ')}`,
        subject: `[STLSWING]: ${formData.name}`,
        text: `
${user?.displayName} has made a ${
          formData.version
        } change to ${formData.type.join(' and ')}

Overview: ${formData.name}.

Description: ${formData.description}
        `,
        to: teachers.map((teacher) => teacher.email),
      })
    }

    if (props.changelog) {
      update(props.changelog.id, formData)
    }

    props.closeForm()
  }

  return (
    <FormModal
      title={'Changelog Form'}
      onSubmit={form.handleSubmit<FormData>(onSubmit)}
      form={form}
      isOpen={props.isOpen}
      onClose={props.closeForm}
      className='sm:w-1/2 w-screen text-black'
      type='right-panel'>
      <div className='p-4'>
        <div className='grid grid-cols-2 gap'>
          <Select<typeof TYPES[number]>
            form={form}
            label='Change Type'
            multiple
            required
            name='type'
            defaultValues={props?.changelog?.type || []}>
            {TYPES.map((type) => (
              <Option label={type} value={type} />
            ))}
          </Select>

          <Select<typeof VERSIONS[number]>
            form={form}
            required
            label='Change Version'
            name='version'
            defaultValues={
              props?.changelog?.version ? [props.changelog.version] : []
            }>
            {VERSIONS.map((type) => (
              <Option label={type} value={type} />
            ))}
          </Select>
        </div>
        <Input
          form={form}
          label='Subject'
          name='name'
          required
          defaultValue={props?.changelog?.name}
        />

        <Textarea
          form={form}
          label='description'
          name='description'
          required
          defaultValue={props?.changelog?.description}
        />

        <Input
          form={form}
          label='Google Doc Link'
          name='googleDoc'
          defaultValue={props?.changelog?.googleDoc || ''}
        />
      </div>
    </FormModal>
  )
}
