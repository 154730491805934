import React from 'react'
import { useFirestore } from 'app/firebase'
import { Input, Select, Option } from 'app/shared/components'
import { FormModal } from 'app/shared/components/Modal'
import { useForm } from 'react-hook-form'
import { FAQ } from '../Faq.types'
import { Textarea } from 'app/shared/components/Field/Input/Textarea'
import { format } from 'date-fns'
import { faqTopics, FaqTopics } from '../Faq.types'

type Props = {
  title: string
  faq?: FAQ
  closeForm: () => void
  isOpen: boolean
}

export const FaqFormModal = ({ faq, ...props }: Props) => {
  const { update, createWithId } = useFirestore('faq')

  const form = useForm()

  type FormData = {
    name: string
    answer: string
    topics: string
  }
  const onSubmit = async ({ topics, ...formData }: FormData) => {
    const updatedFaq = {
      ...formData,
      topics: topics.split(', ') as FAQ['topics'],
    }

    if (!faq) await createWithId(updatedFaq)

    // if command exits, update current command
    if (faq) await update(faq.id, updatedFaq)

    props.closeForm()
  }
  return (
    <FormModal
      title={props.title}
      onSubmit={form.handleSubmit<FormData>(onSubmit)}
      form={form}
      isOpen={props.isOpen}
      onClose={props.closeForm}
      className='sm:w-1/2 w-screen text-black'
      type='right-panel'>
      <div className='p-4'>
        <Input
          form={form}
          label='Question'
          name='name'
          required
          defaultValue={faq ? faq.name : ''}
        />

        <Textarea
          form={form}
          label='Answer'
          name='answer'
          required
          defaultValue={faq ? faq.answer : ''}
        />

        <div className='flex space-x-1'>
          <Select
            form={form}
            label='Topics'
            name='topics'
            multiple
            required
            defaultValues={faq ? faq.topics : []}>
            {faqTopics.map((topic, i) => (
              <Option label={topic} value={topic} key={i} />
            ))}
          </Select>
        </div>

        <Input
          form={form}
          type='text'
          label='Link'
          name='link'
          defaultValue={faq ? faq.link : ''}
        />
      </div>
    </FormModal>
  )
}
