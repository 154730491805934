import React, { useContext } from 'react'
import { useToggle } from 'app/shared/hooks'
import { HeaderDropDown } from '../components/HeaderDropDown'
import { Button } from 'app/shared/components'
import { CurrentUserContext, Role, usePermissions } from 'app/firebase'
import { EyeIcon } from 'app/shared/components/Icons/EyeIcon'
import { UserSelect } from 'app/users/components/UserSelect'
import { Step, Stepper } from 'app/shared/components/Stepper'

export const ViewAsMenu = () => {
  const { user, setUser } = useContext(CurrentUserContext)
  // prettier-ignore
  const { toggle: toggleAdmin, setFalse: closeAdmin, state: adminVisible } = useToggle()

  const { setPermissions, permissions } = useContext(CurrentUserContext)
  const { hasClearance } = usePermissions()

  const getClassName = (role: Role) =>
    role === permissions.role ? 'bg-blue-200 text-gray-200' : ''

  const ViewAsButton = (role: Role, label: string) => (
    <>
      <Button
        onClick={() => setPermissions((p) => ({ ...p, role }))}
        className={`flex space-x-2 items-center w-full ${getClassName(role)}`}>
        <EyeIcon />
        <span>{label}</span>
      </Button>
    </>
  )

  return (
    <>
      <HeaderDropDown
        text='View As'
        isOpen={adminVisible}
        toggle={toggleAdmin}
        close={closeAdmin}>
        <div className='h-screen sm:h-full sm:w-[500px] sm:p-8 flex flex-col items-start w-screen p-4 space-y-2'>
          <UserSelect selectUser={setUser} userId={user?.id} />
          {hasClearance(4) && ViewAsButton('admin', 'Admin')}
          {ViewAsButton('employee', 'Teacher')}
          {ViewAsButton('customer', 'Student')}
          {ViewAsButton('visitor', 'Not Logged In')}
        </div>
      </HeaderDropDown>
    </>
  )
}
