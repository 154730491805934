import { EventType } from '@testing-library/react'
import { CurrentUserContext } from 'app/firebase'
import { PaymentType } from 'app/firebase/functions/stripe.functions'
import { Modal } from 'app/shared/components'
import { StripePaymentButton } from 'app/shared/components/Stripe/StripePaymentButton'
import { EventPaymentButton } from 'app/stlswing/pages/EventPaymentButton'
import { STRIPE_PRICE_IDS } from 'app/stlswing/stlswing.constants'
import { Event, EventTypes } from 'app/stlswing/stlswing.types'
import { ENV } from 'environments'
import React, { useContext, useMemo, useState } from 'react'
import { GroupClassPricingTier } from '../GroupClass/components/GroupClassSignup/components/PricingOptionsStep/components/GroupClassPricingTier'
import {
  getEventPaymentType,
  getEventPrice,
  getEventPriceId,
} from './event.helpers'

type Props = {
  event: Event<true>
  isOpen: boolean
  onClose: () => void
}

export const EventSignup = ({ event, isOpen, onClose }: C<Props>) => {
  const { user } = useContext(CurrentUserContext)

  const getPriceTierText = () => {
    if (event.type === 'Monthly') return '$20 for a great night of live music'
    if (event.type === 'Weekly')
      return '$10 for a great night of dancing and good vibes'
    if (event.type === 'Harmonizers Dance')
      return '$15 for a great night of live music'

    return ''
  }

  const getIncludedText = (paymentType: Event['type']) => {
    if (paymentType === 'Weekly') return ['2 hours of dancing']
    if (paymentType === 'Monthly')
      return [
        '3 hours of dancing',
        'dance performance',
        '1 Hour no experience required Lesson',
      ]
    if (paymentType === 'Harmonizers Dance')
      return ['Taster lesson', 'A night of dancing and music']

    return ['A night of social dancing']
  }

  return (
    <Modal
      type='right-panel'
      isOpen={isOpen}
      onClose={onClose}
      className='w-screen sm:w-1/2 overflow-y-auto'>
      <div className='w-full flex flex-col items-center justify-center max-w-5xl'>
        <h1 className='sm:text-center my-5 text-4xl font-extrabold text-gray-900'>
          Pricing Options
        </h1>

        <div className='p-4 grid sm:grid-cols-2 gap-4 mb-20'>
          <GroupClassPricingTier
            description={getPriceTierText()}
            name='Single Ticket'
            price={getEventPrice(event.type)}
            priceId={getEventPriceId(event.type)}
            included={getIncludedText(event.type)}>
            <StripePaymentButton
              credits={1}
              className='block w-full py-2 mt-8 text-3xl font-extrabold text-center text-white bg-gray-800 border border-gray-800 rounded-md hover:bg-gray-900'
              internalPaymentType={getEventPaymentType(event.type)}
              internalProductId={event.id}
              internalProductType={'events'}
              quantity={2}
              stripePriceId={getEventPriceId(event.type)}>
              ${getEventPrice(event.type)}
            </StripePaymentButton>
          </GroupClassPricingTier>

          <GroupClassPricingTier
            description={
              '$12 / week for all the social dancing that STL Swing offers.  Note:  This is an automatically recurring charge that you can cancel at any time.'
            }
            name='Social Dance Membership'
            price={getEventPrice(event.type)}
            priceId={getEventPriceId(event.type)}
            included={[
              'Intro Lessons Before Each Dance',
              'Weekly Social Dance (normally $10 each)',
              'Fourth Friday Live Music Event (normally $20 each)',
              'Frequent Practice Sessions (normally exclusive to classes)',
              'Second Monday Live Music Event (normally $15 each)',
            ]}>
            <StripePaymentButton
              credits={1}
              className='block w-full py-2 mt-8 text-3xl font-extrabold text-center text-white bg-gray-800 border border-gray-800 rounded-md hover:bg-gray-900'
              internalPaymentType={'Social Dance Membership'}
              internalProductId={event.id}
              internalProductType={'events'}
              quantity={1}
              stripePriceId={STRIPE_PRICE_IDS[ENV].DANCE_MEMBERSHIP}>
              $12 / Week
            </StripePaymentButton>
          </GroupClassPricingTier>
        </div>
      </div>
    </Modal>
  )
}
