import React, { useEffect, useState } from 'react'
import { Modal, ModalProps } from 'app/shared'
import { Loading } from 'app/shared/components/Loading/Loading'
import { getPhotoAlbum } from 'app/shared/utils/fileUpload'
import { Carousel } from 'react-responsive-carousel'

type Props = {
  albumName: string
}

export const Album = ({ albumName }: Props) => {
  const [albumSrcList, setAlbumSrcList] = useState<any>()
  const [currentSrc, setCurrentSrc] = useState('0')

  useEffect(() => {
    getPhotoAlbum(albumName).then(setAlbumSrcList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!albumSrcList) return <Loading />
  return (
    <div className='p-2 py-8'>
      {/* <div className='mb-8 text-center'>
        <a
          className='focus:shadow-outline hover:bg-indigo-900 px-4 py-2 mb-8 font-medium text-white transition duration-300 bg-indigo-700 rounded'
          download='Image'
          rel='noreferrer'
          target='_blank'
          href={albumSrcList[Number(currentSrc)]}>
          Download Current Image
        </a>
      </div> */}
      {/* <Carousel
        showArrows
        showIndicators={false}
        onChange={(c) => setCurrentSrc(c.toString())}>
        {albumSrcList.map((src: string) => (
          <div>
            <img alt='' src={src} />
          </div>
        ))}
      </Carousel> */}
    </div>
  )
}

export const AlbumModal = ({
  albumName,
  isOpen,
  onClose,
}: Props & Omit<ModalProps, 'children' | 'type' | 'title'>) => {
  return (
    <Modal
      className='w-full h-full'
      title='Gallery'
      isOpen={isOpen}
      onClose={onClose}>
      <Album albumName={albumName} />
    </Modal>
  )
}
