import React, { useState } from 'react'
import { SignInModal } from 'app/auth'
import { Button } from 'app/shared/components'
import { useFirestore } from 'app/shared'
import { Video } from 'app/shared/components/Media/Video'
import { usePermissions, useRouter } from 'app/shared/hooks'
import {
  classIsCurrent,
  formatDate,
  hasFinished,
} from 'app/stlswing/helpers/date.helpers'
import {
  addMonths,
  isAfter,
  isPast,
  isThisWeek,
  isToday,
  isTomorrow,
  startOfMonth,
} from 'date-fns'
import { GroupClass } from '../../stlswing.types'
import { InfoSection } from '../Shared/InfoSection'
import { TruncatedText } from '../Shared/TruncatedText'
import { useTransition, animated } from 'react-spring'
import { GroupClassFormModal } from '.'

type StatusProps = {
  groupClass: GroupClass<true>
}

export const GroupClassStatus = ({ groupClass }: StatusProps) => {
  const getClassStatus = (groupClass: GroupClass) => {
    if (isToday(new Date(groupClass.dates[0]))) return 'Starts Tonight'
    if (isTomorrow(new Date(groupClass.dates[0]))) return 'Starts Tomorrow'
    if (
      isAfter(
        new Date(groupClass.dates[0]),
        addMonths(startOfMonth(new Date()), 1),
      )
    )
      return 'Starts Next Month'

    if (
      isThisWeek(new Date(groupClass.dates[0])) &&
      !isPast(new Date(groupClass.dates[0]))
    )
      return 'Starts This Week'

    if (classIsCurrent(groupClass)) return 'In Session'
    if (hasFinished(groupClass.dates)) return 'Class Completed'
    return 'Starts This Month'
  }

  const getClassStatusStyles = (groupClass: GroupClass) => {
    if (
      isAfter(
        new Date(groupClass.dates[0]),
        addMonths(startOfMonth(new Date()), 1),
      )
    )
      return 'text-green-800 bg-green-100'

    if (
      isThisWeek(new Date(groupClass.dates[0])) &&
      !isPast(new Date(groupClass.dates[0]))
    )
      return 'text-green-800 bg-green-100'

    if (hasFinished(groupClass.dates)) return 'text-teal-800 bg-teal-100'

    if (classIsCurrent(groupClass)) return 'text-red-800 bg-red-100'

    return 'text-green-800 bg-green-100'
  }

  // const getClassIcon = (groupClass: GroupClass) => {
  //   if (classIsCurrent(groupClass)) return <WarningIcon />

  //   if (hasFinished(groupClass.dates))
  //     return <CheckboxSolidIcon className={getClassStatusStyles(groupClass)} />

  //   return ''
  // }

  return (
    <span
      className={
        'px-4 py-1 text-md font-medium rounded-full' +
        ' ' +
        getClassStatusStyles(groupClass)
      }>
      {getClassStatus(groupClass)}
    </span>
  )
}

type Props = {
  groupClass?: GroupClass<true>
}

export const GroupClassCard = ({ groupClass }: Props) => {
  const [signInOpen, setSignInOpen] = useState(false)
  const { remove } = useFirestore('groupClasses')
  const { hasRole, hasAnyRole } = usePermissions()
  const [editFormVisible, setEditFormVisible] = useState(false)
  const { navigateTo } = useRouter()

  const animation = useTransition(groupClass, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 1 },
  })

  const getDefaultExperienceRequired = ({ level }: GroupClass) => {
    if (level === 'Intermediate') return 'Over 4 Months of Dancing'
    if (level === 'Advanced') return 'Over 1 year of Dancing'
    return 'No Experience Required, No Partner Required'
  }

  return animation((style, groupClass) => (
    <animated.div style={style}>
      {groupClass && (
        <section className='sm:m-5 sm:mt-8 relative flex flex-col mx-2 my-8 bg-white rounded-lg shadow-2xl'>
          <div className='absolute z-30 mt-2 ml-2'>
            <GroupClassStatus groupClass={groupClass} />
          </div>
          {/* Title */}
          {/* {hasAnyRole(['admin', 'employee']) && (
            <header className='absolute right-0 z-30 flex justify-center mr-2 space-x-2 text-center'>
              <Button
                variant='raised'
                className='my-5'
                onClick={() => setEditFormVisible(true)}>
                Edit Class
              </Button>

              {hasRole('admin') && (
                <Button
                  variant='warn'
                  className='my-5'
                  onClick={() => remove(groupClass.id)}>
                  Remove
                </Button>
              )}
            </header>
          )} */}

          {/* Image */}
          <Video
            alt=''
            fit='cover'
            src={`videos/${groupClass.video}`}
            overlay='light base'
            className='h-80 sm:h-80 rounded-t-2xl bg-gray-500'>
            <div className='flex flex-col items-center justify-center h-full text-white'>
              <h2 className=' text-4xl font-bold text-center'>
                {groupClass.name}
              </h2>
            </div>
          </Video>

          {/* Description */}
          <div className='sm:rounded-lg shadow-sm'>
            <div className='sm:p-0 px-4 py-5 border-t border-gray-200'>
              <div className='divide-y divide-gray-200'>
                <InfoSection title='First Class Date'>
                  {formatDate('readable', groupClass.dates[0])}
                </InfoSection>

                <InfoSection title='Level'>
                  {groupClass.level}
                  {/* {getExperienceRequired(groupClass)} */}
                </InfoSection>

                <InfoSection title='Prerequisites'>
                  {groupClass.requirements ||
                    getDefaultExperienceRequired(groupClass)}
                </InfoSection>

                {groupClass.dates.length > 1 ? (
                  <InfoSection title="What's Included?">
                    {groupClass.dates.length + ' '} classes,{' '}
                    {groupClass.dates.length + ' '} social dance events
                  </InfoSection>
                ) : (
                  <InfoSection title="What's Included?">
                    1 Class, 2 hours of dancing
                  </InfoSection>
                )}

                <InfoSection title='Description'>
                  <TruncatedText truncated>
                    {groupClass.description}
                  </TruncatedText>
                </InfoSection>

                <InfoSection title='Price'>
                  {groupClass.dates.length > 1 ? '$25 / Week' : '$25'}
                </InfoSection>
              </div>

              {/* Price */}
            </div>
          </div>

          <button
            onClick={() => {
              // isLoggedIn() ? setSignUpOpen(true) : setSignInOpen(true)
              navigateTo('/swing-dance-classes/groupClasses/' + groupClass.id)
            }}
            className='hover:bg-indigo-500 rounded-b-2xl flex justify-center w-full mt-auto text-white transition-all duration-200 bg-indigo-700 cursor-pointer'>
            <span className='p-3 text-2xl font-bold'>More Info</span>
          </button>

          {/* <SignInModal
            isOpen={signInOpen}
            onClose={() => setSignInOpen(false)}
            title='Account Required'
          /> */}

          {/* {(hasRole('employee') || hasRole('admin')) && (
            <GroupClassFormModal
              groupClass={groupClass}
              isOpen={editFormVisible}
              closeForm={() => setEditFormVisible(false)}
              title={'Edit ' + groupClass.name}
            />
          )} */}
        </section>
      )}
    </animated.div>
  ))
}
