import { User } from 'app/firebase'
import { AvatarHeading } from 'app/shared'
import { IMAGES } from 'app/stlswing/stlswing.constants'
import React from 'react'

type Props = {
  user?: User
  pill?: [color: string, text: string]
}

export const UserAvatarHeading = ({ user, pill }: Props) => (
  <AvatarHeading
    image={user?.photoUrl || IMAGES.DEFAULT.person}
    title={user?.displayName || 'Missing Name'}
    subText={user?.email.replace('gmail.com', '') || 'Missing Email'}
    pill={pill}
  />
)
