import { sendEmails } from 'app/firebase/functions/sendgrid/sendEmails'
import googleDocLink from '../../../../../assets/images/google-doc-link.svg'
import {
  Button,
  DocumentDefaults,
  FormModal,
  Input,
  Textarea,
  useFirestore,
  CurrentUserContext,
  TableSchema,
  DataTable,
  Modal,
  Select,
  Option,
} from 'app/shared'
import { Image } from 'app/shared/components/Media/Image'
import { formatDate } from 'app/stlswing/helpers/date.helpers'
import { useGetTeachers } from 'app/stlswing/hooks'
import { AdminMobileHeader } from 'app/stlswing/pages'
import { compareAsc } from 'date-fns'
import { useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import { CalendarHeader } from 'app/shared/components/Calendar/components'
import { Calendar } from 'app/shared/components/Calendar/Calendar'
import {
  Availability,
  AvailabilityForm,
} from '../../PrivateLessons/AvailabilityForm'
import { PrivateLesson } from 'app/stlswing/stlswing.types'
import { getFirestoreCollection } from 'app/firebase/firestore/firestore.helpers'

const VERSIONS = ['Major', 'Minor', 'Breaking'] as const
// Major -> Not Knowing could potentially make you feel out of the loop
// Minor -> It doesn't Affect you, but it effects the scene
// Breaking -> A Fundamental Change has been made to how we do things
const TYPES = [
  'Marketing',
  'Finances',
  'Venue Setup',
  'Rules / Policy',
  'Website',
  'Group Classes',
  'Private Lessons',
  'DJing',
  'General',
] as const

export type Changelog = {
  name: string
  description: string
  version: typeof VERSIONS[number]
  type: typeof TYPES[number][]
  googleDoc?: string
  image?: string
} & DocumentDefaults

export const PrivateLessonsDashboard = () => {
  const { list: changelogs } = useFirestore('changelog')
  const [openCreate, setOpenCreate] = useState(false)
  const [viewDetails, setViewDetails] = useState<Changelog>()
  const [logToEdit, setLogToEdit] = useState<Changelog>()
  const { user } = useContext(CurrentUserContext)
  const [month, setMonth] = useState(new Date())

  return (
    <div className='bg-white'>
      <AdminMobileHeader title='Private Lessons'>
        <div className='p-4'>
          <Button onClick={() => setOpenCreate(true)}>Add Availability</Button>
        </div>
      </AdminMobileHeader>

      <Calendar events={[]} date={new Date()} />

      <AddPrivateLessonsForm
        isOpen={openCreate}
        closeForm={() => {
          setOpenCreate(false)
          setLogToEdit(undefined)
        }}
      />
    </div>
  )
}

type ChangelogFormProps = {
  isOpen: boolean
  closeForm: () => void
  changelog?: Changelog
}
export const AddPrivateLessonsForm = (props: ChangelogFormProps) => {
  const form = useForm()
  const { user } = useContext(CurrentUserContext)
  const teachers = useGetTeachers()

  const { createWithId, update, list } = useFirestore('privateLessons')

  type FormData = Changelog

  const onSubmit = async (availability: Availability) => {
    Object.values(availability).forEach((date) => {
      const buildPrivateLesson = {
        instructorUserId: '',
        staffId: '',
      }
    })
    console.log(availability)
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.closeForm}
      className='sm:w-1/2 w-screen text-black'
      type='right-panel'>
      <AvailabilityForm onSubmit={onSubmit} />
    </Modal>
  )
}
