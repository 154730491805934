import { ButtonProps, Button, Loading } from 'app/shared'
import React from 'react'

type Props = {
  isSubmitting: boolean
} & ButtonProps

export const SubmitButton = ({ isSubmitting, children, ...props }: Props) => (
  <Button variant='raised' type='submit' {...props}>
    <div className='flex items-center space-x-2'>
      <span>{children}</span>
      {isSubmitting && <Loading className='w-6 h-6' />}
    </div>
  </Button>
)
