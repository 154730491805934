import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { useTransition, animated, useSpring } from 'react-spring'
import useMeasure from 'react-use-measure'

type Props = { title: string }

export const PanelContent = ({ children, title, className }: C<Props>) => {
  const [isOpen, setIsOpen] = useState(false)

  const [panelRef, { height: panelContentHeight }] = useMeasure()
  const animateContentHeight = useSpring({
    to: { height: panelContentHeight },
  })

  const svgClasses = isOpen ? 'rotate-180' : 'rotate-0'

  return (
    <dl className=''>
      <dt className={'leading-7' + className}>
        <button
          onClick={() => setIsOpen((o) => !o)}
          className='focus:outline-none focus:text-gray-900 hover:bg-gray-200 flex items-start justify-between w-full p-2 text-left text-gray-400 transition-all duration-500 cursor-pointer'>
          <h2 className='font-medium text-gray-900'>{title}</h2>
          <span className='h-7 flex items-center ml-6'>
            <div>
              <FontAwesomeIcon
                icon={faChevronDown}
                className={`${svgClasses} h-6 w-6 transform transition-all duration-300`}
              />
              {/* <svg
                className={`${svgClasses} h-6 w-6 transform transition-all duration-300`}
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'>
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth='2'
                  d='M19 9l-7 7-7-7'
                />
              </svg> */}
            </div>
          </span>
        </button>
      </dt>
      <animated.div style={animateContentHeight}>
        {isOpen && <div ref={panelRef}>{children}</div>}
      </animated.div>
    </dl>
  )
}
