import { User } from 'app/firebase'
import { FullPageContent } from 'app/shared/components/Layout/FullPageLayout/Content/FullPageContent'
import { FullPageWrapper } from 'app/shared/components/Layout/FullPageLayout/FullPageWrapper'
import { useGetAdmins, useGetTeachers } from 'app/stlswing/hooks'
import { AdminMobileHeader } from 'app/stlswing/pages'
import { UserCard } from 'app/users/components/UserCard'
import { UserForm } from 'app/users/components/UserForm'
import { useState } from 'react'

export const TeachersDashboard = () => {
  const teachers = useGetTeachers()
  const admins = useGetAdmins()
  const [selectedUser, setSelectedUser] = useState<User<true>>()

  return (
    <FullPageWrapper>
      <AdminMobileHeader title={'Teachers'} />
      <FullPageContent className='grid grid-cols-3 gap-3 p-4'>
        {[...admins, ...teachers].map((teacher) => (
          <UserCard
            index={Number(teacher.id)}
            user={teacher}
            setSelectedUser={() => setSelectedUser(teacher)}
          />
        ))}
      </FullPageContent>

      <UserForm
        isVisible={!!selectedUser}
        onClose={() => setSelectedUser(undefined)}
        user={selectedUser}
      />
    </FullPageWrapper>
  )
}
