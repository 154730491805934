import React, { useContext, useEffect, useRef, useState } from 'react'
import { useScreenshot } from 'use-react-screenshot'
import { Image } from 'app/shared/components/Media/Image'
import { QRCODES } from 'app/stlswing/stlswing.constants'
import { CheckboxSolidIcon } from 'app/shared/components/Icons/CheckboxSolidIcon'
import { WarningIcon } from 'app/shared/components/Icons/WarningIcon'
import { CurrentUserContext, useFirestore, usePermissions } from 'app/firebase'
import { InfoSection } from './InfoSection'
import { QRCodeCanvas, QRCodeSVG } from 'qrcode.react'
import { Event, GroupClass } from 'app/stlswing/stlswing.types'
import { product } from 'ramda'
import { groupClassFilterDefaults } from '../GroupClass/GroupClassFilters/groupClassFilters.helpers'
import { Order } from '../Accounting/finance.types'
import { Button, Loading, useRouter } from 'app/shared'
import { isAfter } from 'date-fns'
import { navigateToStepIndex } from 'app/shared/components/Stepper/helpers/stepper.helpers'

type Props = {
  qrCode: keyof typeof QRCODES
  quantity?: number
  product: GroupClass<true> | Event<true>
  productName: string
}

export const Ticket = React.forwardRef((props: Props, ref: any) => {
  const { user } = useContext(CurrentUserContext)
  const { list: orders } = useFirestore('orders')
  const [order, setOrder] = useState<Order>()
  const { redirectTo } = useRouter()

  useEffect(() => {
    if (!user) return
    setOrder(
      orders?.find((order) => {
        if (
          order.internalProductId === props.product.id &&
          order.internalUserId === user.id
        )
          return true
        return false
      }),
    )
  }, [orders, user, props.product.id])

  const productType = 'dates' in props.product ? 'groupClasses' : 'events'

  const hasEnded = () => {
    if ('dates' in props.product)
      return isAfter(
        new Date(),
        new Date(props.product.dates[props.product.dates.length - 1]),
      )

    if ('dateTime' in props.product) {
      return isAfter(new Date(), new Date(props.product.dateTime))
    }

    return false
  }

  const getValidForText = () => {
    if (productType === 'groupClasses') {
      if (order?.credits === 1) return 'Class'
      return 'Classes'
    }

    if (productType === 'events') return 'Attendees'
  }

  const getValidForAmount = () => {
    if (props.quantity) return props.quantity
    const credits = order?.credits || 0
    return Number(credits)
  }

  return (
    <div
      ref={ref}
      className='bg-gradient-to-br from-indigo-700 rounded-lg border-2 border-yellow-600 sm:max-w-sm w-96  mt-2 text-white bg-indigo-900'>
      <div className='flex justify-between p-4 pb-6'>
        <h2 className='text-4xl font-extrabold'>STL Swing</h2>
        <TicketInfo
          title='#ticket'
          description={props.product.id.slice(0, 6)}
        />
      </div>
      <div className='flex justify-between w-full px-6 py-2'>
        <TicketInfo
          title='User'
          description={user?.displayName.split(' ')[0] || ''}
        />
        {/* <TicketInfo title='quantity' description={`${props.quantity || 1}`} /> */}
        <TicketInfo
          title='Valid For'
          description={
            getValidForAmount() < 1
              ? '...loading'
              : `${getValidForAmount()} ${getValidForText()}`
          }
        />
        <TicketInfo
          title='type'
          description={productType === 'groupClasses' ? 'Class' : 'Event'}
        />
      </div>
      <div className=' flex justify-between w-full px-6 py-2'>
        <TicketInfo title='Product' description={props.product.name} />
      </div>
      <div className='p-4 border-2 my-14 bg-white max-w-fit mx-auto rounded-lg relative'>
        <div className='flex justify-center'>
          {order ? (
            <QRCodeSVG
              value={`https://stlswing.dance/tickets/${order.id}/redeem`}
              size={250}
              level='H'
              fgColor={order?.credits === 0 ? '#eeeeee' : undefined}
            />
          ) : (
            <div className='relative mx-auto w-52 flex flex-col items-center'>
              <Loading className='h-32 w-32' />
              <p className='text-gray-600 font-medium w-40 text-sm text-justify'>
                {!order && ''}
                We are generating a unique QR Code. This ticket will be sent to
                your email upon completion. As a logged in user, you will be
                able to view this generated ticket from this website at any time
                on mobile or desktop.
              </p>
            </div>
          )}
        </div>
        {order?.credits === 0 && (
          <div className='absolute top-1/4 left-0 w-full space-y-2  font-bold h-24 flex flex-col items-center justify-center'>
            <p className='text-gray-600 text-lg'>This Ticket Is Expired</p>
            {!hasEnded() && (
              <Button
                variant='raised'
                onClick={() =>
                  redirectTo(
                    order.internalProductUrl.replace(
                      'https://stlswing.dance',
                      '',
                    ),
                  )
                }>
                Purchase Remaing Classes
              </Button>
            )}
            <p className='text-xs text-gray-500 text-center'>
              If you think this is a mistake, email info@stlswing.dance
            </p>
          </div>
        )}

        {user && order && user.id !== order.internalUserId && (
          <div className='absolute top-1/4 left-0 w-full space-y-2  font-bold h-24 flex flex-col items-center justify-center'>
            <p className='text-gray-600 '>Your are not this tickets owner</p>
            <Button variant='raised'>Logout</Button>
          </div>
        )}
      </div>
    </div>
  )
})

type TicketInfoProps = {
  title: string
  description: string
}
const TicketInfo = (props: TicketInfoProps) => {
  return (
    <div className=''>
      <p className='text-xs font-bold uppercase text-yellow-500'>
        {props.title}
      </p>
      <div className='text-md'>{props.description}</div>
    </div>
  )
}
