import React, { useEffect, useState, useContext, useRef } from 'react'
import { useScreenshot } from 'use-react-screenshot'
import html2canvas from 'html2canvas'
import { CurrentUserContext, usePermissions } from 'app/firebase'
import {
  FloatingActionButton,
  Modal,
  Option,
  Select,
} from 'app/shared/components'
import { Ticket } from '../Shared/Ticket'
import { isFuture } from 'date-fns'
import { ClassOrder, EventOrder, useUsers } from 'app/stlswing/hooks'
import { Event, GroupClass } from 'app/stlswing/stlswing.types'
import { uniqBy, prop, propEq } from 'ramda'
import { MembershipTicket } from '../Tickets/MembershipTicket'

export const MyTickets = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { isLoggedIn } = usePermissions()
  const { userEventOrders, userClassOrders, userHasGroupClass } = useUsers()
  const { user } = useContext(CurrentUserContext)

  // A 'Ticket' is just a Group Class/Event Object
  const [userTickets, setUserTickets] = useState<
    (Event<true> | GroupClass<true>)[]
  >([])
  const [selectedTicket, setSelectedTicket] = useState<
    Event<true> | GroupClass<true>
  >()

  useEffect(() => {
    const events: EventOrder[] = userEventOrders
      ? userEventOrders.filter((order) =>
          isFuture(new Date(order.data.dateTime)),
        )
      : []

    const groupClasses: ClassOrder[] = userClassOrders
      ? userClassOrders
          .filter(
            (order) =>
              order.credits &&
              !!order.data?.dates &&
              isFuture(
                new Date(order.data?.dates[order.data?.dates.length - 1]),
              ),
          )
          .filter((order) => userHasGroupClass(order.data.id))
      : []

    const ticketProducts = [...groupClasses, ...events].map((d) => d.data)
    setSelectedTicket(ticketProducts[0])
    setUserTickets(ticketProducts)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEventOrders, userClassOrders])

  if (!isLoggedIn()) return null
  if (user?.member || user?.danceMember)
    return (
      <>
        {!isOpen && (
          <FloatingActionButton
            text='View Membership Pass'
            formVisible={isOpen}
            openForm={() => setIsOpen(true)}
          />
        )}
        <Modal
          type='right-panel'
          title='Membership Pass'
          className='w-screen sm:w-2/5 overflow-y-scroll'
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}>
          <div className=''>
            <div className='flex justify-center'>
              <MembershipTicket type={'Class'} />
            </div>
          </div>
        </Modal>
      </>
    )

  if (userTickets.length === 0) return null
  return (
    <>
      {!isOpen && (
        <FloatingActionButton
          text='View Tickets'
          formVisible={isOpen}
          openForm={() => setIsOpen(true)}
        />
      )}
      <Modal
        type='right-panel'
        title='My Tickets'
        className='w-screen sm:w-2/5 overflow-y-scroll'
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}>
        <div className=''>
          <div className='p-2'>
            {/* {user?.member ? (
              <p>
                Congrats on your membership! To get additional class tickets,
                simply navigate to the class page and click 'Sign Up'.{' '}
              </p>
            ) : (
              <p>Show your ticket code at the door for entry into the dance!</p>
            )} */}
            {userTickets && (
              <Select
                name='tickets'
                label='Ticket To Display'
                data-testid='tickets-select'
                defaultValues={selectedTicket ? [selectedTicket.id] : []}
                onSelect={(id) => {
                  const chosenTicket = userTickets.find(propEq('id', id))
                  if (!chosenTicket) return
                  setSelectedTicket(chosenTicket)
                }}>
                {uniqBy(prop('name'), userTickets).map(({ name, id }) => (
                  <Option value={id} label={name} key={id} />
                ))}
              </Select>
            )}
          </div>

          <div className='flex justify-center'>
            {userTickets && selectedTicket && (
              <Ticket
                product={selectedTicket}
                productName={selectedTicket.name}
                qrCode={selectedTicket.qrCode}
                quantity={
                  userTickets.filter(propEq('id', selectedTicket.id)).length
                }
              />
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}
