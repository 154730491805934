import React, { useState } from 'react'
import { Button } from 'app/shared/components'
import { Image } from 'app/shared/components/Media/Image'
import { Video } from 'app/shared/components/Media/Video'
import { useFirestore, usePermissions, useRouter } from 'app/shared/hooks'
import { Event } from '../../stlswing.types'
import { formatCurrency } from '../Accounting/helpers'
import { InfoSection } from '../Shared/InfoSection'
import { TruncatedText } from '../Shared/TruncatedText'
import { EventCode } from './EventCode'
import { useTransition, animated } from 'react-spring'
import { EventFormModal } from '.'
import { formatDate } from 'app/stlswing/helpers/date.helpers'

type Props = {
  event?: Event<true>
}

export const EventCard = ({ event }: Props) => {
  // const [codeShowing, setCodeShowing] = useState(false)
  // const { hasRole, hasAnyRole } = usePermissions()
  const { navigateTo } = useRouter()
  // const { remove } = useFirestore('events')
  // const [editFormVisible, setEditFormVisible] = useState(false)

  const animation = useTransition(event, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 1 },
  })

  const getTypeStyles = (event: Event) => {
    if (event.type === 'Weekly') return 'text-green-800 bg-green-100'
    return 'text-blue-800 bg-blue-100'
  }

  const getEventPrice = (event: Event) => {
    // if (event.type === 'Monthly' || event.type === 'Chelsea Reed')
    // return '$20($25 at the door)'
    if (event.type === 'Weekly') return '$10'
    if (event.type === 'Harmonizers Dance')
      return '$15 (tickets available at the door)'
    return '$20 (tickets available at the door)'
  }

  return animation((style, event) => (
    <animated.div style={style}>
      {event && (
        <section className='sm:m-5 sm:mt-8  relative flex flex-col mx-2 my-8 bg-white rounded-lg shadow-2xl'>
          <div className='absolute z-30 mt-2 ml-2'>
            <span
              className={
                'px-4 py-1 text-md font-medium rounded-full' +
                ' ' +
                getTypeStyles(event)
              }>
              {event.type === 'Weekly'
                ? 'Included With Class Purchase'
                : 'Live Music Event'}
            </span>
          </div>

          {/* {hasAnyRole(['admin', 'employee']) && (
            <header className='absolute right-0 z-30 flex justify-center mr-2 space-x-2 text-center'>
              <Button
                variant='raised'
                className='my-5'
                onClick={() => setEditFormVisible(true)}>
                Edit Class
              </Button>

              {hasRole('admin') && (
                <Button
                  variant='warn'
                  className='my-5'
                  onClick={() => remove(event.id)}>
                  Remove
                </Button>
              )}
            </header>
          )} */}

          {(event.type === 'Monthly' ||
            event.type === 'Chelsea Reed' ||
            event.type === 'Harmonizers Dance') && (
            <Video
              alt=''
              fit='cover'
              src={`videos/battle-allskate.mp4`}
              // src={`videos/${event.video}`}
              overlay='light base'
              className='h-80 sm:h-80 rounded-t-2xl bg-gray-500'>
              <div className='flex flex-col items-center justify-center h-full text-white'>
                <h2 className=' text-4xl font-bold text-center'>
                  {event.name}
                </h2>
              </div>
            </Video>
          )}

          {event.type === 'Weekly' && (
            <Image
              alt=''
              fit='cover'
              overlay
              className='h-80 sm:h-80 rounded-t-2xl bg-gray-500'
              src={'images/lhsl-home-cover.jpg'}>
              <div className='flex flex-col items-center justify-center h-full text-white'>
                <h2 className=' text-4xl font-bold text-center'>
                  {event.name}
                </h2>
              </div>
            </Image>
          )}

          {/* Description */}
          <div className='sm:rounded-lg shadow-sm'>
            <div className='sm:p-0 px-4 py-5 bg-white border-t border-gray-200'>
              <dl className='flex flex-col justify-between divide-y'>
                <InfoSection title='Date and Time'>
                  {/* {formatDate('readable-date-and-time', event.dateTime)} */}

                  {/* {event.type === 'Monthly'
                    ? 'Friday, April 1 at 7:30pm'
                    : 'Wednesday at 8:30pm'} */}

                  {formatDate(
                    'readable-date-and-time',
                    new Date(event.dateTime),
                  )}
                </InfoSection>

                <InfoSection title='Encouraged Attire'>
                  {event.type === 'Weekly'
                    ? 'Informal Dress'
                    : 'Business Casual, Formal, or Vintage'}
                </InfoSection>

                <InfoSection title='Description'>
                  <TruncatedText truncated={true}>
                    {event.description}
                  </TruncatedText>
                </InfoSection>

                <InfoSection title='Price'>
                  {`${getEventPrice(event)}`}
                </InfoSection>
              </dl>
            </div>
          </div>
          <button
            onClick={() => {
              navigateTo('/swing-dance-events/' + event.id)
            }}
            className='hover:bg-indigo-500 rounded-b-2xl flex justify-center w-full mt-auto text-white transition-all duration-200 bg-indigo-700 cursor-pointer'>
            <span className='p-3 text-2xl font-bold'>More Info</span>
          </button>
          {/* <EventCode
            qrcode='Blue / Red'
            isOpen={codeShowing}
            setIsOpen={setCodeShowing}
            name={event.name}
          /> */}
          {/* <EventFormModal
            title='Edit'
            event={event}
            closeForm={() => setEditFormVisible(false)}
            isOpen={editFormVisible}
          /> */}
        </section>
      )}
    </animated.div>
  ))
}
