import React, { useState, useEffect, useContext } from 'react'
import { addMonths, startOfMonth } from 'date-fns'
import { useUsers } from 'app/stlswing/hooks'
import {
  Button,
  useFirestore,
  useRouter,
  useToggle,
  usePermissions,
  Assets,
  useUI,
  Select,
  Option,
  UIContext,
  Modal,
} from 'app/shared'
import { HomeIcon } from 'app/shared/components/Icons/HomeIcon'
import { PeopleIcon } from 'app/shared/components/Icons/PeopleIcon'
import Users from 'app/users/Users'
import { MoneyIcon } from 'app/shared/components/Icons/MoneyIcon'
import { TicketIcon } from 'app/shared/components/Icons/TicketIcon'
import { TeacherIcon } from 'app/shared/components/Icons/TeacherIcon'
import { FolderIcon } from 'app/shared/components/Icons/FolderIcon'
import { BookIcon } from 'app/shared/components/Icons/BookIcon'
import { QuestionMarkIcon } from 'app/shared/components/Icons/QuestionMarkIcon'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyBill, faClipboardList } from '@fortawesome/free-solid-svg-icons'
import { without } from 'ramda'
import { Breadcrumbs } from 'app/shared/components/Layout/Breadcrumbs'
import { tab } from '@testing-library/user-event/dist/tab'
import Burger from 'app/shared/components/Button/Burger'
import { LoggedInGuard } from 'app/auth/LoggedInGuard'
import { AdminRoutes } from './AdminRoutes'

const tabsList = [
  'Dashboard',
  'Changelog',
  'Costs',
  'Orders',
  'Assets',
  'Events',
  'Classes',
  'PrivateLessons',
  'History',
  'Faq',
  'Users',
  'Members',
  'Teachers',
  'CardReader',
] as const
type Tabs = typeof tabsList[number]

type Props = {
  tab?: Tabs
}

export const AdminLayout = (props: Props) => {
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const { hasAnyRole } = usePermissions()
  const { isMobile } = useUI()
  const { redirectTo, currentRoute } = useRouter()
  const { setShowHeader, setShowFooter } = useContext(UIContext)
  const [currentTab, setCurrentTab] = useState<Tabs>(props.tab || 'Dashboard')

  useEffect(() => {
    setShowFooter(false)

    return () => {
      setShowFooter(true)
    }
  }, [])

  useEffect(() => {
    redirectTo('/Admin/' + currentTab)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab])

  const toOmit: Tabs[] = hasAnyRole(['admin'])
    ? ['Assets', 'Costs', 'Faq']
    : ['Costs', 'Orders', 'Assets', 'Faq', 'Assets', 'Teachers']

  const selected =
    'bg-indigo-200 text-indigo-800 group flex items-center px-2 py-2 text-base font-medium rounded-md'
  const notSelected =
    'cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md'

  return (
    <div className='w-screen sm:w-full sm:min-w-full overflow-hidden bg-gray-200'>
      <div className='h-16 w-full bg-gray-800' />

      <LoggedInGuard afterLogin={() => {}}>
        {/* Tabs List */}
        <div className='sm:flex overflow-hidden'>
          {/* Desktop Sidebar */}
          {!isMobile && (
            <div className='relative flex flex-col bg-gray-100 h-screen -mt-16 pt-16 w-1/5'>
              <nav className='md:min-w-max px-2 mt-5 space-y-1'>
                {without<Tabs>(toOmit, tabsList).map((tab) => (
                  <button
                    className={`${
                      tab === currentTab ? selected : notSelected
                    } w-full`}
                    data-testid={`${tab}-admin-link`}
                    onClick={() => setCurrentTab(tab)}>
                    {getTabIcon(tab)}
                    {sidebarOpen && (
                      <span className='md:block hidden'>{tab}</span>
                    )}
                  </button>
                ))}
              </nav>
            </div>
          )}

          {/* Page Content */}
          <div className='max-w-screen w-full overflow-hidden'>
            <AdminRoutes />
          </div>
        </div>
      </LoggedInGuard>
    </div>
  )
}

const getTabIcon = (tab: Tabs) => {
  if (tab === 'Dashboard') return <HomeIcon />
  if (tab === 'Assets') return <FolderIcon />
  if (tab === 'Users') return <PeopleIcon />
  if (tab === 'Members') return <PeopleIcon />
  if (tab === 'Teachers') return <PeopleIcon />
  if (tab === 'Orders') return <MoneyIcon />
  if (tab === 'Costs')
    return (
      <FontAwesomeIcon
        icon={faMoneyBill}
        className='mr-3 w-6 h-6 text-gray-400 '
      />
    )
  if (tab === 'Events') return <TicketIcon />
  if (tab === 'Classes') return <TeacherIcon />
  if (tab === 'PrivateLessons') return <TeacherIcon />
  if (tab === 'History') return <BookIcon />
  if (tab === 'Faq') return <QuestionMarkIcon />
  if (tab === 'Changelog')
    return (
      <FontAwesomeIcon
        className='text-gray-400 ml-1 mr-3 w-7 h-7'
        icon={faClipboardList}
      />
    )
}

type AdminHeaderProps = {
  title: string
}
export const AdminMobileHeader = (props: C<AdminHeaderProps>) => {
  const [open, setOpen] = useState(false)
  const { hasAnyRole } = usePermissions()

  const { currentRoute, redirectTo, navigateTo } = useRouter()

  const toOmit: Tabs[] = hasAnyRole(['admin'])
    ? ['Assets', 'Costs', 'Faq']
    : ['Orders', 'History', 'Assets', 'Costs', 'Faq', 'Teachers']

  return (
    <div className='h-16 bg-white flex'>
      <h1 className='p-4 flex items-center text-2xl font-bold'>
        {props.title}
      </h1>

      {props.children}

      <Burger isOpen={open} setIsOpen={setOpen} dark />

      <Modal
        isOpen={open}
        onClose={() => setOpen(false)}
        title='Admin Options'
        className='w-10/12'>
        <nav className='grid grid-cols-3 mx-auto gap-2 p-2'>
          {without(toOmit, tabsList).map((tab) => (
            <button
              onClick={() => {
                redirectTo('/admin/' + tab)
                setOpen(false)
              }}
              className={`flex flex-col items-center p-2 justify-center rounded-lg border ${
                currentRoute.includes(tab) && 'bg-red-200 text-red-800'
              }`}>
              <p>{tab}</p>
              {getTabIcon(tab)}
            </button>
          ))}
        </nav>
      </Modal>
    </div>
  )
}

export default AdminLayout
