import React from 'react'
import { useFirestore, User, Role } from '../../firebase'
import { AvatarHeading } from 'app/shared/components'
import { UserAvatarHeading } from './UserAvatarHeading'

type Props = {
  user: User<true>
  permissionsToShow?: string
  index: number
  setSelectedUser: SetState<User<true>>
}

export const UserCard = ({ user, setSelectedUser, index }: Props) => {
  const { map } = useFirestore('permissions')

  const getPillProps = (role: Role): [string, string] => {
    if (role === 'admin') return ['blue', 'Administrator']
    if (role === 'employee') return ['green', 'Teacher']
    if (role === 'customer') return ['yellow', 'Student']
    return ['red', 'unkown']
  }

  return (
    <div className='col-span-1 bg-white rounded-lg shadow h-36' key={index}>
      <div className='flex items-center justify-between w-full p-6 space-x-6'>
        <div className='flex-1 truncate'>
          {map && map[user.id] && (
            <UserAvatarHeading
              user={user}
              pill={getPillProps(map[user.id].role)}
            />
          )}
        </div>
      </div>

      <div
        className={` border-t border-gray-200 transition duration-all hover:bg-gray-200`}>
        <div className='flex -mt-px'>
          <div className='flex flex-1 w-0 border-r border-gray-200'>
            <button
              onClick={() => setSelectedUser(user)}
              className='hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 relative inline-flex items-center justify-center flex-1 w-0 py-4 -mr-px text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out border border-transparent rounded-bl-lg'>
              <span className={`ml-3 font-medium`}>Edit User</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
