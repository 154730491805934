import React, { useState, useEffect } from 'react'
import { useFirestore, User } from 'app/firebase'
import { Search } from 'app/shared/components'
import { fuzzySearch } from 'app/shared/utils'
import { UserCard } from './components/UserCard'
import { UserForm } from './components/UserForm'
import { sortBy, prop } from 'ramda'
import { Option, Select } from 'app/shared'
import { usePagination } from 'app/shared/components/Table/hooks/usePagination'
import { Pagination } from 'app/shared/components/Table/components/Pagination'
import { AdminMobileHeader } from 'app/stlswing/pages'

export const Users = () => {
  const [searchTerm, setSearchTerm] = useState('')
  const { list: users } = useFirestore('users')
  const { map: permissionsMap } = useFirestore('permissions')
  const [sortProperty, setSortProperty] =
    useState<keyof User<true>>('displayName')
  const [selectedUser, setSelectedUser] = useState<User<true>>()

  const pagination = usePagination(9)

  useEffect(() => {
    if (!users) return
    console.log(
      fuzzySearch(
        users.map(({ email, id, displayName }) => {
          return {
            email: email,
            id: id,
            name: displayName,
          }
        }),
        searchTerm,
      ),
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm, sortProperty])

  return (
    <div className='w-full z-0'>
      <AdminMobileHeader title='Users'></AdminMobileHeader>
      <div className='sm:flex sm:space-x-3 items-center p-4'>
        <div className='flex-grow-1 w-full'>
          <Search onSearch={setSearchTerm} />
        </div>
        {users && users[0] && (
          <Select<keyof User<true>>
            noLabel
            className='w-32 h-full'
            name='sortBy'
            label='Sort By'
            onSelect={setSortProperty}>
            {Object.keys(users[0]).map((property) => (
              <Option label={property} value={property} />
            ))}
          </Select>
        )}
      </div>

      <UserForm
        isVisible={!!selectedUser}
        onClose={() => setSelectedUser(undefined)}
        user={selectedUser}
      />

      {/* Cards List */}
      <div className='sm:grid-cols-2 lg:grid-cols-3 grid grid-cols-1 gap-5 p-4'>
        {users &&
          permissionsMap &&
          sortBy(
            // @ts-ignore
            prop(sortProperty || 'displayName'),
            pagination.getItemsOnPage(
              fuzzySearch(users, searchTerm),
              pagination.currentPage,
            ),
          ).map((user, i) => (
            // @ts-ignore
            <UserCard user={user} index={i} setSelectedUser={setSelectedUser} />
          ))}
      </div>

      <Pagination
        items={fuzzySearch(users || [], searchTerm)}
        usePagination={pagination}
      />
    </div>
  )
}
export default Users
