import { ActionsMenu, Button, User, useFirestore } from 'app/shared'
import { prop } from 'lodash/fp'
import React from 'react'
import { toast } from 'react-toastify'

interface Props {
  allAttendees?: User<true>[]
  setEmailReceivers: SetState<User<true>[] | undefined>
  addAttendeeToClass?: () => void
}

export const AttendeesTableActions = ({
  allAttendees,
  setEmailReceivers,
  addAttendeeToClass,
}: Props) => {
  const onClickEmailSend = () => {
    if (!allAttendees) return
    setEmailReceivers(allAttendees)
  }

  return (
    <ActionsMenu>
      <div className='w-screen sm:w-52 grid grid-cols-2 sm:grid-cols-1 gap-2 p-2 mb-20'>
        <Button onClick={onClickEmailSend} className='py-4'>
          Email All Attendees
        </Button>

        <Button onClick={addAttendeeToClass} className='py-4'>
          Add Attendee To Class
        </Button>

        <Button
          onClick={() => toast.success('stfu i worked hard on this garbage')}
          className='py-4'>
          Cry More About The Website Being Broken
        </Button>
      </div>
    </ActionsMenu>
  )
}
