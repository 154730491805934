import { any } from 'ramda'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { InlineDescriptionSlider } from 'app/shared'
import { TEACHERS, GROUP_CLASS_LEVELS } from '../../../stlswing.constants'
import { GroupClass } from '../groupClass.types'
import { addOrRemove } from 'app/shared/utils/arrays'
import { ClassFilters } from './groupClassFilters.helpers'

type Props = {
  filters: ClassFilters
  setFilters: SetState<ClassFilters>
}

export const GroupClassFilters = ({ filters, setFilters }: Props) => {
  return (
    <>
      <p className='text-lg font-semibold pl-4 pt-4'>Class Difficulty</p>
      <div className='border-b p-4'>
        {GROUP_CLASS_LEVELS.slice(1, 4).map((level) => (
          <InlineDescriptionSlider
            name={level}
            description=''
            onClick={() =>
              setFilters((prev) => ({
                ...prev,
                level: addOrRemove(level, prev.level),
              }))
            }
            checked={!filters.level.includes(level)}
          />
        ))}
      </div>

      <p className='text-lg font-semibold pl-4 pt-4'>Teachers</p>
      <div className='border-b p-4'>
        {TEACHERS.map((teacher) => (
          <InlineDescriptionSlider
            name={teacher.name}
            description=''
            color={'bg-' + teacher.color}
            onClick={() =>
              setFilters((filters) => ({
                ...filters,
                instructors: addOrRemove(teacher.id, filters.instructors),
              }))
            }
            checked={!filters.instructors.includes(teacher.id)}
          />
        ))}
      </div>
    </>
  )
}
