import React from 'react'
import { Select, Option } from 'app/shared'
import { useForm } from 'react-hook-form'
import { GroupClass } from 'app/stlswing/stlswing.types'
import { DANCE_FORMS } from 'app/stlswing/stlswing.constants'

type Props = {
  form: ReturnType<typeof useForm>
  danceForm?: GroupClass['danceForm']
}

export const DanceFormSelect = ({ form, danceForm }: Props) => (
  <Select
    form={form}
    label='Dance Form'
    name='danceForm'
    data-testid='dance-form-select'
    defaultValues={[danceForm || 'Lindy Hop']}
    required>
    {DANCE_FORMS.map((form) => (
      <Option value={form} label={form} key={form} />
    ))}
  </Select>
)
