import React from 'react'
import { formatDate } from 'app/stlswing/helpers/date.helpers'
import {
  ADDRESS_DESCRIPTION,
  GROUP_CLASS_ADDRESS,
} from 'app/stlswing/stlswing.constants'
import { compareAsc } from 'date-fns'
import { InfoSection } from './InfoSection'

type Props = {
  price?: number
  danceForm?: string
  level?: string
  dates?: string[]
  singleDate?: string
  requirements?: string
  hideTitle?: boolean
}

export const DetailsSection = (props: C<Props>) => (
  <div className='bg-gray-100 flex items-center justify-center'>
    <div className='w-full px-4 py-12  divide-y divide-gray-300 max-w-5xl'>
      {!props.hideTitle && (
        <h2 className='sm:text-4xl p-8 pl-0 text-3xl font-extrabold tracking-tight'>
          Details
        </h2>
      )}

      {/* {props.price && (
      <InfoSection title='Price'>{formatCurrency(props.price)}</InfoSection>
    )} */}
      {props.singleDate && (
        <InfoSection title='Date and Time'>
          {formatDate('readable-date-and-time', props.singleDate)}
        </InfoSection>
      )}

      <InfoSection title='Partner'>No Partner Required!</InfoSection>

      <InfoSection title='Location'>{ADDRESS_DESCRIPTION}</InfoSection>

      <InfoSection title='Address'>{GROUP_CLASS_ADDRESS}</InfoSection>
      {props.danceForm && (
        <InfoSection title='Swing Dance Form'>{props.danceForm}</InfoSection>
      )}

      {props.level && (
        <InfoSection title='Difficulty'>{props.level}</InfoSection>
      )}

      {props.dates && (
        <InfoSection title='All Dates' columns='1'>
          <div>
            {props.dates
              .map((d) => new Date(d))
              .sort(compareAsc)
              .map((date, i) => (
                <p key={i}>{formatDate('readable-date-and-time', date)}</p>
              ))}
          </div>
        </InfoSection>
      )}

      {props.children}
    </div>
  </div>
)
