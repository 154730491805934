import { Select, SelectProps, Option } from 'app/shared'
import { useFirestore, User } from 'app/firebase'
import React from 'react'

type Props = Partial<SelectProps<string>> & {
  selectUser: (user: User<true>) => void
  userId?: string
}
export const UserSelect = (props: Props) => {
  const { list: users, collectionMap: usersMap } = useFirestore('users')

  const onSelectUser = (id: string) => {
    if (!usersMap) return
    props.selectUser(usersMap[id])
  }

  if (!users) return null
  return (
    <Select
      name='user-select'
      label='User Select'
      search
      className='w-full text-black'
      defaultValues={props.userId ? [props.userId] : []}
      onSelect={onSelectUser}>
      {users.map((user) => (
        <Option label={user.displayName} value={user.id}>
          <div className='flex items-center space-x-2'>
            <p>{user.displayName}</p>
            <p className='text-gray-400 text-xs'>{user.email}</p>
          </div>
        </Option>
      ))}
    </Select>
  )
}
