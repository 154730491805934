import React, { useEffect, useState } from 'react'
import { PageHeading } from 'app/layout/PageHeading'
import { Button, useFirestore, useRouter, useUI } from 'app/shared'
import { Event } from 'app/stlswing/stlswing.types'
import { useAnalytics } from 'app/shared/hooks/useAnalytics'
import { DescriptionSection } from '../components/Shared/DescriptionSection'
import { CarouselSection } from '../components/Shared/CarouselSection'
import { DetailsSection } from '../components/Shared/DetailsSection'
import { formatCurrency } from '../components/Accounting/helpers'
import { StickyHeader } from 'app/shared/components/Layout/Heading/StickyHeading'
import { ScheduleStep } from '../components/Shared/ScheduleStep'
import { EventPaymentButton } from './EventPaymentButton'
import { formatDate } from '../helpers/date.helpers'
import { EventType } from '@testing-library/react'
import { EventSignup } from '../components/Events/EventSignup'
import { getEventPrice } from '../components/Events/event.helpers'

export const EventPage = () => {
  const { viewContentEvent } = useAnalytics()
  const { routeParams } = useRouter<{ id: string }>()
  const { getSingle: getEvent } = useFirestore('events')
  const [event, setEvent] = useState<Event<true>>()
  const { isMobile } = useUI()

  const [signupOpen, setSignupOpen] = useState(false)

  useEffect(() => {
    if (!event) return
    viewContentEvent(event.name, 'social-event', [event.id])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [event])

  useEffect(() => {
    if (!routeParams.id) return
    // @ts-ignore
    getEvent(routeParams.id).then(setEvent)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams.id])

  const getEventSubtext = (type: Event['type']) => {
    if (type === 'Monthly' || type === 'Chelsea Reed')
      return "Don't know how to dance? Our event kicks off with a 1 hour introductory lesson."
    if (type === 'Harmonizers Dance')
      return "Don't know how to dance? Our event kicks off with a 30 minutes introductory lesson."
    return ''
  }

  return (
    <div className=' relative z-0'>
      <PageHeading
        title={event?.name || 'loading . . .'}
        video={!isMobile ? 'videos/battle-allskate.mp4' : undefined}
      />
      {event && (
        <>
          <div className=' bg-white p-4 space-y-4 flex flex-col items-center justify-center'>
            <h3 className='flex justify-center w-full text-xl font-semibold text-black'>
              <span>
                {formatDate('readable-date-and-time', new Date(event.dateTime))}
              </span>
            </h3>

            <div className='max-w-md'>
              <Button
                variant='raised'
                className='flex items-center justify-center w-full h-full align-middle'
                onClick={() => {
                  setSignupOpen(true)
                }}>
                Get Tickets (also available at the door for a $5 increase)
              </Button>
            </div>

            <EventSignup
              event={event}
              isOpen={signupOpen}
              onClose={() => setSignupOpen(false)}
            />
          </div>

          <DescriptionSection
            title={
              event.type === 'Monthly' ||
              event.type === 'Chelsea Reed' ||
              event.type === 'Harmonizers Dance'
                ? 'Live Music, Drinks, and Dancing'
                : 'A Night of Dancing'
            }
            description={event.description}
          />

          <CarouselSection
            title='The Full Schedule'
            subText={getEventSubtext(event.type)}>
            <ol>
              {/* {event.type === 'Monthly' && (
                <>
                  <ScheduleStep time='7:30' description='1 hour dance lesson' />
                  <ScheduleStep time='8:30' description='Live music begins' />
                  <ScheduleStep time='9:30' description='Dance Performance' />
                  <ScheduleStep
                    time='10:00'
                    description='More dancing, drinking, and music'
                  />
                  <ScheduleStep time='11:30' last description='lights out' />
                </>
              )} */}

              {event.type === 'Monthly' && (
                <>
                  <ScheduleStep time='8:30' description='Live music begins' />
                  <ScheduleStep time='9:30' description='Dance Performance' />
                  <ScheduleStep
                    time='9:45'
                    description='More dancing, drinking, and music'
                  />
                  <ScheduleStep time='10:30' description='DJ Music Begins' />
                  <ScheduleStep time='11:30' last description='lights out' />
                </>
              )}

              {event.type === 'Harmonizers Dance' && (
                <>
                  <ScheduleStep time='6:30' description='Lesson' />
                  <ScheduleStep
                    time='7:00'
                    last
                    description='Live Music Begins'
                  />
                  <ScheduleStep
                    time='9:00'
                    description='Music continues with a DJ'
                  />
                  <ScheduleStep
                    time='10:30'
                    last
                    description='You go home, filled with happiness'
                  />
                </>
              )}

              {event.type === 'Weekly' && (
                <>
                  <ScheduleStep time='8:30' description='Open dancing' />
                  <ScheduleStep
                    time='10:30'
                    last
                    description='You go home, filled with happiness'
                  />
                </>
              )}
            </ol>
          </CarouselSection>

          <DetailsSection price={getEventPrice(event.type)} />
        </>
      )}
    </div>
  )
}

export default EventPage
