import React, { useEffect, useState } from 'react'
import { PageHeading } from 'app/layout/PageHeading'
import { GroupClassCard } from '../components/GroupClass/GroupClassCard'
import { useFirestore } from 'app/firebase'
import { take } from 'ramda'
import { useAnalytics } from 'app/shared/hooks/useAnalytics'
import { Loading } from 'app/shared/components/Loading/Loading'
import {
  addHours,
  addMonths,
  compareAsc,
  format,
  isAfter,
  isBefore,
  isFuture,
  startOfMonth,
} from 'date-fns'
import {
  fuzzySearch,
  Modal,
  Option,
  Search,
  Select,
  StickyHeader,
  useUI,
} from 'app/shared'
import { FaqModalContent } from '.'
import { DANCE_FORMS, GROUP_CLASS_LEVELS } from '../stlswing.constants'
import { FULL_MONTHS_IN_YEAR } from 'app/shared/utils/dates'
import { GroupClass } from '../stlswing.types'

type Props = {
  searchString?: string
}
export const GroupClassesPage = ({ searchString = '' }: Props) => {
  const { list: groupClasses } = useFirestore('groupClasses')
  const [searchTerm, setSearchTerm] = useState('')
  const [itemsOnPage, setItemsOnPage] = useState(1)
  const [faqOpen, setFaqOpen] = useState(false)
  const [filters, setFilters] = useState({
    level: searchString,
    // month: FULL_MONTHS_IN_YEAR[addMonths(new Date(), 1).getMonth()],
    month: '',
    danceForm: '',
  })

  const { pageViewEvent } = useAnalytics()
  const { isMobile } = useUI()

  useEffect(() => {
    pageViewEvent('Group Classes Page')

    if (window.innerHeight > 650) {
      setItemsOnPage((prev) => prev + 1)
    }

    // If it's a large screen, automatically add two more items
    if (window.innerHeight > 800) {
      setItemsOnPage((prev) => prev + 1)
    }

    const atBottom = () => {
      const height = window.innerHeight + window.scrollY
      const documentHeight = document.body.offsetHeight
      if (height >= documentHeight - 200) {
        setItemsOnPage((items) => items + 2)
      }
    }

    document.addEventListener('scroll', atBottom)

    return () => {
      document.removeEventListener('scroll', atBottom)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <section className='relative z-0 w-screen'>
        <PageHeading title='Group Classes' image='images/group-classes.jpg' />

        {/* Search */}
        {!isMobile && (
          <>
            <div className='flex flex-col items-center justify-center space-y-4 p-4 bg-gray-800'>
              <div className='sm:max-w-5xl flex w-full space-x-4 items-center'>
                <button
                  className='flex items-center justify-center w-1/6 h-12 font-semibold text-red-800 bg-red-200 rounded-sm'
                  onClick={() => setFaqOpen(true)}>
                  FAQ
                </button>

                <Search onSearch={setSearchTerm} />
              </div>
              <div className='flex w-full sm:max-w-5xl space-x-2 items-center justify-center'>
                <Select
                  name='month'
                  className='m-0 w-full'
                  label='Month'
                  noLabel
                  onSelect={(month) =>
                    setFilters((filters) => ({ ...filters, month }))
                  }
                  defaultValues={[filters.month]}>
                  {FULL_MONTHS_IN_YEAR.map((month) => (
                    <Option label={month} value={month} />
                  ))}
                </Select>

                <Select
                  name='level'
                  className='m-0 w-full'
                  label='Level'
                  noLabel
                  defaultValues={[filters.level]}
                  onSelect={(level) =>
                    setFilters((filters) => ({ ...filters, level }))
                  }>
                  {GROUP_CLASS_LEVELS.map((level) => (
                    <Option label={level} value={level} />
                  ))}
                </Select>

                <Select
                  name='type'
                  className='m-0 w-full'
                  label='Dance Form'
                  noLabel
                  onSelect={(danceForm) =>
                    setFilters((filters) => ({ ...filters, danceForm }))
                  }>
                  {DANCE_FORMS.map((month) => (
                    <Option label={month} value={month} />
                  ))}
                </Select>
              </div>
            </div>
          </>
        )}

        {isMobile && (
          <div className='flex w-screen h-16 p-4 overflow-x-auto overflow-y-hidden  border-b space-x-2 items-center bg-gray-100 sm:justify-center sm:hidden'>
            <button
              className='flex items-center justify-center p-4 m-0 h-full font-semibold text-red-800 bg-red-200 rounded-sm'
              onClick={() => setFaqOpen(true)}>
              FAQ
            </button>
            <Select
              name='month'
              className='m-0'
              label='Month'
              noLabel
              onSelect={(month) =>
                setFilters((filters) => ({ ...filters, month }))
              }
              defaultValues={[filters.month]}>
              {FULL_MONTHS_IN_YEAR.slice(new Date().getMonth() - 12).map(
                (month) => (
                  <Option label={month} value={month} />
                ),
              )}
            </Select>

            <Select
              name='level'
              className='m-0'
              label='Level'
              noLabel
              defaultValues={[filters.level]}
              onSelect={(level) =>
                setFilters((filters) => ({ ...filters, level }))
              }>
              {GROUP_CLASS_LEVELS.map((level) => (
                <Option label={level} value={level} />
              ))}
            </Select>

            <Select
              name='type'
              className='m-0'
              label='Dance Form'
              noLabel
              onSelect={(danceForm) =>
                setFilters((filters) => ({ ...filters, danceForm }))
              }>
              {DANCE_FORMS.map((month) => (
                <Option label={month} value={month} />
              ))}
            </Select>
          </div>
        )}

        <div className='md:grid md:grid-cols-2 sm:pb-32 sm:max-w-6xl sm:px-10 lg:px-12 grid-cols-1 px-2 mx-auto'>
          {groupClasses ? (
            take(
              itemsOnPage,
              fuzzySearch(
                groupClasses
                  .filter((groupClass) => !groupClass.draft)
                  .filter((groupClass) => {
                    // Class is 2 months away
                    const twoMonthsAway = addMonths(startOfMonth(new Date()), 3)
                    return isBefore(
                      new Date(groupClass.dates[0]),
                      twoMonthsAway,
                    )
                  })
                  .filter((groupClass) =>
                    // Class has been done for over 1 hour
                    isFuture(
                      addHours(
                        new Date(groupClass.dates[groupClass.dates.length - 1]),
                        1,
                      ),
                    ),
                  )
                  .filter((groupClass) => {
                    if (
                      filters.level &&
                      groupClass.level !== filters.level &&
                      filters.level !== 'All Levels'
                    )
                      return false
                    if (
                      filters.danceForm &&
                      groupClass.danceForm !== filters.danceForm
                    )
                      return false
                    if (
                      filters.month &&
                      format(new Date(groupClass.dates[0]), 'LLLL') !==
                        filters.month
                    )
                      return false
                    return true
                  })
                  .sort((a, b) =>
                    compareAsc(new Date(a.dates[0]), new Date(b.dates[0])),
                  )
                  // Show Beginner Classes First
                  .sort((a) => (a.level === 'Beginner' ? -1 : 1))
                  // Show Starting Swing First
                  .sort((a) => (a.name.includes('Starting Swing') ? -1 : 1)),

                searchTerm,
              ),
            ).map((groupClass) => (
              <div key={groupClass.id}>
                <GroupClassCard groupClass={groupClass} key={groupClass.id} />
              </div>
            ))
          ) : (
            <Loading color='purple' />
          )}
        </div>
        {groupClasses &&
          itemsOnPage <= groupClasses.length &&
          fuzzySearch(groupClasses, searchTerm).length > 0 && (
            <Loading color='purple' />
          )}
      </section>

      <Modal
        title='Group Class FAQ'
        isOpen={faqOpen}
        onClose={() => setFaqOpen(false)}
        className='w-11/12 sm:1/3 overflow-y-scroll'
        type='right-panel'>
        <FaqModalContent />
      </Modal>
    </>
  )
}

export default GroupClassesPage
