import React, { useContext, useEffect, useRef, useState } from 'react'
import { CurrentUserContext, usePermissions, User } from 'app/firebase'
import {
  createCheckoutSession,
  PaymentMetadata,
  PaymentType,
  ProductType,
} from 'app/firebase/functions/stripe.functions'
import { URLS } from 'app/stlswing/stlswing.constants'
import { Button } from '../Button'
import { Loading } from '../Loading/Loading'
import { ENV } from 'environments'

type Props = {
  stripePriceId: string
  internalProductId: string
  internalProductType: ProductType
  internalPaymentType: PaymentType
  quantity: number
  buttonClasses?: string
  credits: number
  isStudent?: boolean
}

export const getUrl = (productType: ProductType) => {
  if (productType === 'events') return URLS[ENV] + '/swing-dance-events'
  if (productType === 'groupClasses')
    return URLS[ENV] + '/swing-dance-classes/groupClasses'
  if (productType === 'privateLessons')
    return URLS[ENV] + '/swing-dance-classes/privateLessons'
  return null
}

const getUrls = (type: ProductType, id: string) => ({
  success: getUrl(type) + '/success/' + id,
  cancel: getUrl(type) + '/' + id,
  productPage: getUrl(type) + '/' + id,
})

const getMetaData = (
  props: Props,
  user: User & { id: string },
): PaymentMetadata => ({
  internalPaymentType: props.internalPaymentType,
  internalProductId: props.internalProductId,
  internalProductType: props.internalProductType,
  quantity: props.quantity,
  credits: props.credits,
  internalProductUrl: getUrls(
    props.internalProductType,
    props.internalProductId,
  ).productPage,
  date: new Date().toISOString(),
  internalUserId: user.id,
  stripePriceId: props.stripePriceId,
})

export const StripePaymentButton = (props: C<Props>) => {
  const [redirecting, setRedirecting] = useState(false)
  const { user } = useContext(CurrentUserContext)
  const { isLoggedIn } = usePermissions()
  const [stripeUrl, setStripeUrl] = useState<string>()
  const [anchorTagUrl, setAnchorTagUrl] = useState<string>()
  const linkButtonRef = useRef<HTMLAnchorElement>(null)
  const error_missing_id = 'You must create an account before signing up'
  const error_not_fucking_working =
    "If this doesn't work after a few moments, simply click the button again or wait a few more moments."
  const error_stripe =
    'There was an error with our payment processor.  please contact info@stlswing.dance for a solution.'

  const [error, setError] = useState<string>()

  // When stripe Url Changes, set the anchorTag url
  useEffect(() => {
    if (!stripeUrl) return
    if (anchorTagUrl !== stripeUrl) setAnchorTagUrl(stripeUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeUrl])

  // when the anchorTag is equal to the stripeUrl, click the anchor tag url
  useEffect(() => {
    if (!anchorTagUrl || !stripeUrl) return // if either of the urls have not been set, return
    if (anchorTagUrl !== stripeUrl) return // if the anchor tag has not been set to the returned stripe url, return
    linkButtonRef?.current?.click() // click the anchor tag

    // If the anchor tag hasn't started yet, use the window.location.replace function as a backup
    setTimeout(() => {
      window.location.replace(anchorTagUrl)
    }, 500)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorTagUrl])

  const onClick = async () => {
    // Set Errors
    if (!user || !isLoggedIn()) return setError(error_missing_id)

    // This is for analytics, but it's fucked
    // beginCheckoutEvent({
    //   category: getContentCategory(props),
    //   id: props.stripePriceId,
    //   name: getTitle(props),
    //   price: getPrice(props),
    // })

    const { success, cancel } = getUrls(
      props.internalProductType,
      props.internalProductId,
    )

    try {
      setRedirecting(true)
      const { url } = await createCheckoutSession({
        successUrl: success,
        cancelUrl: cancel,
        productId: props.stripePriceId,
        customerId: user.stripeId,
        customerEmail: user.email,
        isStudent:
          props.internalProductType === 'events' ||
          props.internalPaymentType === 'Membership' ||
          props.internalPaymentType === 'A La Carte'
            ? false
            : user.studentDiscount,
        metadata: getMetaData(props, user),
      })
      window.location.replace(url)
      setStripeUrl(url)

      setTimeout(() => {
        setError(error_not_fucking_working)
      }, 1000)
    } catch {
      setRedirecting(false)
      setError(error_stripe)
    }
  }

  return (
    <>
      <Button
        variant='raised'
        type='submit'
        className={props.className}
        onClick={onClick}>
        <div className='align-center flex justify-center align-middle'>
          {redirecting ? <p className={''}>...Redirecting</p> : props.children}
          <div className='flex justify-center ml-5'>
            {redirecting && <Loading className='h-7 w-7' />}
          </div>
        </div>
      </Button>

      {error && <p className='p-5 font-semibold text-red-400'>{error}</p>}

      {/* eslint-disable-next-line jsx-a11y/anchor-has-content  */}
      <a className='hidden' href={stripeUrl} ref={linkButtonRef} />
    </>
  )
}
