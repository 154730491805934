import React, { useState } from 'react'
import { pick } from 'lodash/fp'
import { InputError } from './InputError'
import { InputProps } from './types'

/**
 * Input Component That allows for easy, but complex validation
 *
 * @example
 * const exampleForm = () => {
 *  const form = useForm()
 *  const onSubmit = console.log
 *
 *  return (
 *    <form onSubmit={form.handleSubmit(onSubmit)}>
 *      // required field
 *      <Input form={form} name="Required Field" required />
 *      // number field with min/max validation
 *      <Input form={form} type="number" name="Number Field" min={3} max={5} />
 *      // text field with length validation
 *      <Input form={form} name="Text Field" minLength={3} maxLength={5} />
 *      // field with custom validation
 *      <Input form={form} name="Custom Validation"
 *        validate={{
 *           the key is the message shown if the current value of the field does not pass the predicate
 *          'Greater Than 3': currFieldValue => currFieldValue > 3,
 *          'Must Equal Test': currFieldValue => currFieldValue === 'test',
 *        }}
 *       />
 *    </form>
 *  )
 * }
 */
export const Input = ({
  form,
  label,
  name,
  type,
  autoFocus,
  autoComplete,
  ...props
}: InputProps) => {
  const { register, watch, errors } = form

  const validators = pick(
    ['min', 'max', 'minLength', 'maxLength', 'required', 'pattern', 'validate'],
    props,
  )

  return (
    <div className={`mt-6 ${props.className}`}>
      {!props.hideLabel && (
        <label
          className={`text-sm font-medium leading-5 text-gray-700 lock ${props.labelClasses}`}
          htmlFor={label}>
          <span className='pl-2 m-0 text-gray-500 text-md'>
            <span className='pr-1 text-red-400'>
              {validators.required && '*'}
            </span>
            {label}
          </span>
        </label>
      )}

      <div
        className={`rounded-lg group shadow-sm flex border border-gray-300 bg-white focus-within:shadow-outline-blue focus-within:border-blue-300 overflow-hidden ${props.inputClasses}`}>
        {props.prependElement && (
          <div className='border-r flex items-center px-2'>
            {props.prependElement}
          </div>
        )}
        <input
          onChange={({ target }) => {
            props.onChange && props.onChange(target.value)
          }}
          className={`block w-full px-3 py-2 placeholder-gray-400 transition duration-150 ease-in-out outline-none appearance-none sm:text-sm sm:leading-5 ${props.inputClasses}`}
          id={label}
          ref={register(validators)}
          type={type ? type : 'text'}
          name={name}
          value={
            props.transform
              ? props.transform(form.getValues(name))
              : form.getValues(name)
          }
          placeholder={props.placeholder || label}
          autoFocus={autoFocus}
          autoComplete={autoComplete ? 'on' : 'off'}
          defaultValue={props.defaultValue}
          disabled={props.disabled}
        />
        {props.appendElement && (
          <div className='border-l flex items-center px-2'>
            {props.appendElement}
          </div>
        )}
      </div>

      <InputError
        isSubmitted={form.formState.isSubmitted}
        fieldValue={watch(name)}
        error={errors[name]}
        validators={validators}
      />
    </div>
  )
}
