import React, { useContext, useState } from 'react'
import { CurrentUserContext, useFirestore, User } from 'app/firebase'
import { set } from 'lodash/fp'
import { DescriptiveRadioButton } from 'app/shared/components/Field/RadioButtons/DescriptiveRadioButton'
import { EXPERIENCE_LEVEL } from 'app/stlswing/stlswing.constants'
import { BlockRadioButton } from 'app/shared/components/Field/RadioButtons/BlockRadioButton'

interface Props {}
export const DanceExperienceSelect = (props: Props) => {
  const { user, setUser } = useContext(CurrentUserContext)
  const { update: updateUser } = useFirestore('users')

  const updateUserExperience = (experience: User['experience']) => {
    if (!user) return
    setUser(set('experience', experience))
    updateUser(user.id, { experience })
  }

  if (!user) return null
  return (
    <div className='w-full p-4'>
      <fieldset className='space-y-2'>
        <legend className='sr-only'>Choose Position</legend>
        <div className='grid grid-cols-2 sm:grid-cols-3 gap-3'>
          {EXPERIENCE_LEVEL.map((experience) => (
            <BlockRadioButton
              name={experience}
              checked={user.experience === experience}
              onClick={() => updateUserExperience(experience)}
            />
          ))}
        </div>
      </fieldset>
    </div>
  )
}
