import React from 'react'
import { getClassPriceID } from 'app/stlswing/helpers/lesson.helpers'
import { CheckIcon } from 'app/shared/components/Icons/CheckIcon'
import {
  PaymentType,
  paymentTypes,
} from 'app/firebase/functions/stripe.functions'
import {
  classIsCurrent,
  remainingClassDates,
} from 'app/stlswing/helpers/date.helpers'
import { GroupClass } from 'app/stlswing/components/GroupClass/groupClass.types'

type Props = {
  name: string
  description: string
  price: number
  priceId: string
  included: string[]
}

export const GroupClassPricingTier = (tier: C<Props>) => (
  <div
    key={tier.name}
    className='border border-gray-200 divide-y divide-gray-200 rounded-lg shadow-sm'>
    <div className='p-6'>
      <h2 className='text-lg font-medium leading-6 text-gray-900'>
        {tier.name}
      </h2>
      <p className='mt-4 text-sm text-gray-500'>{tier.description}</p>
      <p className='mt-8'>{tier.children}</p>
    </div>
    <div className='px-6 pt-6 pb-8'>
      <h3 className='text-xs font-medium tracking-wide text-gray-900 uppercase'>
        What's included
      </h3>
      <ul className='mt-6 space-y-4'>
        {tier.included.map((feature) => (
          <li key={feature} className='flex space-x-3'>
            <CheckIcon />
            <span className='text-sm text-gray-500'>{feature}</span>
          </li>
        ))}
      </ul>
    </div>
  </div>
)

const getTierInfo = (
  amount: number,
  description: string,
  included: string[],
  name: string,
  type: PaymentType,
) => ({
  priceId: getClassPriceID(amount),
  price: amount * 25,
  description,
  included,
  amount,
  name,
  paymentType: type,
})

export const getTiers = (groupClass: GroupClass) => {
  const amount = groupClass.dates.length

  const getIncluded = (amount: number) => [
    amount === 1 ? 'One Hour of Instruction' : `${amount} Classes`,
    amount === 1
      ? '1.5 Hour Social Event'
      : `${amount} Social Dance Opportunities`,
  ]

  const introDescription =
    'An Introduction to Swing.  The beginning of the end. You are going to love it so much that you will give up everything just to learn more.'

  const half_description =
    "You're into it, but you don't want to pay the full amount all at once.  Don't worry, you'll receive the option to continue if you like."
  const last_half_description =
    'You Bought the First Half, You Want the Second Half'

  const all_description = "You know you want this, you're ready to dive in"
  const remaining = remainingClassDates(groupClass)
  const rest_description =
    'You tried it out, you enjoyed it, you want finish the course'
  const single_description = 'Just One Night of Swing'

  // If Class is Currently In Session
  if (classIsCurrent(groupClass)) {
    return [
      getTierInfo(
        1,
        single_description,
        getIncluded(1),
        'A La Carte',
        'A La Carte',
      ),
      getTierInfo(
        Math.floor(amount / 2),
        last_half_description,
        getIncluded(amount / 2),
        'Second Half Of Class',
        'Second Half Of Class',
      ),
    ]
    // return [getTierInfo(remainingClassDates(groupClass), amount * 25, '', re)]
  }

  // If Class Has Only 1 Date
  if (groupClass.dates.length === 1)
    return [
      getTierInfo(
        1,
        introDescription,
        getIncluded(1),
        'One Night Of Swing',
        'Single Class',
      ),
    ]

  // If Class is not Currently In Session
  return [
    getTierInfo(
      amount,
      all_description,
      getIncluded(amount),
      'Full Class',
      'Full Class',
    ),
    getTierInfo(
      Math.floor(amount / 2),
      half_description,
      getIncluded(amount / 2),
      'Half Class',
      'First Half Of Class',
    ),
    // getTierInfo(
    //   1,
    //   single_description,
    //   getIncluded(1),
    //   'A La Carte',
    //   'A La Carte',
    // ),
  ]
}
