import { getFirestoreCollection } from 'app/firebase/firestore/firestore.helpers'
import {
  cancelReaderTransaction,
  processReaderPayment,
  startReaderTransaction,
} from 'app/firebase/functions/firebase.functions'
import {
  Button,
  DataTable,
  ExpansionPanel,
  Modal,
  useFirestore,
} from 'app/shared'
import { Step, Stepper } from 'app/shared/components/Stepper'
import { subDays } from 'date-fns'
import { flatten, prop, uniqBy, without } from 'ramda'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import {
  getCartTotal,
  getTotalTaxAmount,
} from '../components/Terminal/terminal.helpers'
import { CartItem } from '../components/Terminal/terminal.types'

export type Transaction = {
  description: string
  cartItems: CartItem[]
  total: number
  date: string
}

const READER_ID = 'tmr_EnI4Fwjh6qQ2WJ'

export const CardReader = () => {
  const [showStepper, setShowStepper] = useState(false)
  const [orders, setOrders] = useState<CartItem[]>([])

  const [transactions, setTransactions] = useState<Transaction[]>([])
  useEffect(() => {
    getFirestoreCollection('transactions', {
      where: [['date', '>', subDays(new Date(), 1).toISOString()]],
      // @ts-ignore
    }).then(setTransactions)
  }, [])
  const { createWithId } = useFirestore('transactions')

  console.log(transactions)

  const allOrders = flatten(
    transactions?.map((transaction) => transaction.cartItems) || [],
  )
  const alchoholOrders = allOrders.filter(
    (cartItem) => cartItem.type === 'alchohol',
  )
  const eventOrders = allOrders.filter((cartItem) => cartItem.type === 'events')

  const drinkTotals = (includes: string) => {
    const total = alchoholOrders.filter((order) =>
      order.description.includes(includes),
    )
    const orderAmount = total.length
    const totalRevenue = '$' + total.reduce((acc, curr) => acc + curr.amount, 0)
    return { orderAmount, totalRevenue }
  }

  const createAlchoholOrder = (cartItems: CartItem[]) => {
    createWithId({
      cartItems: cartItems,
      description: 'alchohol',
      total: getCartTotal(orders),
      date: new Date().toISOString(),
    })
  }

  const getOrganizedOrders = (cartItems: CartItem[]) => {
    return uniqBy(
      prop('name'),
      cartItems.map((order, i) => {
        const quantity = orders.filter(
          (o) => o.description === order.description,
        ).length

        return {
          name: order.description,
          total: order.amount * quantity,
          item: order.type,
          quantity: quantity,
          cartItem: order,
        }
      }),
    )
  }

  return (
    <div className='min-h-screen w-full flex flex-col items-center justify-between bg-white'>
      <div className='h-16 w-full bg-gray-800' />
      <div className='p-10 flex-shrink'>
        <Button
          onClick={() => {
            setShowStepper(true)
          }}>
          Start Bar Transaction
        </Button>
        <Button
          variant='warn'
          className='m-4'
          onClick={() => {
            setShowStepper(false)
            cancelReaderTransaction({
              readerId: READER_ID,
            })
            toast.warn('This might take a moment to reflect on the screen')
          }}>
          CANCEL CURRENT
        </Button>
      </div>

      <div className='h-3/4 flex-grow '>
        <ExpansionPanel title='Statistics'>
          <div className='p-2 grid grid-cols-3 divide-y gap-2'>
            <p className=' text-gray-900 font-medium'>description</p>
            <p className=' text-gray-900 font-medium'>Total Sold</p>
            <p className=' text-gray-900 font-medium'>Total Revenue</p>

            <p>Total Tickets:</p>
            <p>{eventOrders.length}</p>
            <p>${eventOrders.reduce((acc, curr) => acc + curr.amount, 0)}</p>

            <p>Total Alchohol:</p>
            <p>{alchoholOrders.length}</p>
            <p>{alchoholOrders.reduce((a, c) => a + c.amount, 0)}</p>

            <p>Total Open Bar:</p>
            <p>{drinkTotals('Open Bar').orderAmount}</p>
            <p>{drinkTotals('Open Bar').totalRevenue}</p>

            <p>Total $15 Open Bar:</p>
            <p>{drinkTotals('Member').orderAmount}</p>
            <p>{drinkTotals('Member').totalRevenue}</p>

            <p>Total $10 Drinks</p>
            <p>{drinkTotals('$10').orderAmount}</p>
            <p>{drinkTotals('$10').totalRevenue}</p>

            <p>Total $8 Drinks</p>
            <p>{drinkTotals('$8').orderAmount}</p>
            <p>{drinkTotals('$8').totalRevenue}</p>

            <p>Total $5 Drinks</p>
            <p>{drinkTotals('$5').orderAmount}</p>
            <p>{drinkTotals('$5').totalRevenue}</p>

            <p>Total $2 Drinks</p>
            <p>{drinkTotals('$2').orderAmount}</p>
            <p>{drinkTotals('$2').totalRevenue}</p>
          </div>
        </ExpansionPanel>
        <DataTable
          recordsList={allOrders}
          defaultSort={'description'}
          hideColumnFilter
          tableSchema={{
            Description: (item) => item.description,
            Type: (item) => item.type,
            Total: (item) => '$' + item.amount,
          }}
        />
      </div>

      <Modal
        className='h-screen w-screen'
        disallowRouting
        isOpen={showStepper}
        onClose={() => setShowStepper(false)}>
        <Stepper fullPage>
          <Step
            title='Select Drinks'
            onPrevious={() => {
              cancelReaderTransaction({
                readerId: READER_ID,
              })
              toast.warn('This might take a moment to reflect on the screen')
            }}
            onNext={() => {
              startReaderTransaction({
                readerId: READER_ID,
                total: getCartTotal(orders) * 100,
                tax: getTotalTaxAmount(orders) * 100,
                cartItems: getOrganizedOrders(orders).map((item) => ({
                  amount: item.total * 100,
                  description: item.name,
                  quantity: item.quantity,
                })),
              })
            }}>
            <div className='w-full border-b'>
              <ExpansionPanel
                title={`Current Total - $${getCartTotal(orders)}`}>
                <div className='grid grid-cols-4 p-2 gap-4'>
                  <p className='font-semibold'>Name</p>
                  <p className='font-semibold'>Quantity</p>
                  <p className='font-semibold'>Total</p>
                  <p className='font-semibold'>Remove</p>
                  {getOrganizedOrders(orders).map((item) => (
                    <>
                      <p>{item.name}</p>
                      <p>{item.quantity}</p>
                      <p>${item.total}</p>

                      <button
                        className='border bg-red-800 flex justify-center items-center'
                        onClick={() => setOrders(without([item.cartItem]))}>
                        {/* <FontAwesomeIcon
                          className='text-red-200'
                          icon={faMinusCircle}
                        /> */}
                        <p className='text-white font-medium uppercase'>
                          remove
                        </p>
                      </button>
                    </>
                  ))}

                  <p>Tax</p>
                  <p>1</p>
                  <p>${getTotalTaxAmount(orders).toFixed(2)}</p>
                </div>

                <div className='flex'></div>
              </ExpansionPanel>
            </div>
            <h2 className='font-3xl p-2 font-bold'>Drinks</h2>
            <div className='p-2 grid grid-cols-2 gap-5'>
              {drinks.map((item) => (
                <CartItemButton
                  item={item}
                  onClick={(item) => setOrders((prev) => [...prev, item])}
                />
              ))}
            </div>

            <h2 className='font-3xl p-2 font-bold'>Events</h2>
            <div className='p-2 grid grid-cols-2 gap-5'>
              {events.map((item) => (
                <CartItemButton
                  item={item}
                  onClick={(item) => setOrders((prev) => [...prev, item])}
                />
              ))}
            </div>

            <h2>Controls</h2>
            <Button
              variant='warn'
              className='m-4'
              onClick={() => {
                toast.warn('This might take a moment to reflect on the screen')
                setShowStepper(false)
                cancelReaderTransaction({
                  readerId: READER_ID,
                })
              }}>
              STOP CURRENT TRANSACTION
            </Button>
          </Step>
          <Step
            title='Complete'
            onNext={() => {
              processReaderPayment({
                readerId: READER_ID,
                amount: getCartTotal(orders),
                description: 'Total',
              })
            }}
            onPrevious={() => {
              cancelReaderTransaction({
                readerId: READER_ID,
              })
            }}>
            <div className='w-full p-4 h-full flex flex-col justify-center items-center'>
              <h1 className='text-3xl text-center text-bold'>
                Wait until the person has swiped their card, then hit next.
              </h1>
            </div>
          </Step>

          <Step
            title='Complete'
            onNext={() => {
              setOrders((prevOrders) => {
                createAlchoholOrder(prevOrders)
                return []
              })
              setShowStepper(false)
            }}
            onPrevious={() => {
              cancelReaderTransaction({
                readerId: READER_ID,
              })
            }}>
            <div className='w-full p-4 h-full flex flex-col justify-center items-center'>
              <h1 className='text-3xl text-center text-bold'>
                Wait until the person has swiped their card, then hit next.
              </h1>
            </div>
          </Step>
        </Stepper>
      </Modal>
    </div>
  )
}

type CartItemProps = {
  item: CartItem
  onClick: (cartItem: CartItem) => void
}
const CartItemButton = ({ item, onClick }: CartItemProps) => (
  <Button onClick={() => onClick(item)}>{item.description}</Button>
)

const events: CartItem[] = [
  {
    amount: 10,
    description: 'Weekly Social',
    type: 'events',
  },
  {
    amount: 20,
    description: 'Monthly Social',
    type: 'events',
  },
  {
    amount: 25,
    description: 'Group Class',
    type: 'groupClasses',
  },
  {
    amount: 100,
    description: 'Full Group Class',
    type: 'groupClasses',
  },
]

const drinks: CartItem[] = [
  {
    amount: 5,
    description: '$5 Drink',
    type: 'alchohol',
  },
  {
    amount: 8,
    description: '$8 Drink',
    type: 'alchohol',
  },
  {
    amount: 10,
    description: '$10 Drink',
    type: 'alchohol',
  },
  {
    amount: 2,
    description: '$2 Drink',
    type: 'alchohol',
  },
  {
    amount: 20,
    description: '$20 Open Bar',
    type: 'alchohol',
  },
  {
    amount: 15,
    description: '$15 Open Bar(Member)',
    type: 'alchohol',
  },
]
