import React from 'react'

export const H1 = ({ className, children }: C<{}>) => (
  <h1
    className={
      'text-5xl font-semibold flex items-center justify-center flex-grow' +
      ' ' +
      className
    }>
    {children}
  </h1>
)

export const H2 = ({ className, children }: C<{}>) => (
  <h1
    className={
      'flex items-center justify-center flex-grow text-4xl font-bold' +
      ' ' +
      className
    }>
    {children}
  </h1>
)

export const H3 = ({ className, children }: C<{}>) => (
  <h1
    className={
      'flex items-center justify-center flex-grow text-xl font-semibold align-middle' +
      ' ' +
      className
    }>
    {children}
  </h1>
)
