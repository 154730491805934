import React from 'react'
type Props = {
  title: string
  columns?: string
  bgClass?: string
}
export const InfoSection = ({
  children,
  title,
  columns = '3',
  className,
  bgClass,
}: C<Props>) => {
  return (
    <div
      className={`py-4 sm:py-5 sm:grid sm:grid-cols-${columns} sm:gap-4 sm:px-6 ${
        bgClass || ''
      }`}>
      <dt className={'text-sm font-medium text-gray-500 ' + className}>
        {title}
      </dt>
      <dd className='mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2'>
        {children}
      </dd>
    </div>
  )
}
