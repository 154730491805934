import { faStop } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { DataTable, DataTableProps } from '.'
import { Modal } from '..'

interface Props<T extends Record<any, any>> extends DataTableProps<T> {
  isOpen: boolean
  onClose: () => void
}

export function DataTableModal<T extends object>({
  isOpen,
  onClose,
  ...props
}: Props<T>) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      hideTitle
      className='h-screen w-screen overflow-hidden'>
      <DataTable
        {...props}
        title={
          <div className='w-full flex justify-between'>
            <h1>{props.title}</h1>
            <button>
              <FontAwesomeIcon icon={faStop} />
            </button>
          </div>
        }
      />
    </Modal>
  )
}
