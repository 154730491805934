import React, { useEffect, useState } from 'react'
import { PageHeading } from 'app/layout/PageHeading'
import { useFirestore, useRouter } from 'app/shared/hooks'
import { GroupClass } from 'app/stlswing/stlswing.types'
import { PRICES } from 'app/stlswing/stlswing.constants'
import { useAnalytics } from 'app/shared/hooks/useAnalytics'
import { getCategoryFromLevel } from 'app/stlswing/helpers/lesson.helpers'
import { GroupClassPageHeading } from '../components/GroupClass/GroupClassPageHeading'
import { DescriptionSection } from '../components/Shared/DescriptionSection'
import { CarouselSection } from '../components/Shared/CarouselSection'
import { DetailsSection } from '../components/Shared/DetailsSection'
import { LoadingPage } from 'app/shared/components/Loading/LoadingPage'

export const GroupClassPage = () => {
  const { routeParams } = useRouter<{ id: string }>()
  const { getSingle: getGroupClass } = useFirestore('groupClasses')
  const [groupClass, setGroupClass] = useState<GroupClass<true>>()

  const { viewContentEvent } = useAnalytics()

  // Set Analytics
  useEffect(() => {
    if (!groupClass) return
    const contentCategory = getCategoryFromLevel(groupClass.level)
    viewContentEvent(groupClass.name, contentCategory, [groupClass.id])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupClass])

  // Set Group Class State
  useEffect(() => {
    if (!routeParams.id) return
    // @ts-ignore
    getGroupClass(routeParams.id).then(setGroupClass)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeParams.id])

  // Show Loading State While Waiting for Class to Load
  if (!groupClass) return <LoadingPage />

  // Actual Component
  return (
    <div className='relative z-0'>
      <PageHeading
        title={groupClass.name}
        video={`videos/${groupClass.video}`}
      />

      <GroupClassPageHeading groupClass={groupClass} />

      <DescriptionSection description={groupClass.description} />

      <CarouselSection
        title='These images are only here to convince you to buy a class'
        subText='Swing dancing has been proven, without a shadow of a doubt, to cure cancer.'
      />

      <DetailsSection
        price={groupClass.dates.length * PRICES.PER_GROUP_CLASS.NORMAL}
        danceForm={groupClass.danceForm}
        level={groupClass.level}
        dates={groupClass.dates}
      />
    </div>
  )
}

export default GroupClassPage
