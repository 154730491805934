import React from 'react'
import { User } from 'app/firebase'
import { Select, Option, FiltersModal } from 'app/shared'
import { rejectNil } from 'app/shared/utils/arrays'
import { EXPERIENCE_LEVEL } from 'app/stlswing/stlswing.constants'
import { AttendeeTableFilters } from '../../helpers/attendeesTable.helpers'
import { set, lensProp } from 'ramda'

interface Props {
  attendeesList: User<true>[]
  filters: AttendeeTableFilters
  setFilters: SetState<AttendeeTableFilters>
}

export const AttendeesTableFilters = ({
  attendeesList,
  filters,
  setFilters,
}: Props) => {
  return (
    <FiltersModal>
      <div className='p-2 sm:w-96'>
        <div className='flex justify-evenly'>
          <p className='font-semibold'>Current Total</p>
          <p>{attendeesList.length} Attendees</p>
        </div>
        <Select
          defaultValues={rejectNil(filters.position || [])}
          name=''
          onSelect={(selections) => {
            setFilters(
              set(
                lensProp('position'),
                // @ts-ignore select type is broken for multiple
                selections,
              ),
            )
          }}
          multiple
          placeholder='lead, follow, or switch'
          label='Position'>
          <Option label='Leaders' value='lead' />
          <Option label='Followers' value='follow' />
          <Option label='Switch' value='switch' />
        </Select>
        <Select<NonNullable<User['experience']>>
          name=''
          placeholder='months / years spent dancing'
          defaultValues={rejectNil(filters.experience || [])}
          onSelect={(selections) => {
            // @ts-ignore select type is broken for multiple
            setFilters(set(lensProp('experience'), selections))
          }}
          multiple
          label='Experience'>
          {EXPERIENCE_LEVEL.map((exp) => (
            <Option label={exp} value={exp} />
          ))}
        </Select>
        <Select
          name=''
          placeholder='member, student discounted, or normal'
          defaultValues={rejectNil(filters.userType || [])}
          onSelect={(selections) => {
            // @ts-ignore select type is broken for multiple
            setFilters(set(lensProp('userType'), selections))
          }}
          multiple
          label='User Type'>
          <Option label='Members' value='member' />
          <Option label='Discounted' value='discounted' />
          <Option label='Normal' value='normal' />
        </Select>
        <Select
          name=''
          placeholder='full class, half class, etc.'
          defaultValues={rejectNil(filters.orderType || [])}
          onSelect={(selections) => {
            // @ts-ignore select type is broken for multiple
            setFilters(set(lensProp('orderType'), selections))
          }}
          multiple
          label='Order Type'>
          <Option label='Full Class' value='Full Class' />
          {/* prettier-ignore */}
          <Option label='First Half Of Class' value='First Half Of Class' />
          {/* prettier-ignore */}
          <Option label='Second Half Of Class' value='Second Half Of Class' />
          <Option label='A La Carte' value='A La Carte' />
        </Select>
      </div>
    </FiltersModal>
  )
}
