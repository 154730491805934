import React, { useContext } from 'react'
import { SignInModal } from 'app/auth'
import { CurrentUserContext, usePermissions } from 'app/firebase'
import { Button, AvatarHeading, Loading } from 'app/shared/components'
import { StripePortalButton } from 'app/shared/components/Stripe/StripePortalButton'
import { useToggle } from 'app/shared/hooks'
import { HeaderDropDown } from '../components/HeaderDropDown'

type Props = {
  openClasses?: () => void
  openEvents?: () => void
}
export const ProfileMenu = (props: Props) => {
  const {
    state: menuOpen,
    toggle: toggleMenu,
    setFalse: closeMenu,
  } = useToggle()
  const {
    state: signInOpen,
    toggle: toggleSignIn,
    setFalse: closeSignIn,
  } = useToggle()
  const { user, logout, userLoading } = useContext(CurrentUserContext)

  if (userLoading) return <Loading />

  return (
    <HeaderDropDown
      close={closeMenu}
      isOpen={menuOpen}
      toggle={toggleMenu}
      text='My Profile'>
      <div className='sm:py-6 sm:gap-8 sm:p-8 relative grid items-start gap-2 px-5 py-3 pt-3 bg-white'>
        <button
          className='sm:p-3 sm:-m-3 sm:w-56 hover:bg-gray-100 block transition duration-150 ease-in-out rounded-md'
          onClick={() => {
            if (!props.openClasses) return
            props.openClasses()
            // navigateTo('/profile/Classes')
            closeMenu()
          }}>
          <p className='font-medium text-left text-gray-900'>My Classes</p>
          <p className='mt-1 text-sm text-left text-gray-500'>
            View Information About Your Past and Current Classes
          </p>
        </button>
        <button
          className='sm:p-3 sm:-m-3 sm:w-56 hover:bg-gray-100 block transition duration-150 ease-in-out rounded-md'
          onClick={() => {
            if (!props.openEvents) return
            // navigateTo('/profile/events')
            props.openEvents()
            closeMenu()
          }}>
          <p className='font-medium text-left text-gray-900'>My Events</p>
          <p className='mt-1 text-sm text-left text-gray-500'>
            View Information About Your Past and Current Events
          </p>
        </button>

        {user && (
          <AvatarHeading
            image={user.photoUrl}
            subText={user.email}
            title={user.displayName}
          />
        )}

        {user && user.member && (
          <StripePortalButton className='sm:p-3 sm:-m-3 sm:w-56 hover:bg-gray-100 block transition duration-150 ease-in-out rounded-md'>
            Edit Payment Information
          </StripePortalButton>
        )}

        <Button variant='raised' onClick={logout}>
          Log Out
        </Button>
      </div>
    </HeaderDropDown>
  )
}
