import React from 'react'
import { StripePaymentButton } from 'app/shared/components/Stripe/StripePaymentButton'
import { getClassPriceID } from 'app/stlswing/helpers/lesson.helpers'
import { GroupClass } from 'app/stlswing/stlswing.types'
import { PaymentType } from 'app/firebase/functions/stripe.functions'

type Props = {
  groupClass: GroupClass<true>
  amountOfClasses: number
  price: number
  discount: boolean
  paymentType: PaymentType
}
export const GroupClassPaymentButton = ({
  groupClass,
  discount,
  amountOfClasses,
  price,
  paymentType,
}: Props) => {
  return (
    <StripePaymentButton
      stripePriceId={getClassPriceID(amountOfClasses)}
      credits={amountOfClasses}
      buttonClasses={
        'block w-full py-2 mt-8 text-3xl font-extrabold text-center text-white bg-gray-800 border border-gray-800 rounded-md hover:bg-gray-900'
      }
      internalProductId={groupClass.id}
      internalProductType='groupClasses'
      internalPaymentType={paymentType}
      quantity={1}
      className='hover:bg-gray-900 block w-full py-2 mt-8 text-3xl font-extrabold text-center text-white bg-gray-800 border border-gray-800 rounded-md'>
      <span className={`${discount && 'line-through pr-2 text-red-600'}`}>
        ${price} {paymentType === 'Membership' ? ' / Mo' : ''}
      </span>
      {discount && <span>${price / 2}</span>}
    </StripePaymentButton>
  )
}
