import React, { useMemo } from 'react'
import {
  Button,
  ActionsMenu,
  ConfirmationButton,
  User,
  useFirestore,
} from 'app/shared'
import { Order } from 'app/stlswing/components/Accounting/finance.types'

interface ActionColumnProps {
  setOrderToEdit: SetState<Order | undefined>
  setUserToEdit: SetState<User<true> | undefined>
  setUserOrderHistory: SetState<Order[] | undefined>
  setEmailRecievers: SetState<User<true>[] | undefined>
  user: User<true>
  userOrders: Order[]
}

export const AttendeeActionsColumn = ({
  user,
  userOrders,
  ...props
}: ActionColumnProps) => {
  const { list: orders, remove: removeOrder } = useFirestore('orders')

  const allUserOrders = useMemo(() => {
    if (!orders) return []
    return orders.filter((order) => order.internalUserId === user.id)
  }, [user, orders])

  const userOrder = useMemo(
    () => allUserOrders.find((order) => order.internalUserId === user.id),
    [allUserOrders],
  )

  return (
    <ActionsMenu>
      <div className='w-screen sm:w-52 grid grid-cols-2 sm:grid-cols-1 gap-2 p-2 mb-20'>
        <Button onClick={() => props.setUserToEdit(user)} className='py-4'>
          Edit User Info
        </Button>
        <Button
          onClick={() => props.setOrderToEdit(userOrder)}
          className='py-4'>
          Edit User Order
        </Button>
        <Button
          onClick={() => props.setUserOrderHistory(userOrders)}
          className='py-4'>
          View Previous Orders
        </Button>
        <Button
          onClick={() => props.setEmailRecievers([user])}
          className='py-4'>
          Send Email To Student
        </Button>
        <ConfirmationButton
          onClick={() => {
            if (!userOrder?.id) return
            removeOrder(userOrder.id)
          }}>
          Remove From Class
        </ConfirmationButton>
      </div>
    </ActionsMenu>
  )
}

export const getActionColumn = (props: ActionColumnProps) => (
  <AttendeeActionsColumn {...props} />
)
