import React from 'react'
import { GroupClass } from 'app/stlswing/components/GroupClass/groupClass.types'
import { InfoSection } from 'app/stlswing/components/Shared/InfoSection'
import { classIsCurrent, formatDate } from 'app/stlswing/helpers/date.helpers'
import { classSpecificQuestions, FaqList } from 'app/stlswing/pages'
import { compareAsc } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
interface Props {
  groupClass: GroupClass
}

export const OverviewStep = ({ groupClass }: C<Props>) => {
  return (
    <div className='w-full flex flex-col justify-center items-stretch'>
      {classIsCurrent(groupClass) && (
        <div className='flex flex-col m-4 shadow-xl border-yellow-600 border-2 rounded-lg items-center justify-center'>
          <div className='flex items-center w-full justify-center'>
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className='text-yellow-600 h-14 w-14'
            />
            <p className='text-yellow-600 font-bold font-2xl'>WARNING</p>
          </div>
          <p className='text-lg font-extrabold text-gray-600'>
            This class is currently in Session!
          </p>
          <p className='p-2'>
            If you haven't taken the previous classes or are not confident in
            your ability to keep up with the material, we suggest waiting until
            our next class series starts at the beginning of next month.
          </p>
        </div>
      )}

      <p className='text-3xl font-bold mx-auto pt-2'>General</p>
      <div className='divide-y p-2'>
        <p className='pb-2'>
          This is a series of {groupClass.dates.length} classes. Each Class is
          progressive and will build on the knowledge of the previous classes
          taken. If you aren't certain you want to take the entire month of
          classes, there is an option to take only half the class to test the
          waters.
        </p>

        <InfoSection title='Partner' className='w-full'>
          No Partner Required!
        </InfoSection>

        <InfoSection title='All Dates'>
          <div>
            {groupClass.dates
              .map((d) => new Date(d))
              .sort(compareAsc)
              .map((date, i) => (
                <p key={i}>{formatDate('readable-date-and-time', date)}</p>
              ))}
          </div>
        </InfoSection>

        <InfoSection title='Level'>{groupClass.level}</InfoSection>
      </div>

      <p className='text-3xl text-center font-bold mx-auto pt-2'>
        Frequently Asked Questions
      </p>

      <div className='p-4'>
        <FaqList faqs={classSpecificQuestions} />
      </div>
    </div>
  )
}
