import React from 'react'
import { Modal } from 'app/shared/components'
import { QRCODES } from 'app/stlswing/stlswing.constants'
import { Ticket } from './Ticket'
import { Event, GroupClass } from 'app/stlswing/stlswing.types'

type Props = {
  name: string
  isOpen: boolean
  product: GroupClass<true> | Event<true>
  qrcode: keyof typeof QRCODES
  close: () => void
}

export const TicketModal = (props: Props) => {
  return (
    <Modal
      title='Your Ticket'
      isOpen={props.isOpen}
      type='right-panel'
      onClose={props.close}>
      <Ticket
        qrCode={props.qrcode}
        productName={props.name}
        product={props.product}
      />
    </Modal>
  )
}
