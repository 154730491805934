import React, { useContext, useState } from 'react'

import { Modal } from 'app/shared/components/Modal/components/Modal'
import {
  CurrentUserContext,
  usePermissions,
  useRouter,
  useToggle,
} from 'app/shared/hooks'
import { RouteNames } from 'app/Routes'
import { Button, Loading } from 'app/shared/components'
import { SignInModal } from 'app/auth'
import { AdminMenu } from './header/menus/AdminMenu'
import { MyClasses } from 'app/stlswing/components/GroupClass/MyClasses'
import { MyEvents } from 'app/stlswing/components/Events/MyEvents'
import { ViewAsMenu } from './header/menus/ViewAsMenu'
import { StripePortalButton } from 'app/shared/components/Stripe/StripePortalButton'
import { OpenSidebarButton } from './header'
import { Breadcrumbs } from 'app/shared/components/Layout/Breadcrumbs'
import { UIContext } from 'app/shared/context/UIContext'

const moreLinks: { name: string; route: RouteNames }[] = [
  { name: 'Resources', route: '/resources' },
  { name: 'About', route: '/about' },
  // { name: 'FAQ', route: '/faq' },
]
const links: { name: string; route: RouteNames }[] = [
  { name: 'Home', route: '/' },
  { name: 'Group Classes', route: '/swing-dance-classes/groupClasses' },
  { name: 'Dances and Events', route: '/swing-dance-events' },
  { name: 'Calendar', route: '/calendar' },
  ...moreLinks,
]

export const MobileHeader = () => {
  // prettier-ignore
  const { state: signInVisible, setFalse: closeSignin, setTrue: openSignin } = useToggle(false)
  // prettier-ignore
  const { state: classesOpen, setFalse: closeClasses, setTrue: openClasses } = useToggle(false)
  // prettier-ignore
  const { state: eventsOpen, setFalse: closeEvents, setTrue: openEvents } = useToggle(false)

  const { showHeader } = useContext(UIContext)
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)

  const { user, logout, userLoading } = useContext(CurrentUserContext)
  const { isLoggedIn, hasAnyRole, hasClearance } = usePermissions()
  const { navigateTo } = useRouter()

  const buttonClasses =
    'block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white'

  return (
    <div className='relative md:hidden w-screen'>
      {showHeader && (
        <div className='absolute z-30 top-0 left-0 h-16 flex justify-between w-screen items-center'>
          {/* <h1 className='text-white font-medium text-xl ml-2'>STL Swing</h1> */}
          <Breadcrumbs
            titles={['Home', 'Group Classes']}
            onClickTitle={() => console.log('')}
          />
          <OpenSidebarButton
            toggleSidebar={() => setIsSidebarOpen((v) => !v)}
          />
        </div>
      )}

      <Modal
        className='!text-white !bg-gray-800 !w-screen'
        isOpen={isSidebarOpen}
        title='Navigation'
        onClose={() => setIsSidebarOpen(false)}
        type='left-panel'>
        <div className='max-w-screen max-w-7xl sm:px-6 lg:px-8 w-screen px-4 mx-auto'>
          {/* Admin Shit */}
          {hasClearance(3) && <ViewAsMenu />}
          {hasAnyRole(['admin', 'employee']) && (
            <button
              className={
                'border bg-gray-50 text-gray-800 ml-4 px-4 py-1 rounded-full font-medium'
              }
              onClick={() => navigateTo('/admin/products')}>
              Go To Admin Page
            </button>
          )}

          <div className='sm:hidden' id='mobile-menu'>
            <div className='px-2 pt-2 pb-3 space-y-1'>
              {links.map(({ name, route }) => (
                <button
                  onClick={() => {
                    navigateTo(route)
                    setIsSidebarOpen(false)
                  }}
                  key={name}
                  className={buttonClasses}>
                  {name}
                </button>
              ))}
            </div>

            {userLoading && <Loading className='' />}

            {!isLoggedIn() && !userLoading && (
              <>
                <div className='px-4 pt-4 pb-3 border-t border-gray-700'>
                  <Button variant='raised' onClick={openSignin}>
                    Login
                  </Button>
                </div>
                <SignInModal
                  title='Log In'
                  isOpen={signInVisible}
                  onClose={closeSignin}
                />
              </>
            )}

            {/* User Shit */}
            {isLoggedIn() && user && (
              <div className='pt-4 pb-3 border-t border-gray-700'>
                <div className='flex items-center px-5'>
                  <div className='flex-shrink-0'>
                    {user.photoUrl && (
                      <img
                        className='w-10 h-10 rounded-full'
                        src={user.photoUrl}
                        alt=''
                      />
                    )}
                  </div>
                  <div className='ml-3'>
                    <div className='text-base font-medium text-white'>
                      {user.displayName}
                    </div>
                    <div className='text-sm font-medium text-gray-400'>
                      {user.email}
                    </div>
                  </div>
                </div>

                <Modal
                  title='My Classes'
                  type='left-panel'
                  isOpen={classesOpen}
                  className='w-screen text-gray-800 bg-white overflow-y-auto'
                  onClose={closeClasses}>
                  <MyClasses onClose={closeClasses} />
                </Modal>
                <Modal
                  title='My Events'
                  type='left-panel'
                  isOpen={eventsOpen}
                  className='w-screen text-gray-800 bg-white overflow-y-auto'
                  onClose={closeEvents}>
                  <MyEvents onClose={closeEvents} />
                </Modal>

                <div className='px-2 mt-3 space-y-1'>
                  <button className={buttonClasses} onClick={openClasses}>
                    My Classes
                  </button>
                  <button className={buttonClasses} onClick={openEvents}>
                    My Events
                  </button>

                  {hasAnyRole(['admin', 'employee']) && (
                    <button
                      className={buttonClasses}
                      onClick={() => navigateTo('/card-reader')}>
                      Card Reader
                    </button>
                  )}
                  {user.member && (
                    <StripePortalButton className={buttonClasses}>
                      Edit / View Payment Information
                    </StripePortalButton>
                  )}

                  <Button variant='raised' className='ml-2' onClick={logout}>
                    Sign out
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  )
}
