import { toast } from 'react-toastify'
import {
  getStorage,
  getDownloadURL,
  listAll,
  ref,
  uploadBytes,
} from 'firebase/storage'
import { firebaseApp } from 'environments'

// type Folders = 'images' | 'videos' | 'cost'
// type Media = {
//   name: string
//   path: string
//   url: string
// }
// export const useUploadMedia = (
//   file: File,
//   onUpload?: (url: string) => void,
// ) => {
//   const [progress, setProgress] = useState(0)
//   const [error, setError] = useState<firebase.storage.FirebaseStorageError>()
//   const [media, setMedia] = useState<Media>()

//   return { progress, media, error }
// }
// type Options = {
//   percentageUpdate: (percentage: number) => void
//   error: (error: any) => void
//   onComplete: (ref: any) => void
// }
export const upload = async (file: File /** options: Options */) => {
  const Storage = getStorage(firebaseApp)
  const storageRef = ref(Storage, file.name)

  return uploadBytes(storageRef, file)
}

export const getImageSrcList = async (paths: string[]) =>
  await Promise.all(paths.map(getImageSrc))

export const getImageSrc = async (path: string) =>
  await getDownloadURL(ref(getStorage(firebaseApp), path))

export const getPhotoAlbumPaths = async (name: string) =>
  await Promise.all(
    await listAll(ref(getStorage(firebaseApp), 'albums/' + name)).then((ref) =>
      ref.items.map((i) => i.fullPath),
    ),
  )
export const getPhotoAlbum = async (name: string) =>
  await Promise.all(
    await listAll(ref(getStorage(firebaseApp), 'albums/' + name)).then(
      (ref) => ref.items,
    ),
  )

export const getSavedFiles = async () =>
  await listAll(ref(getStorage(firebaseApp)))
    .then((ref) =>
      ref.items.map((ref) => ({
        fullPath: ref.fullPath,
        name: ref.name,
        src: getDownloadURL(ref),
      })),
    )
    .catch((err) => toast(err, { type: 'error' }))
