import { CurrentUserContext, usePermissions } from 'app/firebase'
import { createPortalSession } from 'app/firebase/functions/stripe.functions'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Loading } from '../Loading/Loading'

type Props = {}

export const StripePortalButton = (props: C<Props>) => {
  const [redirecting, setRedirecting] = useState(false)
  const { user } = useContext(CurrentUserContext)
  const { isLoggedIn } = usePermissions()
  const [stripeUrl, setStripeUrl] = useState<string>()
  const [anchorTagUrl, setAnchorTagUrl] = useState<string>()
  const linkButtonRef = useRef<HTMLAnchorElement>(null)
  const error_missing_id = 'You must have an account to view the portal'
  const error_not_fucking_working =
    "If this doesn't work after a few moments, simply click the button again. This is Cameron, our developers fault. He also teaches classes sometimes, and if you see him at social dances, make sure to shame him for it."
  const error_stripe =
    'There was an error with our payment processor.  please contact info@stlswing.dance for a solution.'

  const [error, setError] = useState<string>()

  // When stripe Url Changes, set the anchorTag url
  useEffect(() => {
    if (!stripeUrl) return
    if (anchorTagUrl !== stripeUrl) setAnchorTagUrl(stripeUrl)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeUrl])

  // when the anchorTag is equal to the stripeUrl, click the anchor tag url
  useEffect(() => {
    if (!anchorTagUrl || !stripeUrl) return // if either of the urls have not been set, return
    if (anchorTagUrl !== stripeUrl) return // if the anchor tag has not been set to the returned stripe url, return
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorTagUrl])

  const onClick = async () => {
    // Set Errors
    if (!user || !isLoggedIn()) return setError(error_missing_id)

    try {
      setRedirecting(true)
      const { url } = await createPortalSession({
        customer: user.stripeId,
        return_url: 'https://stlswing.dance',
      })

      window.location.replace(url)
      setStripeUrl(url)

      setTimeout(() => {
        setError(error_not_fucking_working)
      }, 1000)
    } catch {
      setRedirecting(false)
      setError(error_stripe)
    }
  }

  return (
    <>
      <button type='submit' className={props.className} onClick={onClick}>
        <div className='align-center flex justify-center align-middle'>
          {redirecting ? <p className={''}>...Redirecting</p> : props.children}
          <div className='flex justify-center ml-5'>
            {redirecting && <Loading className='h-7 w-7' />}
          </div>
        </div>
      </button>

      {error && <p className='p-5 font-semibold text-red-400'>{error}</p>}

      {/* eslint-disable-next-line jsx-a11y/anchor-has-content  */}
      <a className='hidden' href={stripeUrl} ref={linkButtonRef} />
    </>
  )
}
