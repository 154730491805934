import React, { useContext, useState, useEffect } from 'react'
import { useFirestore } from 'app/firebase'
import { Input } from 'app/shared/components'
import { FormModal } from 'app/shared/components/Modal'
import { addWeeks } from 'date-fns/fp'
import { useForm } from 'react-hook-form'
import { GroupClass } from '../../stlswing.types'
import {
  Textarea,
  MultipleDatePicker,
  Slider,
  CurrentUserContext,
} from 'app/shared'
import {
  DanceFormSelect,
  LevelSelect,
  TeacherSelect,
  DanceVideoSelect,
  QrCodeColorSelect,
} from '../Shared/Fields'
import { sendEmails } from 'app/firebase/functions/sendgrid/sendEmails'
import { useGetAdmins, useGetTeachers } from 'app/stlswing/hooks'
import { formatDate } from 'app/stlswing/helpers/date.helpers'

type Props = {
  title: string
  groupClass?: GroupClass<true>
  closeForm: () => void
  isOpen: boolean
}

export const GroupClassFormModal = ({ groupClass, ...props }: Props) => {
  const form = useForm()
  const { user } = useContext(CurrentUserContext)
  const { update, createWithId } = useFirestore('groupClasses')
  const [dates, setDates] = useState(
    groupClass?.dates.map((d) => new Date(d)) || [new Date()],
  )

  useEffect(() => {
    if (!groupClass) return
    setDates(groupClass.dates.map((d) => new Date(d)))
  }, [groupClass])

  const teachers = useGetTeachers()
  const admins = useGetAdmins()

  type FormData = Pick<
    GroupClass<true>,
    | 'danceForm'
    | 'description'
    | 'qrCode'
    | 'name'
    | 'level'
    | 'video'
    | 'instructors'
    | 'draft'
  >

  const onSubmit = async (formData: FormData) => {
    const groupClassData = {
      ...formData,
      dates: dates.map((d) => d.toISOString()),
    }

    if (!groupClass) {
      await createWithId(groupClassData)
      await sendEmails({
        subject: `[STLSWING ALERT]: A Group Class Has Been Created`,
        text: `The Group Class, ${
          groupClassData.name
        }, has been created and scheduled by ${
          user?.displayName
        }. The first class date will be on ${formatDate(
          'readable-date-and-time',
          dates[0],
        )} and the teachers will be ${teachers
          .filter((teacher) => formData.instructors.includes(teacher.id))
          .map((teacher) => teacher.displayName)
          .join(' and ')}.  It is a ${
          groupClassData.danceForm
        } class with a level of ${groupClassData.level}.  It has a ${
          groupClassData.qrCode
        } ticket color.  There are ${
          groupClassData.dates.length
        } classes in this series.  View or edit the class on this page:  https://stlswing.dance/Admin/Classes.  `,
        to: teachers
          .filter((teacher) => teacher.role === 'employee')
          .map((teacher) => teacher.email),
        bcc: ['info@stlswing.dance'],
      })
    }

    if (groupClass) {
      update(groupClass.id, { ...groupClass, ...groupClassData })
      sendEmails({
        subject: `[STLSWING ALERT]: ${groupClassData.name} has been Updated`,
        text: `The Group Class, ${groupClassData.name} has been updated by ${user?.displayName}`,
        to: teachers
          .filter(({ id }) => formData.instructors.includes(id))
          .map((teacher) => teacher.email),
      })
    }

    props.closeForm()
  }

  return (
    <FormModal
      title={props.title}
      onSubmit={form.handleSubmit<FormData>(onSubmit)}
      form={form}
      disallowRouting
      isOpen={props.isOpen}
      className='sm:w-1/2 w-screen pb-10 text-black'
      onClose={props.closeForm}
      type='right-panel'>
      <div className='p-4 pb-10 space-y-4'>
        <Input
          form={form}
          label='Group Class Name'
          name='name'
          required
          defaultValue={groupClass ? groupClass.name : 'Untitled Class'}
        />

        <MultipleDatePicker
          dates={dates}
          setDates={setDates}
          addDateTransform={(dates) => [
            ...dates,
            addWeeks(1, dates[dates.length - 1]),
          ]}
        />

        <div className='grid grid-cols-2 gap-4'>
          <TeacherSelect form={form} teacherIds={groupClass?.instructors} />
          <LevelSelect form={form} level={groupClass?.level} />
          <DanceFormSelect form={form} danceForm={groupClass?.danceForm} />
          <QrCodeColorSelect form={form} qrCode={groupClass?.qrCode} />
        </div>

        <DanceVideoSelect form={form} video={groupClass?.video} />

        <Textarea
          form={form}
          label='Description'
          name='description'
          required
          defaultValue={groupClass?.description || 'test description'}
        />

        {/* <Input name='requirements' label='Requirements' form={form} /> */}

        <Slider
          name='draft'
          form={form}
          defaultValue={groupClass?.draft || false}
          label='Draft State'
          description='classes with this toggled will not display pubilcly on the website.'
        />
      </div>
    </FormModal>
  )
}
