import React from 'react'
import { Loading } from './Loading'

export const LoadingPage = () => (
  <div className='w-screen h-screen'>
    <div className='h-96 flex justify-end w-full bg-gray-600'>
      <Loading />
    </div>
    <div className='h-96' />
  </div>
)
