import React from 'react'

type Props = {
  image: string
  title: string
  subText: string
  pill?: [color: string, text: string]
  width?: string
}

export const AvatarHeading = (props: C<Props>) => {
  return (
    <div className={`flex items-center truncate`}>
      <div className='flex-shrink-0 w-10 h-10'>
        <img className='w-10 h-10 rounded-full' src={props.image} alt='' />
      </div>
      <div className='ml-4 truncate'>
        <div className='flex items-center space-x-3'>
          <div className='text-sm font-medium text-gray-900 truncate'>
            {props.title}
          </div>
          {props.pill && (
            <span
              className={`flex-shrink-0 inline-block px-2 py-0.5 text-teal-800 bg-${props.pill[0]}-200 rounded-full text-sm leading-4 font-medium`}>
              {props.pill[1]}
            </span>
          )}
        </div>
        <div className='text-sm text-gray-500 truncate'>{props.subText}</div>
      </div>
    </div>
  )
}
