import {
  faExclamationTriangle,
  faStop,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export interface InfoBoxProps {
  icon?: boolean
  type?: 'warning' | 'error' | 'info'
  headline?: string
  title: string
  description: string
}
export const InfoBox = React.memo((props: C<InfoBoxProps>) => {
  const getStyleInfo = () => {
    if (props.type === 'warning') {
      return {
        border: 'border-yellow-600',
        text: 'text-yellow-600',
        icon: faExclamationTriangle,
      }
    }

    if (props.type === 'error') {
      return {
        border: 'border-red-600',
        text: 'text-red-600',
        icon: faStop,
      }
    }

    return {
      border: 'border-indigo-600',
      text: 'text-indigo-600',
      icon: faExclamationTriangle,
    }
  }

  const styleInfo = getStyleInfo()

  return (
    <div
      className={`flex flex-col m-4 shadow-xl ${styleInfo.border} border-2 rounded-lg max-w-fit p-4 mx-auto items-center justify-center`}>
      {props.headline && (
        <div className='flex items-center w-full justify-center'>
          <FontAwesomeIcon
            icon={styleInfo.icon}
            className={`${styleInfo.text} h-14 w-14`}
          />
          <p className={`${styleInfo.text} font-bold font-2xl`}>
            {props.headline}
          </p>
        </div>
      )}
      <p className='text-lg font-extrabold text-gray-600'>{props.title}</p>
      <p className='p-2'>{props.description}</p>
    </div>
  )
})
