import React, { useState } from 'react'
import { Input } from 'app/shared/components'
import { FormModal } from 'app/shared/components/Modal'
import { useForm } from 'react-hook-form'
import { Textarea } from 'app/shared/components/Field/Input/Textarea'
import { sendEmails } from 'app/firebase/functions/sendgrid/sendEmails'
import { uniq } from 'ramda'
import { useFirestore, User } from 'app/firebase'
import { Select } from 'app/shared/components/Field/Select/Select'
import { Option } from 'app/shared/components/Field/Option/Option'
import { TEACHERS_MAP } from 'app/stlswing/stlswing.constants'
import { toast } from 'react-toastify'

type Props = {
  title: string
  closeForm: () => void
  hideFilters?: boolean
  emailAddresses?: User<true>[]
  isOpen: boolean
}

export const SendEmailsForm = (props: Props) => {
  const form = useForm()
  const { map: ordersMap, list: orders } = useFirestore('orders')

  const [filters, setFilters] = useState({
    members: false,
    students: false,
    onlyOne: false,
    onlyMonthly: false,
  })

  const filterEmails = (filterState: typeof filters, users: User<true>[]) => {
    const filteredUsers = users.reduce((toEmail, currentUser) => {
      if (!!TEACHERS_MAP[currentUser.id]) return toEmail
      // members in class
      if (filterState.members) {
        if (currentUser.member) return [...toEmail, currentUser]
      }
      // people with student discounts
      if (filterState.students) {
        if (currentUser.studentDiscount) return [...toEmail, currentUser]
      }

      // who have only gone to one event
      if (filterState.onlyOne) {
        const userOrders =
          orders &&
          uniq(
            orders.filter((order) => order?.internalUserId === currentUser.id),
          )

        if (
          userOrders &&
          userOrders.length === 1 &&
          userOrders[0].internalProductType === 'events'
        ) {
          return [...toEmail, currentUser]
        }
      }

      if (!filterState.members && !filterState.students && !filterState.onlyOne)
        return [...toEmail, currentUser]

      return toEmail
    }, [] as User<true>[])

    return uniq(filteredUsers)
  }

  type FormData = {
    subject: string
    text: string
    html: string
  }

  const onSubmit = async (formData: FormData) => {
    if (!props.emailAddresses) return

    const filtered = filterEmails(filters, props.emailAddresses)

    // console.log(formData)
    const payload = {
      to: filtered.map((user) => user.email),
      ...formData,
    }
    await sendEmails(payload)
      .then((res) => {
        toast.success('Emails Are Sending')
      })
      .catch((err) => {
        toast.error(`${err}`)
      })

    props.closeForm()
  }

  return (
    <FormModal
      title={props.title}
      onSubmit={form.handleSubmit<FormData>(onSubmit)}
      form={form}
      isOpen={props.isOpen}
      onClose={props.closeForm}
      className='sm:w-1/2 w-screen text-black'
      type='right-panel'>
      <div className='p-4'>
        <Select
          multiple={true}
          label='Filter Users'
          name='filters'
          onSelect={(selections) => {
            if (selections.includes('members'))
              setFilters((prev) => ({ ...prev, members: true }))
            if (selections.includes('students'))
              setFilters((prev) => ({ ...prev, students: true }))
            if (selections.includes('onlyOne'))
              setFilters((prev) => ({ ...prev, onlyOne: true }))
            if (selections.includes('onlyMonthly'))
              setFilters((prev) => ({ ...prev, onlyOne: true }))

            if (!selections.includes('members'))
              setFilters((prev) => ({ ...prev, members: false }))
            if (!selections.includes('students'))
              setFilters((prev) => ({ ...prev, students: false }))
            if (!selections.includes('onlyOne'))
              setFilters((prev) => ({ ...prev, onlyOne: false }))
            if (selections.includes('onlyMonthly'))
              setFilters((prev) => ({ ...prev, onlyOne: false }))
          }}>
          <Option label='Members' value='members' />
          <Option label='Students' value='students' />
          <Option label='Only 1 Dance' value='onlyOne' />
        </Select>

        <Input form={form} label='Subject' name='subject' required />

        <Textarea form={form} label='Body' name='text' required />
      </div>
    </FormModal>
  )
}
