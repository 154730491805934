import React from 'react'
import { Select, Option } from 'app/shared'
import { useForm } from 'react-hook-form'
import { GroupClass } from 'app/stlswing/stlswing.types'
import { CLASS_VIDEOS } from 'app/stlswing/stlswing.constants'

type Props = {
  form: ReturnType<typeof useForm>
  video?: GroupClass['video']
}

export const DanceVideoSelect = ({ form, video }: Props) => (
  <Select
    form={form}
    data-testid='dance-video-select'
    label='Video'
    name='video'
    defaultValues={[video || 'videos/skye-frida-swingouts.mp4']}
    required>
    {CLASS_VIDEOS.map((level) => (
      <Option label={level} value={level} key={level} />
    ))}
  </Select>
)
