import React from 'react'
import { Carousel } from 'react-responsive-carousel'

type Props = {
  title: string
  subText: string
  images?: string[]
}

export const CarouselSection = (props: C<Props>) => (
  <div className='max-w-7xl sm:px-6 lg:px-8 lg:py-24 px-4 py-12 mx-auto'>
    <div className='lg:grid-cols-2 lg:gap-8 grid grid-cols-1 gap-12'>
      <div className='sm:space-y-4 space-y-5'>
        <h2 className='sm:text-4xl text-3xl font-extrabold tracking-tight'>
          {props.title}
        </h2>
        <p className='align-self-end text-xl text-gray-500'>{props.subText}</p>
        {props.children}
      </div>

      <Carousel emulateTouch infiniteLoop autoPlay>
        {/* prettier-ignore */}
        <img alt="" className='w-full' src={ require('../../../../assets/images/lhsl-home-cover.jpg')} />
        {/* prettier-ignore */}
        <img alt="" src={ require('../../../../assets/images/kevin-julianne-2.jpg') } />
        {/* prettier-ignore */}
        <img alt="" src={ require('../../../../assets/images/kevin-and-juliane-2.jpg') } />
        {/* prettier-ignore */}
        <img alt="" src={ require('../../../../assets/images/class-recap-img.jpg') } />
        {/* prettier-ignore */}
        <img alt="" src={ require('../../../../assets/images/group-classes.jpg') } />
      </Carousel>
    </div>
  </div>
)
