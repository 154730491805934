import React, { useEffect, useState } from 'react'
import { add, allPass, prop } from 'ramda'
import {
  Button,
  DataTable,
  TableSchema,
  useFirestore,
  Modal,
} from 'app/shared/'
import { isPast } from 'date-fns'
import { OptionNode } from 'app/shared/components/Table/components/NestedOptionsMenu'
import { CloseIcon } from 'app/shared/components/Icons/CloseIcon'
import { FAQ } from './Faq.types'
import { FaqFormModal } from './components/FaqFormModal'
import { UserAvatarHeading } from 'app/users/components/UserAvatarHeading'
import { sendEmails } from 'app/firebase/functions/sendgrid/sendEmails'

export const FaqsDashboard = () => {
  const { list: faqs, remove: removeFaq } = useFirestore('faq')
  const { map: userMap } = useFirestore('users')

  const [addingFaq, setAddingFaq] = useState(false)
  const [selectedFaq, setSelectedFaq] = useState<FAQ>()

  type Columns = 'Question' | 'Created By' | 'Last Updated By' | 'Actions'
  const faqTableSchema: TableSchema<FAQ, Columns> = {
    Question: prop('name'),
    'Created By': ({ createdBy }) => (
      <UserAvatarHeading user={userMap && userMap[createdBy]} />
    ),
    'Last Updated By': ({ updatedBy }) => (
      <UserAvatarHeading user={userMap && userMap[updatedBy]} />
    ),
    Actions: (faq) => (
      <div>
        <div className='space-x-2 flex'>
          <Button
            variant='raised'
            className='flex-1'
            onClick={() => setSelectedFaq(faq)}>
            Edit
          </Button>
          <Button variant='warn' onClick={() => removeFaq(faq.id)}>
            <CloseIcon className='text-white rounded-full' />
          </Button>
        </div>
      </div>
    ),
  }

  return (
    <div className=' w-full overflow-hidden'>
      <div className='flex items-center h-16 mb-8 space-x-10'>
        <h2 className='text-4xl font-extrabold text-gray-800'>Faqs</h2>
        <Button onClick={() => setAddingFaq((v) => !v)} variant='raised'>
          Add Frequently Asked Question
        </Button>
      </div>

      <FaqFormModal
        title='Add Faq Question'
        isOpen={addingFaq}
        closeForm={() => setAddingFaq(false)}
      />

      <DataTable<FAQ> tableSchema={faqTableSchema} recordsList={faqs} />

      <FaqFormModal
        isOpen={!!selectedFaq}
        closeForm={() => setSelectedFaq(undefined)}
        title={`Edit ${selectedFaq?.name}`}
        faq={selectedFaq}
      />
    </div>
  )
}
