import React from 'react'
import { Select, Option } from 'app/shared'
import { useForm } from 'react-hook-form'
import { GroupClass } from 'app/stlswing/stlswing.types'
import { QRCODES } from 'app/stlswing/stlswing.constants'

type Props = {
  form: ReturnType<typeof useForm>
  qrCode?: GroupClass['qrCode']
}

export const QrCodeColorSelect = ({ form, qrCode }: Props) => (
  <Select
    form={form}
    label='Qr Code'
    name='qrCode'
    data-testid='qrcode-select'
    defaultValues={[qrCode || '']}
    required>
    {Object.keys(QRCODES).map((code) => (
      <Option label={code} value={code} key={code} />
    ))}
  </Select>
)
