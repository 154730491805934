import React from 'react'

export const BookIcon = ({ className }: C) => (
  <svg
    version='1.1'
    viewBox='0 0 512 512'
    className={`w-7 h-7 mr-3 ${className}`}>
    <g>
      <g>
        <g>
          <rect x='132.083' y='55.882' width='15.694' height='312.191' />
          <path
            d='M427.949,0h-288.02c-30.81,0-55.877,25.067-55.877,55.877v400.246c0,30.811,25.066,55.877,55.877,55.877h288.02v-87.897
				h-15.694v72.202H139.928c-22.156,0-40.183-18.025-40.183-40.183s18.026-40.183,40.183-40.183h288.02V0z M412.254,400.246H147.776
				v-16.167h-15.694v16.732c-12.611,1.781-23.887,7.773-32.336,16.522V55.877c0-19.471,13.923-35.747,32.336-39.407v23.397h15.694
				V15.694h264.479V400.246z'
          />
          <path
            d='M139.928,439.956v-15.694c-17.569,0-31.862,14.293-31.862,31.862c0,17.568,14.293,31.862,31.862,31.862V472.29
				c-8.915,0-16.167-7.253-16.167-16.167S131.014,439.956,139.928,439.956z'
          />
          <rect x='148.092' y='424.102' width='15.694' height='64.043' />
          <rect x='171.947' y='424.259' width='232.14' height='15.694' />
          <rect x='171.947' y='448.272' width='232.14' height='15.694' />
          <rect x='171.947' y='472.294' width='232.14' height='15.694' />
          <rect x='235.99' y='360.227' width='88.056' height='15.694' />
          <path d='M371.913,40.025H188.116v143.774h183.797V40.025z M356.219,168.104H203.811V55.719h152.409V168.104z' />
          <rect x='219.982' y='72.044' width='120.072' height='15.694' />
          <rect x='219.982' y='104.064' width='88.056' height='15.694' />
          <rect x='219.982' y='136.08' width='72.044' height='15.694' />
        </g>
      </g>
    </g>
  </svg>
)
