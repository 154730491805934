import React, { useEffect, useState } from 'react'
import { Modal, ModalProps } from 'app/shared'
import { getImageSrcList } from 'app/shared/utils/fileUpload'
import { Carousel } from 'react-responsive-carousel'

type Props = {
  recaps?: string[]
}

export const Recaps = ({ recaps }: Props) => {
  const [recapSrcList, setRecapSrcList] = useState<any[]>()

  useEffect(() => {
    if (!recaps) return
    getImageSrcList(recaps).then(setRecapSrcList)
  }, [recaps])

  if (!recaps) return null
  if (!recapSrcList) return null
  return (
    <div className='p-2 py-8'>
      <Carousel>
        {recapSrcList.map((src) => (
          <div>
            <video playsInline controls src={src} />
          </div>
        ))}
      </Carousel>
    </div>
  )
}

export const RecapsModal = ({
  recaps,
  isOpen,
  onClose,
}: Props & Omit<ModalProps, 'children' | 'type' | 'title'>) => {
  return (
    <Modal type='right-panel' title='Recaps' isOpen={isOpen} onClose={onClose}>
      <Recaps recaps={recaps} />
    </Modal>
  )
}
