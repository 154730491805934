import {
  CollectionNames,
  CurrentUserContext,
  User,
  useFirestore,
  usePermissions,
} from 'app/firebase'
import { Event, GroupClass } from '../stlswing.types'
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from 'firebase/firestore'
import { differenceInCalendarDays, isPast } from 'date-fns'
import { groupBy, prop } from 'ramda'
import { useCallback, useContext, useEffect, useState } from 'react'

import { Order } from '../components/Accounting/finance.types'
import { firebaseApp } from 'environments'

export type ClassOrder = Order & { data: GroupClass<true>; user: User }
export type EventOrder = Order & { data: Event<true>; user: User }

export const useUsers = () => {
  const { user, permissions } = useContext(CurrentUserContext)
  // const { list: users, apiState } = useFirestore('users')
  // const { list: permissionsList } = useFirestore('permissions')
  const { hasAnyRole } = usePermissions()
  const [teachers] = useState<User<true>[]>([])
  const [allStudents] = useState<User<true>[]>([])
  const [members] = useState<User<true>[]>([])
  const [studentDiscounted] = useState<User<true>[]>([])
  const [orders, setOrders] = useState<Order[]>()
  const { map: classesMap, list: groupClasses } = useFirestore('groupClasses')
  const { map: eventsMap, list: events } = useFirestore('events')
  const [userEventOrders, setUserEventOrders] = useState<EventOrder[]>()
  const [userClassOrders, setUserClassOrders] = useState<ClassOrder[]>()
  const [currentUserEvents, setCurrentUserEvents] = useState<Event<true>[]>([])
  const [currentUserGroupClassess, setCurrentUserGroupClasses] = useState<
    GroupClass<true>[]
  >([])

  // If Not an admin, get all orders for their ID
  useEffect(() => {
    if (!user || !permissions) return
    if (hasAnyRole(['admin', 'employee'])) return
    const listener = userIdPropertyQuery('orders', ['internalUserId', user.id])

    return () => {
      listener.then((unsubscribe) => unsubscribe())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  // If admin, view all events / users
  useEffect(() => {
    if (!events || !groupClasses) return
    if (!hasAnyRole(['admin', 'employee'])) return
    setCurrentUserEvents(events)
    setCurrentUserGroupClasses(groupClasses)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupClasses, events, permissions])

  // If member, view all events / classes
  useEffect(() => {
    if (!user?.member || !groupClasses) return
    setCurrentUserGroupClasses(groupClasses)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, groupClasses, events])

  useEffect(() => {
    if (!orders || !classesMap || !eventsMap || !user) return

    const { events, classes } = groupBy((order: Order) => {
      if (order.internalPaymentType?.includes('Event')) return 'events'
      return 'classes'
    }, orders)

    const eventOrders = events
      ? events.map((order) => ({
          ...order,
          user,
          data: eventsMap[order.internalProductId],
        }))
      : []

    const classesOrders = classes
      ? classes.map((order) => ({
          ...order,
          user,
          data: classesMap[order.internalProductId],
        }))
      : []

    setUserEventOrders(eventOrders)
    setUserClassOrders(classesOrders)
    // setCurrentUserGroupClasses(Object.values(classesMap))
    // setCurrentUserEvents(Object.values(eventsMap))
    setCurrentUserEvents(eventOrders.map(prop('data')))
    !user.member && setCurrentUserGroupClasses(classesOrders.map(prop('data')))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders, classesMap, eventsMap])

  async function userIdPropertyQuery(
    collectionName: CollectionNames,
    [property, userId]: [string, string],
  ) {
    const userQuery = query(
      collection(getFirestore(firebaseApp), collectionName),
      where(property, '==', userId),
    )

    return await onSnapshot(userQuery, (snapshot) =>
      setOrders(snapshot.docs.map((d) => d.data() as Order)),
    )
  }

  const userHasGroupClass = (groupClassId: string) => {
    if (!orders) return false
    if (!user) return false

    // if (user.member) return true

    // If No Class Order Is Found, return false
    const userClassOrder = orders.find(
      (order) =>
        order.internalProductId === groupClassId &&
        order.internalUserId === user.id,
    )
    if (userClassOrder?.credits && userClassOrder.credits > 0) return true
    if (!userClassOrder) return false

    // If No Class Object Is Found From Order, return false
    const groupClass =
      classesMap && classesMap[userClassOrder.internalProductId]
    if (!groupClass) return false

    // If Payment Type is A la Carte, only remain valid for 1 week after purchase
    if (userClassOrder.internalPaymentType === 'A La Carte') {
      return (
        differenceInCalendarDays(new Date(), new Date(userClassOrder.date)) < 7
      )
    }

    // If Payment Type is First Half, only remain valid until the halfway date in class
    if (userClassOrder.internalPaymentType === 'First Half Of Class') {
      const middleDateIndex = groupClass.dates.length / 2 - 1
      if (isPast(new Date(groupClass.dates[middleDateIndex]))) return false
      return true
    }

    return true

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }

  return {
    teachers,
    allStudents,
    members,
    studentDiscounted,
    orders,
    userEventOrders,
    userClassOrders,
    currentUserGroupClassess,
    currentUserEvents,
    userHasGroupClass,
  }
}
