import {
  PaymentType,
  ProductType,
} from 'app/firebase/functions/stripe.functions'
import { STRIPE_PRICE_IDS } from 'app/stlswing/stlswing.constants'
import { Event, EventTypes } from 'app/stlswing/stlswing.types'
import { ENV } from 'environments'

export const getEventPrice = (type: Event['type']) => {
  if (type === 'Monthly') return 20
  if (type === 'Harmonizers Dance') return 15

  // Default is just a weekly event
  return 10
}

export const getEventPriceId = (type: Event['type']) => {
  if (type === 'Weekly') return STRIPE_PRICE_IDS[ENV].WEEKLY_EVENT
  if (type === 'Monthly') return STRIPE_PRICE_IDS[ENV].MONTHLY_EVENT
  if (type === 'Harmonizers Dance')
    return STRIPE_PRICE_IDS[ENV].HARMONIZERS_DANCE
  if (type === 'Chelsea Reed') return STRIPE_PRICE_IDS[ENV].CHELSEA_REED

  // Default is just a weekly event
  return STRIPE_PRICE_IDS[ENV].WEEKLY_EVENT
}

export const getEventPaymentType = (
  type: Event['type'] | 'Dance Membership',
): PaymentType => {
  if (type === 'Weekly') return 'Weekly Event'
  if (type === 'Monthly') return 'Monthly Event'
  if (type === 'Harmonizers Dance') return 'Harmonizers Dance'
  if (type === 'Dance Membership') return 'Social Dance Membership'

  return 'Weekly Event'
}
