import React from 'react'
import { Select, Option } from 'app/shared'
import { useForm } from 'react-hook-form'
import { GroupClass } from 'app/stlswing/stlswing.types'
import { GROUP_CLASS_LEVELS } from 'app/stlswing/stlswing.constants'

type Props = {
  form: ReturnType<typeof useForm>
  level?: GroupClass['level']
}

export const LevelSelect = ({ form, level }: Props) => (
  <Select
    form={form}
    label='Level'
    name='level'
    data-testid='all-levels-select'
    defaultValues={[level || 'All Levels']}
    required>
    {GROUP_CLASS_LEVELS.map((level) => (
      <Option label={level} value={level} key={level} />
    ))}
  </Select>
)
