import { FullPageContent } from 'app/shared/components/Layout/FullPageLayout/Content/FullPageContent'
import { UserCard } from 'app/users/components/UserCard'
import { FullPageWrapper } from 'app/shared/components/Layout/FullPageLayout/FullPageWrapper'
import { FullPageHeader } from 'app/shared/components/Layout/FullPageLayout/Header/FullPageHeader'
import { FullPageSidebar } from 'app/shared/components/Layout/FullPageLayout/Sidebar/FullPageSidebar'
import { useQuery } from 'react-query'
import React, { useState } from 'react'
import {
  collection,
  getFirestore,
  query,
  where,
  getDocs,
} from 'firebase/firestore'
import { firebaseApp } from 'environments'
import { useFirestore, User } from 'app/firebase'
import { UserForm } from 'app/users/components/UserForm'
import { AdminMobileHeader } from 'app/stlswing/pages'
import { SendEmailsForm } from '../../Shared/SendEmailsForm'
import { Search } from 'app/shared'

const getMembers = async () =>
  await getDocs(
    query(
      collection(getFirestore(firebaseApp), 'users'),
      where('member', '==', true),
    ),
  )
    .then((d) => d.docs)
    .then((d) => d.map((d) => d.data() as User<true>))

export const MembersDashboard = () => {
  const { data: members } = useQuery(['members'], () => getMembers())
  const [selectedUser, setSelectedUser] = useState<User<true>>()
  const [sendingEmail, setSendingEmail] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')

  return (
    <FullPageWrapper>
      <AdminMobileHeader title={'Members'}>
        <div className='border rounded-md flex justify-center items-center p-2'>
          <Search
            className='p-2'
            onSearch={setSearchTerm}
            placeholder='Search Members'
          />
        </div>
      </AdminMobileHeader>

      <h2 className='p-4 bg-white rounded-md flex items-center justify-center m-2'>
        {members?.length} Current Members
      </h2>
      <FullPageContent className='grid sm:grid-cols-3 gap-3 p-4'>
        {members?.map((member) => (
          <UserCard
            index={Number(member.id)}
            user={member}
            setSelectedUser={() => setSelectedUser(member)}
          />
        ))}
      </FullPageContent>

      <UserForm
        isVisible={!!selectedUser}
        onClose={() => setSelectedUser(undefined)}
        user={selectedUser}
      />

      <SendEmailsForm
        closeForm={() => setSendingEmail(false)}
        isOpen={sendingEmail}
        title='Send an Email to All Members'
        emailAddresses={members}
      />
    </FullPageWrapper>
  )
}
