import { CurrentUserContext } from 'app/firebase'
import { Modal } from 'app/shared/components'
import React, { useContext, useMemo, useState } from 'react'
import { GroupClass } from '../../groupClass.types'
import { Step, Stepper } from 'app/shared/components/Stepper'
import { OverviewStep } from './components/OverviewStep/OverviewStep'
import { AttendeeInfoFormStep } from './components/AttendeeInfoFormStep/AttendeeInfoFormStep'
import { PricingOptionsStep } from './components/PricingOptionsStep/PricingOptionsStep'

type Props = {
  groupClass: GroupClass
}

export const GroupClassSignup = ({ groupClass }: C<Props>) => {
  const { user } = useContext(CurrentUserContext)

  const attendeeInfoStepDisabled = useMemo(() => {
    const discountDisable = user?.studentDiscount
      ? !!!user.studentIdImagePath
      : false
    const positionDisable =
      user?.position === 'undecided' ? true : !!user?.position
    return discountDisable && positionDisable
    // if (user?.studentDiscount) return !!!user.studentIdImagePath
  }, [user?.studentDiscount, user?.position, user?.studentIdImagePath])

  const infoDisabled = () => {
    const studentValidation = user?.studentDiscount
      ? !!!user.studentIdImagePath
      : false
    return studentValidation || !user?.experience || !user?.position
  }

  return (
    <Stepper fullPage>
      <Step title='Overview'>
        <OverviewStep groupClass={groupClass} />
      </Step>
      <Step title='Add Info' disabled={infoDisabled()}>
        <AttendeeInfoFormStep />
      </Step>
      <Step title='Choose Payment Option' disabled={true}>
        <PricingOptionsStep groupClass={groupClass} />
      </Step>
    </Stepper>
  )
}
