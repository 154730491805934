import { CurrentUserContext } from 'app/firebase'
import React, { useContext } from 'react'
import { DanceExperienceSelect } from './components/DanceExperienceSelect'
import { DancePositionSelect } from './components/DancePositionSelect'
import { StudentDiscountSelect } from './components/StudentDiscountSelect'

export const AttendeeInfoFormStep = () => {
  const { user } = useContext(CurrentUserContext)

  if (!user) return null
  return (
    <div className='w-full flex flex-col justify-center items-stretch pb-32'>
      <p className='text-xl font-bold mx-auto pt-4'>
        What Position Will You Be Learning?
      </p>
      <DancePositionSelect />

      <p className='text-xl font-bold mx-auto pt-4'>
        How Long Have You Been Dancing?
      </p>
      <DanceExperienceSelect />

      <p className='text-xl text-center font-bold mx-auto pt-4'>
        Are You Elligible For A Student Discount?
      </p>
      <p className='text-sm text-gray-400 mx-auto p-4'>
        Students recieve a flat 50% discount to all Classes
      </p>
      <StudentDiscountSelect user={user} />
      <div></div>
    </div>
  )
}
