import React from 'react'

export const StickyHeader = ({ children, className }: C<{}>) => {
  return (
    <div
      className={
        'sticky w-screen flex bg-white top-0 z-30 border-b border-red-800 ' +
        className
      }>
      {children}
    </div>
  )
}
