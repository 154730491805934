import { useFirestore } from 'app/firebase'
import { Tabs } from 'app/shared'
import CardStepper from 'app/shared/components/Layout/Cards/CardStepper'
import { useState } from 'react'
import { GroupClass } from '../components/GroupClass/groupClass.types'
import { formatDate } from '../helpers/date.helpers'
import { TEACHERS_MAP } from '../stlswing.constants'
import { AdminMobileHeader } from './AdminPage'

export const UpcomingClassesPage = () => {
  const { list: groupClasses } = useFirestore('groupClasses')
  const [tabs, setTabs] = useState({
    Beginner: false,
    Intermediate: true,
    Advanced: false,
  })

  return (
    <div className='w-full'>
      <div className='h-16 w-full bg-gray-800' />

      <div className='flex flex-col items-center w-full justify-center'>
        <h1 className='h-16 bg-white  justify-center p-4 flex items-center text-3xl font-extrabold'>
          Marketing milk
        </h1>

        {/* <Tabs tabs={tabs} setTabs={setTabs}></Tabs> */}
        {/*
        <CardStepper
          formatHeader
          list={[
            {
              content: <div>test</div>,
              children: [
                {
                  content: <div>test</div>,
                },
              ],
            },
          ]}
        /> */}

        <div className='space-y-4 divide-y'>
          <ClassSectionHeading title='Brand New' subTitle='' />
          <CondensedClassCard groupClass={groupClasses && groupClasses[0]} />

          <ClassSectionHeading
            title='Under 2 Months'
            subTitle='You must have completed Starting Swing before entering this course'
          />
          <CondensedClassCard groupClass={groupClasses && groupClasses[0]} />

          <ClassSectionHeading
            title='2 - 6 Months'
            subTitle='You must have completed Continuing Swing before entering this course'
          />
          <CondensedClassCard groupClass={groupClasses && groupClasses[0]} />

          <ClassSectionHeading
            title='Over 6 Months'
            subTitle=' You must be comfortable with Swing Outs, Charleston, Balboa, Tuck Turns,'
          />
          <CondensedClassCard groupClass={groupClasses && groupClasses[0]} />
        </div>
      </div>
    </div>
  )
}

const CondensedClassCard = ({ groupClass }: { groupClass?: GroupClass }) => {
  const getInstructorsFromProduct = () => {
    return groupClass?.instructors
      .map((id) => TEACHERS_MAP[id].name)
      .join(' and ')
  }

  const getFirstDate = () => {
    if (!groupClass) return null
    return formatDate('condensed-readable', groupClass.dates[0])
  }

  return (
    <div className='bg-white rounded-md border shadow-md p-2'>
      <h2 className='flex items-center border-b py-2'>
        <div className='pl-4 flex flex-col grow truncate'>
          <span className='flex flex-1 items-center'>
            <h2 className='pb-1 text-xl font-semibold truncate'>
              {groupClass?.name}
            </h2>
            <p className='bg-green-200 text-green-800 rounded-full flex items-center h-1/3 justify-center text-sm px-2 ml-2'>
              {groupClass?.danceForm}
            </p>
          </span>

          <p className='text-gray-400 text-sm'>
            {getInstructorsFromProduct()} - Begins {getFirstDate()}
          </p>
        </div>
      </h2>

      <div className='grid grid-cols-2 divide-x divide-y'>
        <CardButton onClick={() => ''}>Sign Up</CardButton>
        <CardButton onClick={() => ''}>More Info</CardButton>
      </div>
    </div>
  )
}

const CardButton = ({ text, children, onClick }: any) => (
  <button
    onClick={onClick}
    className='flex flex-1 p-4 sm:p-6 transform duration-150 hover:bg-gray-100 text-gray-600 items-center justify-center h-10 font-medium text-md'>
    {children}
  </button>
)

type Props = {
  title: string
  subTitle: string
}
const ClassSectionHeading = ({ title, subTitle }: Props) => (
  <div className='flex flex-col justify-center items-center max-w-sm pt-2 pb-4'>
    <h2 className='font-bold text-2xl'>{title}</h2>
    <p className='text-xs text-gray-500'>{subTitle}</p>
  </div>
)
