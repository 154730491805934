import React, { useEffect, useState } from 'react'
import { Event, GroupClass } from 'app/stlswing/stlswing.types'
import { AttendeesTable } from '../../Shared/AttendeesTable/AttendeesTable'
import { Order } from '../finance.types'
import { add, allPass, prop } from 'ramda'
import {
  Button,
  DataTable,
  TableSchema,
  useFirestore,
  Modal,
} from 'app/shared/'
import { compareAsc, isPast } from 'date-fns'
import { OptionNode } from 'app/shared/components/Table/components/NestedOptionsMenu'
import { EventFormModal } from '../..'
import { CloseIcon } from 'app/shared/components/Icons/CloseIcon'
import { AdminMobileHeader } from 'app/stlswing/pages'
import { formatDate } from 'app/stlswing/helpers/date.helpers'

export const EventsDashboard = () => {
  const { list: orders } = useFirestore('orders')
  const { list: events, remove: removeEvent } = useFirestore('events')

  const [addingEvent, setAddingEvent] = useState(false)
  const [selectedEvent, setSelectedEvent] = useState<Event<true>>()

  const [selectedProduct, setSelectedProduct] = useState<Event<true>>()
  const getAttendees = (product: Event<true> | undefined, orders?: Order[]) => {
    if (!product || !orders) return []
    return orders
      .filter((o) => o.internalProductId === product?.id)
      .map((i) => i.internalUserId)
  }

  type Columns = 'Name' | 'Attendees' | 'Type' | 'Date' | 'Actions' | 'Date'
  const eventsTableSchema: TableSchema<Event<true>, Columns> = {
    Name: prop('name'),
    Type: prop('type'),
    Date: (event) => formatDate('readable-date-and-time', event.dateTime),
    Attendees: (event) => (
      <Button
        className='text-blue-600'
        onClick={() => setSelectedProduct(event)}>
        {getAttendees(event, orders).length || 0}
      </Button>
    ),
    Actions: (event) => (
      <div className='space-x-2 flex'>
        <Button
          variant='raised'
          className='flex-1'
          onClick={() => setSelectedEvent(event)}>
          Edit
        </Button>
        <Button variant='warn' onClick={() => removeEvent(event.id)}>
          <CloseIcon className='text-white rounded-full' />
        </Button>
      </div>
    ),
  }

  return (
    <div className=' w-full overflow-auto'>
      <AdminMobileHeader title='Events'>
        <div className='p-4'>
          <Button onClick={() => setAddingEvent((v) => !v)} variant='raised'>
            Add Event
          </Button>
        </div>
      </AdminMobileHeader>

      <EventFormModal
        title='Add New Event'
        isOpen={addingEvent}
        closeForm={() => setAddingEvent(false)}
      />

      <EventFormModal
        isOpen={!!selectedEvent}
        closeForm={() => setSelectedEvent(undefined)}
        title={`Edit ${selectedEvent?.name}`}
        event={selectedEvent}
      />

      <Modal
        type='right-panel'
        className='w-screen overflow-auto'
        isOpen={!!selectedProduct}
        onClose={() => setSelectedProduct(undefined)}
        title='Attendees'>
        <AttendeesTable
          productId={selectedProduct?.id}
          attendees={getAttendees(selectedProduct, orders)}
          remove={() => ''} // TODO: add this
        />
      </Modal>

      <DataTable<Event<true>>
        tableSchema={eventsTableSchema}
        recordsList={events?.sort((a, b) =>
          compareAsc(new Date(a.dateTime), new Date(b.dateTime)),
        )}
        defaultSort='dateTime'
      />
    </div>
  )
}
