import React, { useContext, useState } from 'react'
import { CurrentUserContext, useFirestore, User } from 'app/firebase'
import { set } from 'lodash/fp'
import { DescriptiveRadioButton } from 'app/shared/components/Field/RadioButtons/DescriptiveRadioButton'

interface Props {}
export const DancePositionSelect = (props: Props) => {
  const { user, setUser } = useContext(CurrentUserContext)
  const { update: updateUser } = useFirestore('users')

  const updateUserPosition = (position: User['position']) => {
    if (!user) return
    setUser(set('position', position))
    updateUser(user.id, { position })
  }

  if (!user) return null
  return (
    <div className='w-full p-4'>
      <fieldset className='space-y-2'>
        <legend className='sr-only'>Choose Position</legend>
        <DescriptiveRadioButton
          name='Leader'
          description='The leader drives the direction of the dance by communicating momentum to the follow through his body and steps.'
          checked={user.position === 'lead'}
          onClick={() => updateUserPosition('lead')}
        />
        <DescriptiveRadioButton
          name='Follower'
          description='The follower moves in the direction the leads momentum perscribes while adding flairs, variations, and musicality.'
          checked={user.position === 'follow'}
          onClick={() => updateUserPosition('follow')}
        />
        <DescriptiveRadioButton
          name='Switch'
          description='A switch dancer can lead an entire dance, follow an entire dance, or switch positions on the fly.'
          checked={user.position === 'switch'}
          onClick={() => updateUserPosition('switch')}
        />
      </fieldset>
    </div>
  )
}
