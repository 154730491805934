import React, { useState } from 'react'
import { Controller, useController, UseFormMethods } from 'react-hook-form'
import { InlineDescriptionSlider } from './InlineDescriptionSlider'

export type SliderProps = {
  onClick?: () => void
  defaultValue?: boolean
  name: string
  description?: string
  form: UseFormMethods<any>
  required?: boolean
  label?: string
  variant?: 'condensed' | 'yes-or-no'
}

export const Slider = ({ defaultValue = false, ...props }: SliderProps) => {
  const [checked, setChecked] = useState(defaultValue)

  const { field } = useController({
    name: props.name,
    control: props.form.control,
    rules: { required: props.required },
    defaultValue,
  })

  return (
    <>
      <input
        className='sr-only'
        ref={field.ref}
        checked={checked}
        name={field.name}
        type='checkbox'
        onBlur={field.onBlur}
      />
      <InlineDescriptionSlider
        description={props.description || ''}
        name={props.label || props.name}
        onClick={() =>
          setChecked((v) => {
            field.onChange(!v)
            return !v
          })
        }
        checked={checked}
      />
    </>
  )
}
