import { CurrentUserContext, useFirestore } from 'app/firebase'
import { useRouter } from 'app/shared'
import { formatDate } from 'app/stlswing/helpers/date.helpers'
import { QRCODES } from 'app/stlswing/stlswing.constants'
import { Event, GroupClass } from 'app/stlswing/stlswing.types'
import { isAfter } from 'date-fns'
import { QRCodeSVG } from 'qrcode.react'
import { useContext, useState } from 'react'
import { Order } from '../Accounting/finance.types'

type Props = {
  type: 'Dance' | 'Class' | 'Full'
}

export const MembershipTicket = (props: Props) => {
  const { user } = useContext(CurrentUserContext)
  const { list: orders } = useFirestore('orders')
  const [order, setOrder] = useState<Order>()
  const { redirectTo } = useRouter()

  const getPassColor = () => {
    if (props.type === 'Class') return 'from-green-700 bg-green-900'
    if (props.type === 'Full') return 'from-green-400 bg-purple-900'
    if (props.type === 'Dance') return 'from-purple-700 bg-purple-900'
  }

  return (
    <div
      className={`bg-gradient-to-br  rounded-lg border-2 border-yellow-600 sm:max-w-sm w-96  mt-2 text-white ${getPassColor()}`}>
      <div className='flex justify-between p-4 pb-6'>
        <h2 className='text-4xl font-extrabold'>{props.type} Pass</h2>
        <TicketInfo title='#user' description={user?.id.slice(20) || ''} />
      </div>
      <div className='flex justify-between w-full px-6 py-2'>
        <TicketInfo
          title='User'
          description={user?.displayName.split(' ')[0] || ''}
        />
        <TicketInfo
          title='Month'
          description={formatDate('month', new Date().toISOString())}
        />
        <TicketInfo title='Membership Type' description={props.type} />
      </div>
      <div className=' flex justify-between w-full px-6 pt-2'>
        {/* <h2 className='text-2xl font-bold'>Membership</h2> */}
        <p className='text-md border-t border-gray-300 pt-4'>
          Show this pass at the door for quick entry into our events
        </p>
      </div>
      <div className='p-4 border-2 mb-14 mt-6 bg-white max-w-fit mx-auto rounded-lg relative'>
        <div className='flex justify-center'>
          (
          <QRCodeSVG
            value={`https://stlswing.dance/tickets/${user?.id}/redeem`}
            size={250}
            level='H'
            fgColor={order?.credits === 0 ? '#eeeeee' : undefined}
          />
          )
        </div>
      </div>
    </div>
  )
}

type TicketInfoProps = {
  title: string
  description: string
}
const TicketInfo = (props: TicketInfoProps) => {
  return (
    <div className=''>
      <p className='text-xs font-bold uppercase text-yellow-500'>
        {props.title}
      </p>
      <div className='text-md'>{props.description}</div>
    </div>
  )
}
