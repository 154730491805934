import React, { useState, useEffect, useMemo } from 'react'
import { useFirestore, User } from 'app/firebase'
import {
  PaymentType,
  paymentTypes,
  ProductType,
} from 'app/firebase/functions/stripe.functions'
import { FormModal, Input, Option, Select } from 'app/shared/components'
import { getUrl } from 'app/shared/components/Stripe/StripePaymentButton'
import { formatDate } from 'app/stlswing/helpers/date.helpers'
import { UserSelect } from 'app/users/components/UserSelect'
import { addMonths } from 'date-fns'
import { propOr } from 'ramda'
import { useForm } from 'react-hook-form'
import { Order } from '../finance.types'
import { Event, GroupClass } from 'app/stlswing/stlswing.types'
import { getFirestoreCollection } from 'app/firebase/firestore/firestore.helpers'

type Props = {
  isOpen: boolean
  onClose: () => void
  order?: Order
}

export const PaymentForm = (props: Props) => {
  const form = useForm()
  const { createWithId, update } = useFirestore('orders')
  const { list: events } = useFirestore('events')
  const { list: groupClasses } = useFirestore('groupClasses')
  const { map: usersMap } = useFirestore('users')

  const [user, setUser] = useState<User<true>>()
  const [products, setProducts] = useState<Event<true>[] | GroupClass<true>[]>(
    [],
  )

  useEffect(() => {
    if (!props.order || !usersMap) return

    setUser(usersMap[props.order.internalUserId])

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.order?.internalUserId])

  // const getTotal = (type: ProductType, id: string) => {
  //   const product =
  //     type === 'events'
  //       ? eventsMap && eventsMap[id]
  //       : type === 'groupClasses'
  //       ? groupClassesMap && groupClassesMap[id]
  //       : undefined

  //   if (type === 'events') {
  //     const event = product as Event<true>
  //     if (!event) return undefined
  //     if (event.type === 'Monthly') return 15
  //     if (event.type === 'Weekly') return 10
  //   }

  //   if (type === 'groupClasses') {
  //     const groupClass = product as GroupClass<true>
  //     if (!groupClass) return undefined
  //     return groupClass.dates.length * 25
  //   }
  // }

  // const getTotalWithDiscounts = (
  //   type: ProductType,
  //   id: string,
  //   user?: User<true>,
  // ) => {
  //   const total = getTotal(type, id)
  //   if (!total || !user) return
  //   if (!user.studentDiscount) return total
  //   return total / 2
  // }

  const onSubmit = (data: {
    date: string
    internalPaymentType: PaymentType
    internalProductId: string
    internalProductType: ProductType
    amount: string
    quantity: string
    credits: string
  }) => {
    if (!user) return
    const date = new Date(data.date).toISOString()

    const toSubmit: Order = {
      discounts: user.studentDiscount ? ['student'] : [],
      date: date,
      internalUserId: user.id,
      stripeCustomerId: user.stripeId,
      amount: Number(data.amount),
      quantity: Number(data.quantity),
      internalProductType: data.internalProductType,
      internalPaymentType: data.internalPaymentType,
      internalProductId: data.internalProductId,
      internalProductUrl:
        getUrl(data.internalProductType) + '/' + data.internalProductId,
      credits: Number(data.credits),
      stripePriceId: props.order?.stripePriceId || '',
    }
    // console.log('order', props.order)
    // console.log('submit', toSubmit)
    if (!props.order) createWithId(toSubmit)
    if (props.order && props.order.id) update(props.order.id, toSubmit)
    // console.log(toSubmit)
    // }
  }

  return (
    <FormModal
      type='right-panel'
      title={'Create Payment'}
      form={form}
      className='sm:w-1/2 xl:w-1/3 p-4'
      isOpen={props.isOpen}
      onClose={props.onClose}
      onSubmit={form.handleSubmit(onSubmit)}>
      <UserSelect selectUser={setUser} userId={props.order?.internalUserId} />
      <div className='flex'>
        <Select
          form={form}
          name='internalProductType'
          label='Product Type'
          onSelect={(productType) => {
            if (!groupClasses || !events) return
            if (productType === 'groupClasses') return setProducts(groupClasses)
            if (productType === 'events') return setProducts(events)
          }}
          search
          required
          defaultValues={
            props.order ? [props.order.internalProductType] : ['groupClasses']
          }>
          {['groupClasses', 'events', 'privateLessons'].map((type) => (
            <Option label={type} value={type} />
          ))}
        </Select>

        <Select
          form={form}
          name='internalPaymentType'
          label='Payment Type'
          search
          required
          defaultValues={
            props.order ? [props.order.internalPaymentType] : ['Full Class']
          }>
          {paymentTypes.map((type) => (
            <Option label={type} value={type} />
          ))}
        </Select>
      </div>
      <Select
        form={form}
        name='internalProductId'
        label='Product Ordered'
        search
        onSelect={() => {}}
        defaultValues={props.order ? [props.order.internalProductId] : []}>
        {[...(groupClasses || []), ...(events || [])].map((product) => (
          <Option label={product.name} value={product.id} key={product.id}>
            <div className='flex flex-col justify-start items-start'>
              <p>{product.name}</p>

              <div className='flex justify-evenly space-x-2'>
                <p className='text-gray-400 italic'>
                  {formatDate('month', getStartDate(product))}
                </p>
                <p className='text-gray-400 italic'>|</p>
                <p className='text-gray-400 italic'>
                  {isGroupClass(product) &&
                    'Starting ' +
                      formatDate('condensed-readable', getStartDate(product)) +
                      ', Ending ' +
                      formatDate(
                        'condensed-readable',
                        product.dates[product.dates.length - 1],
                      )}
                  {isEvent(product) &&
                    (product.type === 'Monthly'
                      ? formatDate('month', getStartDate(product)) +
                        ' Monthly Event'
                      : 'Wed Night Swing - ' +
                        formatDate(
                          'condensed-readable',
                          getStartDate(product),
                        ))}
                </p>
              </div>
            </div>
            {/*  */}
          </Option>
        ))}
      </Select>

      <div className='flex'>
        <Input
          name='quantity'
          label='Quantity'
          type='number'
          required
          form={form}
          defaultValue={props.order ? props.order.quantity.toString() : '1'}
        />
        <Input
          name='amount'
          label='Cost Amount'
          type='number'
          required
          form={form}
          defaultValue={props.order ? props.order.amount.toString() : '0'}
        />
      </div>
      <Input
        name='credits'
        label='Credits'
        type='number'
        required
        form={form}
        defaultValue={
          props.order?.credits ? props.order.credits.toString() : '0'
        }
      />
      <Input
        name='date'
        label='Date'
        type='date'
        required
        form={form}
        defaultValue={
          props.order ? formatDate('form', new Date(props.order.date)) : ''
        }
      />
    </FormModal>
  )
}

const getStartDate = (product: Event | GroupClass) => {
  if (isGroupClass(product)) return new Date(product.dates[0])
  if (isEvent(product)) return new Date(product.dateTime)
  return new Date()
}

const isEvent = (product: Event | GroupClass): product is Event =>
  'dateTime' in product

const isGroupClass = (product: Event | GroupClass): product is GroupClass =>
  'dates' in product
