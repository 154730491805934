import React, { useEffect, useState } from 'react'
import { PageHeading } from 'app/layout/PageHeading'
import { GroupClassCard } from '../components/GroupClass/GroupClassCard'
import { useFirestore } from 'app/firebase'
import { take } from 'ramda'
import { useAnalytics } from 'app/shared/hooks/useAnalytics'
import { Loading } from 'app/shared/components/Loading/Loading'
import { addHours, isFuture } from 'date-fns'
import { Button, fuzzySearch, Modal, Search } from 'app/shared'
import { FaqModalContent, WhatItLooksLikePanel } from '.'
import { WhatIsSwingDancingPanel } from '../components/Home'
import { Panel } from 'app/shared/components/Layout/Panel/Panel'
import { TruncatedText } from '../components/Shared/TruncatedText'
import { InfoSection } from '../components/Shared/InfoSection'
import { Video } from 'app/shared/components/Media/Video'
import { ScheduleStep } from '../components/Shared/ScheduleStep'
import { Stepper } from 'app/shared/components/Stepper/Stepper'
import { Step } from 'app/shared/components/Stepper'

export const GiftsPage = () => {
  const [classesModalOpen, setClassesModalOpen] = useState(false)

  return (
    <div className='relative z-0'>
      <PageHeading
        title='Virtual Gift Cards'
        video='videos/battle-allskate.mp4'
      />
      <Panel bgColor='dark'>
        <div className='p-4'>
          <p>
            "My mom got me a 100 inch 8K flatscreen TV, but really all I wanted
            was some swing dance lessons. I hate my life!"
          </p>
          <i className='font-medium m-2 mb-8 text-gray-400'>
            - Abraham Lincoln
          </i>
          <p className='font-semibold text-xl mt-8'>
            Don't make the same mistake as Abe's mom. Give your friends and
            family the gift of swing dancing this Christmas.
          </p>
        </div>
      </Panel>
      <GiftCardSection />
      <TheProcessPanel />
      <WhatIsSwingDancingPanel />
      <WhatItLooksLikePanel />
      <GiftCardSection />
    </div>
  )
}

const TheProcessPanel = () => (
  <div className='w-full bg-gray-800 flex justify-center'>
    <div className='py-6 max-w-5xl flex flex-col text-white'>
      <h2 className='text-3xl font-extrabold text-center pb-4'>How It Works</h2>
      <ol className='text-lg flex flex-col gap-4 p-6'>
        <InfoSection title='Step 1' className='text-white' bgClass='text-white'>
          <p className='text-white text-lg'> Buy a virtual gift card</p>
        </InfoSection>
        <InfoSection title='Step 2'>
          <li className='text-white text-lg'>
            Take a screenshot of the gift card
          </li>
        </InfoSection>
        <InfoSection title='Step 3'>
          <li className='text-white text-lg'>
            Text an Image of the gift card to one lucky recipient on Christmas
          </li>
        </InfoSection>
      </ol>
    </div>
  </div>
)

export default GiftsPage

const HowItWorksPanel = () => (
  <Panel bgColor='light'>
    <h2 className='text-3xl font-extrabold text-center pb-4'>How It Works</h2>
    <ol className='max-w-5xl mx-auto'>
      <ScheduleStep time='Step 1' description='Get The Virtual Card' />
      <ScheduleStep time='Step 1' description='Get The Virtual Card' />
      <ScheduleStep time='Step 1' description='Get The Virtual Card' />
      <ScheduleStep time='Step 1' description='Get The Virtual Card' />
    </ol>
  </Panel>
)

type Props = {
  title: string
  description: string
  buttonText: string
  priceText: string
  onClick: () => void
}
const GiftCardSection = () => {
  const [classesModalOpen, setClassesModalOpen] = useState(false)

  // const [c, setC] = useState(true)
  const [error, setError] = useState(false)
  const [valid, setValid] = useState(false)

  return (
    <div className='w-screen bg-gray-200 py-6'>
      <Modal
        isOpen={classesModalOpen}
        onClose={() => setClassesModalOpen(false)}
        className='w-screen sm:w-1/2'
        type='left-panel'>
        <Stepper>
          <Step
            title='Choose A Class'
            disabled={false}
            completed={false}
            className=''
            onNext={() => console.log('test 3')}>
            <div className=''>Class 3</div>
            <div className=''>Class 3</div>
            <div className=''>Class 3</div>
            <div className=''>Class 3</div>
          </Step>

          <Step
            title='Choose Payment Option'
            disabled={false}
            completed={false}
            className=''
            onNext={() => console.log('test 3')}>
            <div className=''>Class 3</div>
            <div className=''>Class 3</div>
            <div className=''>Class 3</div>
            <div className=''>Class 3</div>
          </Step>

          <Step
            title='Confirm'
            disabled={false}
            className=''
            completed={false}
            // type="error"
            onNext={() => console.log('TEST 1')}>
            <div className=''>Class 4</div>
            <div className=''>Class 4</div>
            <div className=''>Class 4</div>
            <div className=''>Class 4</div>
          </Step>

          {/* <Step
            title='Confirm'
            className=''
            canContinue={true}
            completed={false}
            // type="error"
            onNext={() => console.log('TEST 1')}>
            <div className=''>Class 4</div>
            <div className=''>Class 4</div>
            <div className=''>Class 4</div>
            <div className=''>Class 4</div>
          </Step> */}
        </Stepper>
      </Modal>
      <h2 className='text-3xl font-extrabold text-center pb-4'>Gift Options</h2>
      <div className='grid sm:grid-cols-2 mx-auto max-w-5xl gap-10 p-4'>
        <GiftCard
          title='Group Class Series'
          priceText={'$25 / class'}
          description='Choose any one of our 4 January class series to offer as a gift.  We have classes for every experience level and all of our classes include a 2 hour social event.'
          buttonText='Pick A Class'
          onClick={() => setClassesModalOpen(true)}
        />

        <GiftCard
          priceText={'$15'}
          title='Live Music Dance Event'
          buttonText='Buy'
          description="Redeeming this gift card will allow someone entry into our Live Music Event on January 7.  They'll Experience the best swing dancing has to offer with a 1-hour intro lesson and 3 hours of music from a 7-piece live band"
          onClick={() => {}}
        />
      </div>
    </div>
  )
}

const GiftCard = (props: Props) => {
  const buttonClasses =
    'transition duration-all hover:bg-blue-500 hover:text-white bg-blue-300 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 relative inline-flex items-center justify-center flex-1 w-0 py-4 -mr-px text-sm font-medium leading-5 text-gray-700 transition duration-300 ease-in-out border-r border-black'

  return (
    <div className='col-span-1 bg-white rounded-lg shadow flex flex-col'>
      <div className='flex items-center w-full p-6 space-x-6 '>
        <p className='text-lg font-medium flex-1'>{props.title}</p>
        <p className='rounded-full border py-1 px-3 bg-blue-100'>
          {props.priceText}
        </p>
      </div>
      <div className='flex items-center justify-between w-full  px-6 pb-6 space-x-6 flex-1'>
        {props.description}
      </div>
      <div className={`border-t border-gray-200 `}>
        <div className='flex -mt-px rounded-b-lg overflow-hidden'>
          <div className='divide-x divide-blue-400 flex flex-1 w-0 border-gray-200 '>
            <button onClick={props.onClick} className={buttonClasses}>
              {props.buttonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
