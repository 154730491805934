import React, { useEffect, useMemo, useState } from 'react'

import { useFirestore, usePermissions } from 'app/firebase'
import { prop, add } from 'ramda'
import {
  AvatarHeading,
  Button,
  DataTable,
  TableSchema,
  useUI,
} from 'app/shared'
import { PaymentForm } from '../modals/OrdersForm'
import { Order } from '../finance.types'
import { IMAGES } from 'app/stlswing/stlswing.constants'
import {
  endOfMonth,
  isThisMonth,
  isThisWeek,
  isToday,
  isWithinInterval,
  startOfMonth,
  subMonths,
  subWeeks,
} from 'date-fns'
import { formatDate } from 'app/stlswing/helpers/date.helpers'
import { Stats } from '../components/Stats'
import { formatCurrency } from '../helpers'
import { useQuery } from 'react-query'
import { getFirestoreCollection } from 'app/firebase/firestore/firestore.helpers'
import { AdminMobileHeader } from 'app/stlswing/pages'

const getLatestOrders = async () => {
  return await getFirestoreCollection<Order>('orders', {
    where: [['date', '>', subMonths(new Date(), 1).toISOString()]],
  })
}

export const Orders = () => {
  const { collectionMap: usersMap, list: users } = useFirestore('users')
  const { remove } = useFirestore('orders')
  const { collectionMap: eventsMap } = useFirestore('events')
  const { collectionMap: groupClassesMap } = useFirestore('groupClasses')
  const { data: orders } = useQuery(
    ['ordersPage'],
    async () => await getLatestOrders(),
  )
  // const { list: privateLessons } = useFirestore('privateLessons')
  const [open, setOpen] = useState(false)
  const { hasAnyRole } = usePermissions()
  const [selectedOrder, setSelectedOrder] = useState<Order>()
  const { isMobile } = useUI()

  const getHeading = (orderData: Order) => {
    if (!usersMap) return null
    // console.log('users', list)
    const user = usersMap[orderData.internalUserId]
    return (
      <AvatarHeading
        image={user?.photoUrl || IMAGES.DEFAULT.person}
        subText={user?.email || 'no email found'}
        title={user?.displayName || 'no name found'}
      />
    )
  }

  const getProduct = (orderData: Order) => {
    const { internalProductType: type, internalProductId: id } = orderData
    if (type === 'events') return eventsMap ? eventsMap[id] : undefined
    if (type === 'groupClasses')
      return groupClassesMap ? groupClassesMap[id] : undefined
    return undefined
  }

  const getProductName = (orderData: Order) => {
    const product = getProduct(orderData)
    return product ? product.name : 'No Name'
  }

  type Columns = 'Amount' | 'User' | 'Product' | 'Type' | 'Edit' | 'Date'
  const orderTableSchema: TableSchema<Order, Columns> = {
    User: getHeading,
    Amount: prop('amount'),
    Date: (order) =>
      order?.date
        ? formatDate(
            isMobile ? 'condensed-readable' : 'readable-date-and-time',
            new Date(order.date),
          )
        : 'No Date Found',
    Product: getProductName,
    Type: prop('internalPaymentType'),
    Edit: (order) => (
      <div>
        <Button onClick={() => setSelectedOrder(order)}>Edit</Button>
        {order.id && (
          <Button variant='warn' onClick={() => order.id && remove(order.id)}>
            delete
          </Button>
        )}
      </div>
    ),
  }

  return (
    <div className=' w-full space-y-6 overflow-auto'>
      <AdminMobileHeader title={'Orders'}>
        <div className='p-4'>
          <Button onClick={() => setOpen((v) => !v)} variant='raised'>
            Add Order
          </Button>
        </div>
      </AdminMobileHeader>
      {/* <div className='flex items-center h-16 mb-8 space-x-10'>
        <h2 className='text-4xl font-extrabold text-gray-800'>Orders</h2>
      </div> */}

      {/* {hasAnyRole(['admin']) && (
        <Stats
          title='Revenue'
          stats={[
            {
              name: 'Total',
              stat: formatCurrency(
                orders?.map((o) => o.amount).reduce(add, 0) || 0,
              ),
            },
            {
              name: 'This Month',
              stat: formatCurrency(
                orders
                  ?.filter((o) => isThisMonth(new Date(o.date)))
                  .map((o) => o.amount)
                  .reduce(add, 0) || 0,
                //+  membersAmount * 120,
              ),
            },
            {
              name: 'Last Month',
              stat: formatCurrency(
                orders
                  ?.filter((o) =>
                    isWithinInterval(new Date(o.date), {
                      start: startOfMonth(subMonths(new Date(), 1)),
                      end: endOfMonth(subMonths(new Date(), 1)),
                    }),
                  )
                  .map((o) => o.amount)
                  .reduce(add, 0) || 0,
              ),
            },

            {
              name: 'Today',
              stat: formatCurrency(
                orders
                  ?.filter((o) => isToday(new Date(o.date)))
                  .map((o) => o.amount)
                  .reduce(add, 0) || 0,
              ),
            },
            {
              name: 'This Week',
              stat: formatCurrency(
                orders
                  ?.filter((o) => isThisWeek(new Date(o.date)))
                  .map((o) => o.amount)
                  .reduce(add, 0) || 0,
              ),
            },
            // {
            //   name: 'Previous Weeks In Month',
            //   stat: formatCurrency(
            //     orders
            //       ?.filter((o) =>
            //         isWithinInterval(new Date(o.date), {
            //           start: startOfMonth(new Date()),
            //           end: startOfWeek(new Date()),
            //         }),
            //       )
            //       .map((o) => o.amount)
            //       .reduce(add, 0) || 0,
            //   ),
            // },
          ]}
        />
      )} */}

      <DataTable<Order>
        tableSchema={orderTableSchema}
        recordsList={orders}
        defaultSort='date'
      />

      <PaymentForm isOpen={open} onClose={() => setOpen(false)} />
      <PaymentForm
        isOpen={!!selectedOrder}
        onClose={() => setSelectedOrder(undefined)}
        order={selectedOrder}
      />
    </div>
  )
}
