import { Group, useFirestore, usePermissions, User } from 'app/firebase'
import {
  Button,
  ExpansionPanel,
  Loading,
  Modal,
  Tabs,
  useRouter,
} from 'app/shared'
import {
  endOfPreviousDay,
  endOfPreviousMonth,
  startOfPreviousMonth,
  thisMonthInterval,
} from 'app/shared/utils/dates'
import {
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isAfter,
  isBefore,
  isThisMonth,
  isWithinInterval,
  startOfMonth,
  subDays,
  subWeeks,
} from 'date-fns'
import {
  prop,
  groupBy,
  flatten,
  uniq,
  values,
  any,
  uniqBy,
  props,
  product,
  omit,
} from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { Order } from '../components/Accounting/finance.types'
import { GroupClass } from '../components/GroupClass/groupClass.types'
import { QRCODES, TEACHERS_MAP } from '../stlswing.constants'
import { Event } from '../stlswing.types'
import { Image } from 'app/shared/components/Media/Image'
import { AdminMobileHeader } from './AdminPage'
import { formatDate } from '../helpers/date.helpers'
import { CalendarHeader } from 'app/shared/components/Calendar/components'
import { getGroupClassOrders } from '../helpers/ticket.helpers'
import { AttendeesTable } from '../components/Shared/AttendeesTable/AttendeesTable'
import { EventFormModal, GroupClassFormModal } from '../components'
import { createTypeReferenceDirectiveResolutionCache } from 'typescript'
import { useQuery } from 'react-query'

export type ProductInfo<T extends GroupClass | Event<true>> = Omit<
  T,
  'attendees'
> & {
  attendees: User<true>[]
  orders: Order[]
}

type EventInfo = ProductInfo<Event<true>>

export const DashboardPage = () => {
  const [month, setMonth] = useState(new Date())
  const { list: allGroupClasses } = useFirestore('groupClasses')
  const { list: allEvents } = useFirestore('events')
  const { list: orders } = useFirestore('orders')
  const { map: usersMap } = useFirestore('users')
  const [tabs, setTabs] = useState({
    classes: true,
    events: false,
  })

  const { redirectTo } = useRouter()

  const [classToEdit, setClassToEdit] = useState<GroupClass>()
  const [eventToEdit, setEventToEdit] = useState<EventInfo>()
  const [creatingClass, setCreatingClass] = useState(false)
  const [creatingEvent, setCreatingEvent] = useState(false)

  const { data: groupClassesInfo } = useQuery(
    [
      'groupClasses',
      allGroupClasses?.length,
      formatDate('month', month),
      orders?.length,
      usersMap,
    ],
    () => {
      const classesInMonth = allGroupClasses?.filter(classInMonth(month))
      if (!classesInMonth || !orders || !usersMap) return []
      return classesInMonth.map((groupClass) => {
        const groupClassInfo: ProductInfo<GroupClass> = {
          ...groupClass,
          attendees: getUsersForProduct(groupClass, orders, usersMap) || [],
          orders: orders.filter(
            (order) => order.internalProductId === groupClass.id,
          ),
        }
        return groupClassInfo
      })
    },
  )

  const { data: eventsInfo } = useQuery(
    [
      'events',
      allEvents?.length,
      formatDate('month', month),
      orders?.length,
      usersMap,
    ],
    () => {
      const eventsInMonth = allEvents?.filter(eventInMonth(month))
      if (!eventsInMonth || !orders || !usersMap) return []
      return eventsInMonth.map((event) => {
        const eventInfo: EventInfo = {
          ...event,
          attendees: getUsersForProduct(event, orders, usersMap) || [],
          orders: orders.filter(
            (order) => order.internalProductId === event.id,
          ),
        }
        return eventInfo
      })
    },
  )

  const groupClasses: GroupClass[] | undefined = useMemo(() => {
    return allGroupClasses?.filter(classInMonth(month))
  }, [allGroupClasses, month])

  const events: Event<true>[] | undefined = useMemo(() => {
    return allEvents?.filter(eventInMonth(month))
  }, [allGroupClasses, month])

  return (
    <div className=''>
      <AdminMobileHeader title='Dashboard'>
        <CalendarHeader date={month} setDate={setMonth}></CalendarHeader>
      </AdminMobileHeader>

      <div className='bg-white'>
        <ExpansionPanel title='Statistics' className='bg-white'>
          {groupClasses && orders && usersMap && events ? (
            <MonthlyRetentionData
              month={month}
              groupClasses={groupClasses}
              events={events}
              usersMap={usersMap}
              orders={orders}
            />
          ) : (
            <Loading />
          )}
          <div></div>
        </ExpansionPanel>
      </div>

      <div className='flex items-center justify-between sm:justify-center sm:space-x-10'>
        <div className='pl-4'>
          <Tabs<typeof tabs> tabs={tabs} setTabs={setTabs} />
        </div>
        <Button
          variant='raised'
          className='bg-white'
          onClick={() => setCreatingClass(true)}>
          Add Class
        </Button>
        <Button
          variant='raised'
          className='bg-white'
          onClick={() => setCreatingEvent(true)}>
          Add Event
        </Button>
      </div>

      {tabs['classes'] && (
        <>
          <div className='p-4 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-3 gap-5 mx-auto'>
            {groupClassesInfo?.map((groupClass) => (
              <ProductCard
                attendees={groupClass.attendees}
                product={groupClass}
                onEdit={() => {
                  setClassToEdit(groupClass)
                }}
                onViewAttendees={() => {
                  redirectTo(`/admin/attendees/groupClasses/${groupClass.id}`)
                }}
              />
            ))}
          </div>
        </>
      )}

      {tabs['events'] && (
        <div className='p-4 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-3 gap-5 mx-auto'>
          {eventsInfo?.map((event) => (
            <ProductCard
              product={event}
              attendees={event.attendees}
              onEdit={() => setEventToEdit(event)}
              onViewAttendees={() =>
                redirectTo(`/admin/attendees/events/${event.id}`)
              }
            />
          ))}
        </div>
      )}

      <EventFormModal
        closeForm={() => setCreatingEvent(false)}
        isOpen={creatingEvent}
        title='Create Event'
      />

      <GroupClassFormModal
        closeForm={() => setCreatingClass(false)}
        isOpen={creatingClass}
        title='Create Class'
      />

      <EventFormModal
        closeForm={() => setEventToEdit(undefined)}
        isOpen={!!eventToEdit}
        // @ts-ignore
        event={omit(['attendees', 'product'], eventToEdit)}
        title={`Edit ${eventToEdit?.name} - ${eventToEdit?.id.slice(0, 3)} `}
      />

      <GroupClassFormModal
        closeForm={() => setClassToEdit(undefined)}
        isOpen={!!classToEdit}
        groupClass={classToEdit}
        title={`Edit ${classToEdit?.name} - ${classToEdit?.id.slice(0, 3)} `}
      />
    </div>
  )
}

type MonthlyRetentionDataProps = {
  groupClasses: GroupClass[]
  events: Event<true>[]
  month: Date
  orders: Order[]
  usersMap: Record<string, User<true>>
}
const MonthlyRetentionData = ({
  groupClasses,
  events,
  month,
  orders,
  usersMap,
}: MonthlyRetentionDataProps) => {
  const { hasAnyRole } = usePermissions()
  const totalAttendees = useMemo(
    () =>
      groupClasses && orders && usersMap
        ? getTotalAttendees(groupClasses, orders, usersMap)
        : undefined,
    [groupClasses, orders, usersMap],
  )

  const totalOrders = useMemo(
    () =>
      groupClasses && orders
        ? getOrdersForProducts(groupClasses, orders)
        : undefined,
    [groupClasses, orders],
  )

  const totalEventAttendees =
    events && orders && usersMap
      ? getTotalAttendees(events, orders, usersMap)
      : undefined

  const totalEventOrders =
    events && orders ? getOrdersForProducts(events, orders) : undefined

  const classRevenue = getTotalRevenue(
    totalOrders || [],
    totalAttendees || [],
    month,
  )
  const eventRevenue = getTotalRevenue(
    totalEventOrders || [],
    totalEventAttendees || [],
    month,
  )

  const memberRevenue = isThisMonth(month)
    ? getTotalOldMembersAmount(totalOrders || [], totalAttendees || []) * 120
    : 0

  return (
    <div className='py-4 border-t divide-y'>
      {hasAnyRole(['admin']) && (
        <>
          <p className='p-2 text-gray-900'>
            <b>Total Revenue</b> - $
            {classRevenue + eventRevenue + memberRevenue}
          </p>

          <p className='p-2 text-gray-900'>
            <b>Class Revenue</b> - ${classRevenue}
          </p>
          <p className='p-2 text-gray-900'>
            <b>Event Revenue</b> - ${eventRevenue}
          </p>
          {isThisMonth(month) && (
            <p className='p-2 text-gray-900'>
              <b>Member Revenue</b> - ${memberRevenue}
            </p>
          )}
        </>
      )}

      <p className='p-2 text-gray-900'>
        <b>Total Sign Ups</b> - {totalAttendees?.length}
      </p>

      <p className='p-2 text-gray-900'>
        <b>Total Students</b> -{' '}
        {uniqBy((order) => order.internalUserId, totalOrders || []).length}{' '}
      </p>
      <RetentionInfo
        orders={totalOrders || []}
        groupClasses={groupClasses || []}
      />
      <p className='p-2 text-gray-900'>
        <b>Student Discounts</b> -{' '}
        {totalAttendees?.filter((user) => user.studentDiscount).length}
      </p>

      <p className='p-2 text-gray-900'>
        <b>New Members</b> -{' '}
        {
          totalOrders?.filter(
            (order) => order.internalPaymentType === 'Membership',
          ).length
        }
      </p>
      <p className='p-2 text-gray-900'>
        <b>Member Signups</b> -{' '}
        {
          totalOrders?.filter(
            (order) =>
              order.internalPaymentType === 'Membership' ||
              order.internalPaymentType === 'Member Signup',
          ).length
        }
      </p>
      <p className='p-2 text-gray-900'>
        <b>New Signups</b> -{' '}
        {
          totalOrders?.filter(
            (order) =>
              order.internalPaymentType === 'Full Class' ||
              order.internalPaymentType === 'First Half Of Class' ||
              order.internalPaymentType === 'Membership',
          ).length
        }
      </p>
      <p className='p-2 text-gray-900'>
        <b>Event Transfers</b> -{' '}
        {
          getTransfersFromEvent(
            month,
            [...groupClasses, ...events],
            orders,
            usersMap,
          )?.length
        }
      </p>
    </div>
  )
}

type ProductCardProps = {
  product: GroupClass | EventInfo
  onEdit: SetState<EventInfo | GroupClass>
  attendees?: User<true>[]
  onViewAttendees: SetState<EventInfo | GroupClass>
}
const ProductCard = ({
  product,
  onEdit,
  onViewAttendees,
  attendees,
}: ProductCardProps) => {
  // const { list: orders } = useFirestore('orders')
  // const { map: userMap } = useFirestore('users')
  // const [attendees, setAttendees] = useState<User[]>()

  // useEffect(() => {
  //   if (!orders || !userMap) return
  //   const productOrders = getProductOrders(product, orders)
  //   setAttendees(getUsersFromOrders(userMap, productOrders))
  // }, [orders, userMap, product])

  const getInstructorsFromProduct = () => {
    if (!('instructors' in product)) return false
    return product.instructors.map((id) => TEACHERS_MAP[id]?.name).join(' and ')
  }

  const getFirstDate = () => {
    if ('dates' in product)
      return formatDate('condensed-readable', product.dates[0])
    if ('dateTime' in product)
      return formatDate('condensed-readable', product.dateTime)
  }

  return (
    <div className='bg-white rounded-md border shadow-md'>
      <h2 className='flex items-center border-b py-2'>
        <div className='pl-4 flex flex-col grow truncate'>
          <span className='flex flex-1 items-center'>
            <h2 className='pb-1 text-xl font-semibold truncate'>
              {product.name}
            </h2>
            <p className='bg-green-200 sm:hidden text-green-800 rounded-full flex items-center h-1/3 justify-center text-sm px-2 ml-2'>
              {attendees && attendees.length}
            </p>
          </span>
          <p className='text-gray-400 text-sm'>
            {getInstructorsFromProduct()} - Begins {getFirstDate()}
          </p>
        </div>
        {/* <Image
          src={'qrcodes/' + QRCODES[product.qrCode]}
          fit='contain'
          alt='code for groupClass'
          className='aspect-h-9 w-10 h-10 p-1'
        /> */}
      </h2>
      <div className='hidden sm:flex sm:h-32 justify-center items-center border-b'>
        <p className='sm:text-3xl text-center'>
          {attendees ? `${attendees.length} Attendees` : '...loading'}
        </p>
      </div>

      <div className='grid grid-cols-2 divide-x divide-y'>
        <CardButton onClick={onViewAttendees}>View Attendees</CardButton>
        <CardButton onClick={onEdit}>Edit</CardButton>
      </div>
    </div>
  )
}

const RetentionInfo = ({
  orders,
  groupClasses,
}: {
  orders: Order[]
  groupClasses: GroupClass[]
}) => {
  const { hasAnyRole } = usePermissions()

  const nonMembers = useMemo(
    () =>
      orders?.filter(
        (o) =>
          o.internalPaymentType === 'First Half Of Class' ||
          o.internalPaymentType === 'Full Class',
      ),
    [orders],
  )
  const members = useMemo(
    () =>
      orders?.filter(
        (o) =>
          o.internalPaymentType === 'Member Signup' ||
          o.internalPaymentType === 'Membership',
      ),
    [orders],
  )

  const firstHalf = useMemo(
    () =>
      orders?.filter(
        (order) => order.internalPaymentType === 'First Half Of Class',
      ),
    [orders],
  )

  const secondHalf = useMemo(
    () =>
      orders?.filter(
        (order) =>
          order.internalPaymentType === 'Second Half Of Class' ||
          order.internalPaymentType === 'Remaining Classes',
      ),
    [orders],
  )

  const getPercentRetention = (first: number, second: number) =>
    ((second / first) * 100).toFixed(2)

  const getRetentionByClass = (groupClass: GroupClass) => {
    const firstAmount = firstHalf.filter(
      (o) => o.internalProductId === groupClass.id,
    ).length
    const secondAmount = secondHalf.filter(
      (o) => o.internalProductId === groupClass.id,
    ).length
    if (!firstAmount) return ''
    if (firstAmount < secondAmount) return ''
    // if (firstAmount < 2) return ''
    return `${groupClass.name} - ${groupClass.instructors
      .map((instructor) => TEACHERS_MAP[instructor].name)
      .join(
        ' and ',
      )} - First Half ${firstAmount} / Second Half ${secondAmount} - ${getPercentRetention(
      firstAmount,
      secondAmount,
    )}%`
  }

  if (!groupClasses || !orders) return null
  return (
    <>
      <p className='p-2 text-gray-900'>
        <b>Total Retention </b> - First Half: {firstHalf.length} / Second Half:{' '}
        {secondHalf.length} -{' '}
        {getPercentRetention(firstHalf.length, secondHalf.length)}%
      </p>
      {hasAnyRole(['admin']) &&
        groupClasses
          .filter((groupClass) => getRetentionByClass(groupClass) !== '')
          .map((groupClass) => (
            <p className='p-2 text-gray-900'>
              {getRetentionByClass(groupClass)}
            </p>
          ))}
    </>
  )
}

const CardButton = ({ text, children, onClick }: any) => (
  <button
    onClick={onClick}
    className='flex flex-1 p-4 sm:p-6 transform duration-150 hover:bg-gray-100 text-gray-600 items-center justify-center h-10 font-medium text-md'>
    {children}
  </button>
)

const getUsersForProduct = (
  product: GroupClass | Event<true>,
  orders: Order[],
  usersMap: Record<string, User<true>>,
): User<true>[] =>
  getUsersFromOrders(usersMap, getProductOrders(product, orders))

const getNewStudents = (
  date: Date,
  products: (GroupClass | Event<true>)[],
  orders: Order[],
  usersMap: Record<string, User<true>>,
) => {
  const thisMonthsClassOrders = uniqBy(
    prop('internalUserId'),
    orders
      .filter(
        ({ internalPaymentType: type, ...order }) =>
          (type === 'Full Class' ||
            type === 'First Half Of Class' ||
            type === 'Membership' ||
            type === 'Member Signup' ||
            type === 'Discounted Class') &&
          isWithinInterval(new Date(order.date), {
            start: startOfPreviousMonth(date),
            end: endOfMonth(date),
          }),
      )
      .filter((order) =>
        any((product) => product.id === order.internalProductId, products),
      ),
  )

  const allClassAttendees = getTotalAttendees(
    products,
    thisMonthsClassOrders,
    usersMap,
  )

  const transfers = allClassAttendees.map(({ id }) =>
    orders.filter((order) => order.internalUserId === id),
  )
  return transfers
}

const getTransfersFromEvent = (
  date: Date,
  products: (GroupClass | Event<true>)[],
  orders: Order[],
  usersMap: Record<string, User<true>>,
) => {
  const thisMonthsMonthlyEventOrders = orders.filter(
    (order) =>
      order.internalPaymentType === 'Monthly Event' &&
      isWithinInterval(new Date(order.date), {
        start: endOfPreviousDay(endOfWeek(startOfPreviousMonth(date))),
        end: endOfPreviousDay(endOfWeek(startOfMonth(date))),
      }),
  )

  const allMonthlyAttendees = getTotalAttendees(
    products,
    thisMonthsMonthlyEventOrders,
    usersMap,
  )

  const thisMonthsClassOrders = groupBy(
    prop('internalProductId'),
    orders.filter(
      ({ internalPaymentType: type, ...order }) =>
        (type === 'Full Class' ||
          type === 'First Half Of Class' ||
          type === 'Membership' ||
          type === 'Discounted Class') &&
        isWithinInterval(new Date(order.date), {
          start: subWeeks(endOfPreviousMonth(date), 1),
          end: addWeeks(date, 1),
        }),
    ),
  )

  const transfers = allMonthlyAttendees
    .map(({ id }) => orders.filter((order) => order.internalUserId === id))
    .filter((orders) =>
      orders.find((order) => thisMonthsClassOrders[order.internalProductId]),
    )
    .filter(
      (orders) =>
        !any(
          (order) =>
            order.internalPaymentType !== 'Monthly Event' &&
            isBefore(new Date(order.date), startOfMonth(date)),
          orders,
        ),
    )

  return transfers
}

const getTotalAttendees = (
  products: (GroupClass | Event<true>)[],
  orders: Order[],
  usersMap: Record<string, User<true>>,
) =>
  flatten(
    products.map((product) => getUsersForProduct(product, orders, usersMap)),
  )

const getProductOrders = (product: GroupClass | Event<true>, orders: Order[]) =>
  groupBy(prop('internalProductId'), orders)[product.id] || []

const filterForInitialOrders = (orders: Order[]) =>
  orders.filter((order) => {
    if (order.internalPaymentType === 'Second Half Of Class') return false
    if (order.internalPaymentType === 'Remaining Classes') return false
    if (order.internalPaymentType === 'Member Signup') return false
    return true
  })

const getOrdersForProducts = (
  products: (GroupClass | Event<true>)[],
  orders: Order[],
) => flatten(products.map((product) => getProductOrders(product, orders)))

const getUsersFromOrders = (
  userMap: Record<string, User<true>>,
  orders: Order[],
): User<true>[] =>
  orders
    .map(({ internalUserId }) => userMap[internalUserId])
    .filter((user) => !!user)

const classInMonth = (date: Date) => (groupClass: GroupClass) => {
  const dateAmount = groupClass.dates.length
  const datesInMonth = groupClass.dates
    .map((classDate) =>
      isWithinInterval(new Date(new Date(classDate)), thisMonthInterval(date)),
    )
    .filter((inMonth) => inMonth)

  if (dateAmount === 1) return datesInMonth.length > 0
  if (dateAmount > 2) return datesInMonth.length > 2
  // if (dateAmount === 2) return !!datesInMonth[datesInMonth.length - 1]
  // if (dateAmount > 2) return

  const firstInMonth = isWithinInterval(
    new Date(groupClass.dates[0]),
    thisMonthInterval(date),
  )
  const lastInMonth = isWithinInterval(
    new Date(groupClass.dates[groupClass.dates.length - 1]),
    thisMonthInterval(date),
  )
  return firstInMonth || lastInMonth
}

const eventInMonth = (date: Date) => (event: Event<true>) =>
  isWithinInterval(new Date(event.dateTime), thisMonthInterval(date))

// gets amount of people who were members at the start of the month
const getTotalOldMembersAmount = (totalOrders: Order[], allUsers: User[]) => {
  const newMembersAmount =
    totalOrders.filter((order) => order.internalPaymentType === 'Membership')
      .length || 0
  const totalMembersAmount = allUsers.filter((user) => user.member).length || 0
  return totalMembersAmount - newMembersAmount
}

// const getTotalRevenueFromEvents = (totalOrders: Order[], )

const getTotalRevenue = (
  totalOrders: Order[],
  totalAttendees: User[],
  month: Date,
) => {
  const newRevenue =
    totalOrders?.reduce((acc, curr) => curr.amount + acc, 0) || 0

  // return newRevenue + memberRevenue
  return newRevenue
}

export default DashboardPage
