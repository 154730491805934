import React from 'react'
import { CheckboxSolidIcon } from 'app/shared/components/Icons/CheckboxSolidIcon'

type Props = {
  time: string
  description: string
  last?: boolean
}
export const ScheduleStep = ({ time, description, last }: Props) => (
  <li>
    <div className='group relative flex items-start'>
      {!last && (
        <div
          className='-ml-px absolute mt-8 top-4 left-6 w-0.5 h-full bg-indigo-600'
          aria-hidden='true'
        />
      )}
      <span className={`h-20 flex items-center`}>
        <span className='relative z-10 flex items-center justify-center w-12 h-12 bg-indigo-600 rounded-full'>
          <CheckboxSolidIcon />
        </span>
      </span>
      <span className={`ml-4 mt-4 min-w-0 flex flex-col`}>
        <span className='text-md font-semibold tracking-wide uppercase'>
          {time}pm
        </span>
        <span className='text-sm text-gray-500'>{description}</span>
      </span>
    </div>
  </li>
)
