import React, { useContext, useState } from 'react'
import { MyEvents } from 'app/stlswing/components/Events/MyEvents'
import { MyClasses } from 'app/stlswing/components/GroupClass/MyClasses'
import { CurrentUserContext } from 'app/firebase'
import { SignInModal } from 'app/auth'
import {
  useToggle,
  useRouter,
  usePermissions,
  Modal,
  Button,
  Loading,
} from 'app/shared'
import {
  ClassesMenu,
  MoreMenu,
  AdminMenu,
  ProfileMenu,
  ViewAsMenu,
  HeaderLink,
  OpenSidebarButton,
} from './header'
import { UIContext } from 'app/shared/context/UIContext'

export const DesktopHeader = () => {
  const { hasRole, hasClearance, hasAnyRole } = usePermissions()
  const { showHeader } = useContext(UIContext)
  const { navigateTo } = useRouter()
  // prettier-ignore
  const { state: classesOpen, setFalse: closeClasses, setTrue: openClasses } = useToggle(false)
  // prettier-ignore
  const { state: eventsOpen, setFalse: closeEvents, setTrue: openEvents } = useToggle(false)

  const { userLoading } = useContext(CurrentUserContext)
  const [loginVisible, setLoginVisible] = useState(false)
  const { isLoggedIn } = usePermissions()

  return (
    <>
      {showHeader && (
        <div className='sm:justify-center sm:w-full flex bg-transparent'>
          <div className='absolute z-30 flex justify-between flex-shrink-0 w-full h-16'>
            {/* <OpenSidebarButton toggleSidebar={toggleSidebar} /> */}
            {/* <CurrentEvent isOpen={isOpen} onClose={() => setIsOpen(false)} /> */}

            {/* Header Links  */}
            <div className='sm:flex justify-center hidden w-full'>
              <HeaderLink route='/'>Home</HeaderLink>

              {/* <HeaderLink route='/'>Home</HeaderLink> */}
              <HeaderLink route='/swing-dance-events'>Events</HeaderLink>

              <HeaderLink route='/calendar'>Calendar</HeaderLink>

              <ClassesMenu />

              <MoreMenu />

              {hasClearance(3) && <ViewAsMenu />}

              {!isLoggedIn() ? (
                <div className='flex items-center mr-3'>
                  {!userLoading && (
                    <Button
                      variant='raised'
                      onClick={() => setLoginVisible((v) => !v)}>
                      Login
                    </Button>
                  )}
                </div>
              ) : (
                <ProfileMenu
                  openClasses={openClasses}
                  openEvents={openEvents}
                />
              )}

              {(hasRole('admin') || hasRole('employee')) && (
                <HeaderLink route='/admin'>
                  <p className='text-gray-800 border uppercase border-white rounded-md p-2 bg-sky-50'>
                    Admin
                  </p>
                </HeaderLink>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Sign In Modal */}
      <SignInModal
        title='Log In'
        isOpen={loginVisible}
        onClose={() => setLoginVisible(false)}
      />

      <Modal
        title='My Classes'
        type='left-panel'
        isOpen={classesOpen}
        className={`w-screen ${
          hasAnyRole(['employee', 'admin']) ? '' : 'sm:w-1/2'
        } text-gray-800 overflow-y-auto`}
        onClose={closeClasses}>
        <MyClasses onClose={closeClasses} />
      </Modal>
      <Modal
        title='My Events'
        type='left-panel'
        isOpen={eventsOpen}
        className='sm:w-1/2 w-screen text-gray-800 overflow-y-auto'
        onClose={closeEvents}>
        <MyEvents onClose={closeEvents} />
      </Modal>
    </>
  )
}
