import {
  faChevronDown,
  faChevronUp,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useUI } from 'app/shared'
import { searchObject } from 'app/shared/utils'
import { addOrRemove } from 'app/shared/utils/arrays'
import {
  includes,
  pick,
  pipe,
  propOr,
  pathOr,
  __,
  isEmpty,
  reverse,
  find,
  propEq,
  prop,
  any,
  equals,
} from 'ramda'
import React, { Children } from 'react'
import { Menu } from '../../../Menu'
import { Option } from '../../Option/Option'
import { Search } from '../../Search'
import { Selection } from '../Select'

type Props = {
  searchTerm: string
  selections: Selection<any>[]
  handleSelect: (selections: Selection<any>) => void
}

export const OptionsList = ({
  searchTerm,
  selections,
  handleSelect,
  ...props
}: C<Props>) => {
  return (
    <>
      {Children.map(props.children, ({ props }, i) =>
        searchObject({ v: props.value, l: props.label }, searchTerm) ? (
          <Option
            key={i}
            selected={!!find(propEq('value', props.value), selections)}
            onClick={() =>
              handleSelect({ value: props.value, label: props.label })
            }
            {...props}
            role={props.multiple ? 'checkbox' : 'radio'}
          />
        ) : null,
      )}
    </>
  )
}
