import { User } from 'app/firebase'
import { PaymentType } from 'app/firebase/functions/stripe.functions'
import { Order } from 'app/stlswing/components/Accounting/finance.types'
import { isEmpty } from 'ramda'

export type AttendeeTableFilters = {
  position: User['position'][]
  experience: User['experience'][]
  userType: ('member' | 'discounted' | 'normal')[]
  orderType: Order['internalPaymentType'][]
}

export const filterAttendeePredicate = (
  attendee: User<true>,
  filters: AttendeeTableFilters,
  userOrder?: Order,
) => {
  const { position, experience, userType, orderType } = filters
  // Filter User Position
  if (position.includes(attendee.position)) return true

  // Filter User Experience
  if (experience.includes(attendee.experience)) return true

  // Filter By Member/Student Discounted/Normal
  if (
    userType.includes('normal') &&
    !attendee.member &&
    !attendee.studentDiscount
  )
    return true
  if (userType.includes('member') && attendee.member) return true
  if (userType.includes('discounted') && attendee.studentDiscount) return true

  // Filter By User Payment Type
  const checkType = (type: PaymentType) =>
    orderType.includes(type) && userOrder?.internalPaymentType === type
  if (checkType('Full Class')) return true
  if (checkType('First Half Of Class')) return true
  if (checkType('Second Half Of Class')) return true
  if (checkType('A La Carte')) return true
  return false
}

export const filterAttendees = (
  users: User<true>[],
  attendee: User<true>,
  filters: AttendeeTableFilters,
  userOrder: Order,
) => {
  const { position, experience, userType, orderType } = filters
  users.filter((user) => {
    // Filter User Position
    if (position.includes(user.position)) return true

    // Filter User Experience
    if (experience.includes(user.experience)) return true

    // Filter By Member/Student Discounted/Normal
    if (userType.includes('normal') && !user.member && !user.studentDiscount)
      return true
    if (userType.includes('member') && user.member) return true
    if (userType.includes('discounted') && user.studentDiscount) return true

    // Filter By User Payment Type
    const checkType = (type: PaymentType) =>
      orderType.includes(type) && userOrder.internalPaymentType === type
    if (checkType('Full Class')) return true
    if (checkType('First Half Of Class')) return true
    if (checkType('Second Half Of Class')) return true
    if (checkType('A La Carte')) return true
    return false
  })
}
