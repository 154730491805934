import React from 'react'
import { Button } from 'app/shared'
import { formatDate } from 'app/stlswing/helpers/date.helpers'
import { compareAsc } from 'date-fns'
import DateTimePicker from 'react-datetime-picker'
import { CloseIcon } from '../../Icons/CloseIcon'

type Props = {
  dates: Date[]
  setDates: SetState<Date[]>
  addDateTransform?: (prevDates: Date[]) => Date[]
}

export const MultipleDatePicker = ({
  addDateTransform,
  setDates,
  dates,
  ...props
}: C<Props>) => {
  const updateDate = (dates: Date[], newDate: Date, i: number) =>
    dates.map((date, index) => (index === i ? newDate : date))

  const deleteDate = (dates: Date[], i: number) =>
    dates.reduce((acc, date, index) => {
      if (index === i) return acc
      return [...acc, date]
    }, [] as Date[])

  const addNewDate = () => {
    if (!addDateTransform) {
      return setDates((prev) => [...prev, new Date()])
    }
    setDates((prev) => addDateTransform(prev))
  }

  return (
    <div className={`${props.className} space-y-3`}>
      {dates.sort(compareAsc).map((date, i) => (
        <div>
          <div className='sm:flex items-center space-x-1'>
            <p className='text-md w-28 pb-1 font-medium'>
              {formatDate('condensed-readable', date)}
            </p>
            <DateTimePicker
              onChange={(dateToChange: Date) => {
                setDates((prevDates) => updateDate(prevDates, dateToChange, i))
              }}
              value={date}
            />
            {(i !== 0 || dates.length !== 1) && (
              <button
                type='button'
                onClick={() =>
                  setDates((prevDates) => deleteDate(prevDates, i))
                }>
                <CloseIcon className='text-red-600 border-red-800 rounded-full' />
              </button>
            )}
          </div>
        </div>
      ))}

      <Button variant='raised' type='button' onClick={addNewDate}>
        Add Date
      </Button>
    </div>
  )
}
