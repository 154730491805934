import React, { useContext } from 'react'
import {
  Button,
  CurrentUserContext,
  usePermissions,
  useToggle,
  useFirestore,
  useRouter,
} from 'app/shared'
import { StickyHeader } from 'app/shared/components/Layout/Heading/StickyHeading'
import { getUrl } from 'app/shared/components/Stripe/StripePaymentButton'
import { formatDate } from 'app/stlswing/helpers/date.helpers'
import { useUsers } from 'app/stlswing/hooks'
import { PRICE_IDS } from 'app/stlswing/stlswing.constants'
import { GroupClass } from 'app/stlswing/stlswing.types'
import { TicketModal } from '../Shared/TicketModal'
import { GroupClassSignupModal } from './components/GroupClassSignup/GroupClassSignupModal'

type Props = {
  groupClass: GroupClass<true>
}
export const GroupClassPageHeading = ({ groupClass }: Props) => {
  const { user } = useContext(CurrentUserContext)
  const { hasAnyRole } = usePermissions()
  const { userHasGroupClass } = useUsers()
  // prettier-ignore
  const { state: codeOpen, setFalse: closeCode, setTrue: openCode } = useToggle()
  //prettier-ignore
  const { toggle: toggleSignIn, setFalse: closeSignin, state: signInOpen } = useToggle()
  const { createWithId: createOrderWithId } = useFirestore('orders')
  const { navigateTo } = useRouter()

  const onClickSignUp = () => {
    if (user && user.member) {
      createOrderWithId({
        date: new Date().toISOString(),
        amount: 0,
        credits: groupClass.dates.length,
        internalPaymentType: 'Member Signup',
        internalProductType: 'groupClasses',
        internalUserId: user.id,
        stripeCustomerId: user.stripeId,
        quantity: 1,
        internalProductId: groupClass.id,
        // @ts-ignore
        stripePriceId: PRICE_IDS.PROD.GROUP_CLASS[groupClass.dates.length],
        internalProductUrl: getUrl('groupClasses') + '/' + groupClass.id,
        discounts: [],
      })
      navigateTo('success/' + groupClass.id)
      return
    }

    toggleSignIn()
  }

  if (!groupClass) return null
  if (hasAnyRole(['employee', 'admin']))
    return (
      <StickyHeader>
        <div className='place-items-center sm:py-8 sm:px-1/4 container grid grid-cols-2 px-4 py-4 space-x-2'>
          <Button variant='raised' onClick={openCode}>
            Show Ticket
          </Button>
          {/* <GroupClassChat groupClass={groupClass} /> */}
        </div>
        <TicketModal
          isOpen={codeOpen}
          name={groupClass.name}
          product={groupClass}
          qrcode={groupClass.qrCode}
          close={closeCode}
        />
      </StickyHeader>
    )

  if (userHasGroupClass(groupClass.id))
    return (
      <StickyHeader>
        <div className='sm:grid-cols-2 place-items-center sm:py-8 sm:px-1/4 container grid px-4 py-4 space-x-2'>
          <Button variant='raised' onClick={openCode}>
            Show Ticket
          </Button>
        </div>
        <TicketModal
          isOpen={codeOpen}
          name={groupClass.name}
          product={groupClass}
          qrcode={groupClass.qrCode}
          close={closeCode}
        />
      </StickyHeader>
    )

  return (
    <StickyHeader>
      <div className='sm:grid-cols-3 place-items-center sm:space-x-10 sm:py-8 sm:px-1/5 container grid grid-cols-1 px-4 py-4 space-y-2'>
        <h3 className='flex justify-center w-full text-xl font-semibold text-black'>
          <span>
            {/* {formatDate('readable-date-and-time', groupClass.dates[0])} */}
            {formatDate('day', groupClass.dates[0]) +
              ' at ' +
              formatDate('time', groupClass.dates[0])}
          </span>
        </h3>

        <Button
          variant='raised'
          className='w-full'
          onClick={onClickSignUp}
          data-testid='group-class-signup-button'>
          Sign Up
        </Button>
        <h3 className='flex justify-center w-full text-xl font-semibold text-black'>
          {groupClass.dates.length} Classes
        </h3>
      </div>

      <GroupClassSignupModal
        groupClass={groupClass}
        isVisible={signInOpen}
        onClose={closeSignin}
      />
      <TicketModal
        product={groupClass}
        isOpen={codeOpen}
        name={groupClass.name}
        qrcode={groupClass.qrCode}
        close={closeCode}
      />
    </StickyHeader>
  )
}
