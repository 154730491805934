import React, { useState, useEffect } from 'react'
import { animated, useTransition } from 'react-spring'
import { indexOf, take, map, head, tail, equals, any } from 'lodash/fp'
import { capitalize } from 'lodash/fp'
import { sentenceCase } from 'change-case'
import { useHistory, useLocation } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { useRouter } from 'app/shared/hooks'

type BreadCrumb = {
  title: string
  route?: string
}

type Props = {
  breadcrumbs: BreadCrumb[]
  className?: string
  children?: React.ReactNode[]
}
export const Breadcrumbs = React.memo((props: Props) => {
  const history = useHistory()
  const { redirectTo } = useRouter()

  const baseTitleClasses =
    'text-sm font-medium sm:w-full text-gray-800 p-2 text-2xl font-semibold '

  return (
    <ol className='flex items-center'>
      {props.breadcrumbs.map((breadcrumb, i) => (
        <animated.li key={i}>
          <div className='flex items-center truncate space-x-1'>
            {breadcrumb.title !== props.breadcrumbs[0].title && (
              <FontAwesomeIcon icon={faChevronRight} />
            )}

            {!breadcrumb.route && (
              <div className={`${baseTitleClasses}`}>
                <h2 className='text-xl sm:text-4xl'>{breadcrumb.title}</h2>
              </div>
            )}

            {breadcrumb.route && (
              <button
                onClick={() => breadcrumb.route && redirectTo(breadcrumb.route)}
                className={`${baseTitleClasses} text-blue-500  hover:text-blue-700 hover:border hover:border-b`}>
                <h2 className='text-xl sm:text-4xl truncate max-w-sm'>
                  {breadcrumb.title}
                </h2>
              </button>
            )}
          </div>
        </animated.li>
      ))}
    </ol>
  )
})
