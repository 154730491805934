import { useFirestore } from 'app/firebase'
import {
  Input,
  Select,
  Option,
  MultipleDatePicker,
  Slider,
} from 'app/shared/components'
import { FormModal } from 'app/shared/components/Modal'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Event } from '../../stlswing.types'
import { CLASS_VIDEOS, HOURS, QRCODES } from '../../stlswing.constants'
import { Textarea } from 'app/shared/components/Field/Input/Textarea'
import { format } from 'date-fns'
import { formatDate } from 'app/stlswing/helpers/date.helpers'

type Props = {
  title: string
  event?: Event<true>
  closeForm: () => void
  isOpen: boolean
}

export const EventFormModal = ({ event, ...props }: Props) => {
  const { update, createWithId } = useFirestore('events')
  const [dates, setDates] = useState<Date[]>([])

  const form = useForm()

  useEffect(() => {
    if (event) return
    setDates([new Date()])
  }, [])

  useEffect(() => {
    if (!event) return
    setDates([new Date(event.dateTime)])
  }, [event])

  type FormData = Omit<Event, 'id'>
  const onSubmit = async (formData: FormData) => {
    dates.forEach(async (date) => {
      const updatedEvent = {
        ...formData,
        video: 'skye-frida-swingouts.mp4',
        dateTime: date.toISOString(),
      }
      if (!event) await createWithId(updatedEvent)

      // if command exits, update current command
      if (event) await update(event.id, updatedEvent)
    })

    props.closeForm()
  }

  return (
    <FormModal
      title={props.title}
      onSubmit={form.handleSubmit<FormData>(onSubmit)}
      form={form}
      isOpen={props.isOpen}
      onClose={props.closeForm}
      className='sm:w-1/2 w-screen text-black'
      type='right-panel'>
      <div className='p-4'>
        <Input
          form={form}
          label='Name'
          name='name'
          required
          defaultValue={event ? event.name : ''}
        />

        <p className='pt-4 px-4'>
          <MultipleDatePicker dates={dates || []} setDates={setDates} />
        </p>

        <div className='flex space-x-1'>
          <Select
            form={form}
            label='type'
            name='type'
            required
            defaultValues={[event ? event.type : 'Weekly']}>
            <Option label='Weekly' value='Weekly' key={1} />
            <Option label='Monthly' value='Monthly' key={2} />
            <Option
              label='Harmonizers Dance'
              value='Harmonizers Dance'
              key={2}
            />
          </Select>
        </div>

        <Textarea
          form={form}
          label='Description'
          name='description'
          required
          defaultValue={event ? event.description : ''}
        />

        <Slider
          name='draft'
          form={form}
          defaultValue={event?.draft || false}
          label='Draft State'
          description='classes with this toggled will not display pubilcly on the website.'
        />
      </div>
    </FormModal>
  )
}
