import React from 'react'

export const MenuIcon = ({ className }: C<{}>) => (
  <svg
    className={`w-7 h-7 ${className} text-black`}
    stroke='black'
    fill='none'
    viewBox='0 0 24 24'>
    <path
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='2'
      d='M4 6h16M4 12h16M4 18h7'
    />
  </svg>
)
