import React from 'react'

type Props = {
  description: string
  title?: string
  background?: 'dark' | 'light'
}

export const DescriptionSection = ({
  description,
  title,
  background,
  children,
}: C<Props>) => {
  const classes =
    background === 'light'
      ? 'bg-gray-300 text-gray-800'
      : 'bg-gray-800 text-white'

  const text = background === 'light' ? 'text-gray-800' : 'text-gray-200'

  return (
    <div className={`w-full ${classes}`}>
      <section className='container max-w-3xl px-4 py-16 mx-auto'>
        <h2
          className={
            'text-3xl font-extrabold tracking-tight text-center md:text-4xl lg:text-5xl'
          }>
          {title || 'Description'}
        </h2>
        <p className={'mt-6 text-xl ' + text}>
          {description}
          {children}
        </p>
      </section>
    </div>
  )
}
