import {
  addWeeks,
  eachWeekOfInterval,
  endOfMonth,
  startOfMonth,
  format,
  addDays,
} from 'date-fns'
import { GroupClass, GroupClassLevels } from '../stlswing.types'
import { ContentCategory } from 'app/shared/hooks/useAnalytics'
import { PRICE_IDS } from '../stlswing.constants'
import { ENV } from 'environments'
import { tail } from 'ramda'

// type Days = 'Mon' | 'Tue' | 'Wed' | 'Thur' | 'Fri' | 'Sat' | 'Sun'

export const getClassDates = (startDate: Date, classAmount: number) =>
  tail(
    eachWeekOfInterval({
      start: startDate,
      end: addWeeks(startDate, classAmount + 1),
    }).map((w) => getCorrectDayFromWeek(w, format(startDate, 'EEE'))),
  )

export const getWeeksOfMonth = (date: Date) =>
  eachWeekOfInterval({ start: startOfMonth(date), end: endOfMonth(date) })

export const getCorrectDayFromWeek = (
  startOfWeek: Date,
  dayOfWeekToGet: string,
) => {
  // follow the getISODay format (7 for Sunday, 1 for Monday)
  const dayOfWeekMap: Record<string, number> = {
    Mon: 1,
    Tue: 2,
    Wed: 3,
    Thur: 4,
    Fri: 5,
    Sat: 6,
    Sun: 7,
  }
  return addDays(startOfWeek, dayOfWeekMap[dayOfWeekToGet] + 1)
}

export const getCategoryFromLevel = (
  level: GroupClassLevels,
): ContentCategory => {
  if (level === 'Beginner') return 'beginner-class'
  if (level === 'Intermediate') return 'intermediate-class'
  if (level === 'Advanced') return 'advanced-class'
  return 'group-class'
}

export const getClassPriceID = (amountOfDates: number) =>
  // @ts-ignore
  PRICE_IDS[ENV].GROUP_CLASS[amountOfDates]

// export const getClassPriceID = (classAmount: number) =>
//   classAmount === 2
//     ? STRIPE_PRICE_IDS[ENV].CLASS_2_WEEKS
//     : classAmount === 4
//     ? STRIPE_PRICE_IDS[ENV].CLASS_4_WEEKS
//     : classAmount === 6
//     ? STRIPE_PRICE_IDS[ENV].CLASS_6_WEEKS
//     : classAmount === 8
//     ? STRIPE_PRICE_IDS[ENV].CLASS_8_WEEKS
//     : STRIPE_PRICE_IDS[ENV].CLASS_2_WEEKS

export const getGroupClassPrice = (
  groupClass: GroupClass,
  studentDiscount?: boolean,
) => {
  const price = groupClass.dates.length * 25
  if (studentDiscount) return price / 2
  return price
}
