import React, { useMemo } from 'react'
import { useFirestore, Select, Option } from 'app/shared'
import { useForm } from 'react-hook-form'
import { anyPass, equals } from 'ramda'
import { useGetAdmins, useGetTeachers } from 'app/stlswing/hooks'

type Props = {
  form: ReturnType<typeof useForm>
  teacherIds?: string[]
}

export const TeacherSelect = ({ form, teacherIds }: Props) => {
  const teachers = useGetTeachers()
  const admins = useGetAdmins()

  return (
    <Select
      data-testid='teacher-select'
      form={form}
      multiple
      search
      label='Instructors'
      name='instructors'
      defaultValues={teacherIds ? teacherIds : []}
      required>
      {[...teachers, ...admins].map((instructor) => (
        <Option
          key={instructor.id}
          label={instructor.displayName}
          value={instructor.id}
        />
      ))}
    </Select>
  )
}
