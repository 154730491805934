import React from 'react'

export const MoneyIcon = ({ className }: C<{}>) => (
  <svg
    className={`mr-3 h-6 w-6 text-gray-400 ${className}`}
    xmlns='http://www.w3.org/2000/svg'
    // xmlns:xlink='http://www.w3.org/1999/xlink'
    x='0px'
    y='0px'
    viewBox='0 0 490.35 490.35'
    // style='enable-background:new 0 0 490.35 490.35;'
    // xml:space='preserve'
  >
    <path
      d='M475.462,163.15l-279.8-161.5c-3.8-2.2-10.1-2.2-13.9,0l-166.8,96.9c-3.8,2.2-3.8,5.8,0,8.1l279.8,161.5
		c3.9,2.2,10.1,2.2,13.9,0l166.8-96.9C479.363,168.95,479.363,165.35,475.462,163.15z M329.663,230.85c-1.5-1.5-3.4-2.9-5.6-4.1
		c-14.2-8.2-37.2-8.2-51.4,0c-1.1,0.6-2,1.3-2.9,2l-188.5-108.9c1.8-0.7,3.5-1.5,5.2-2.5c14.1-8.2,14.1-21.5-0.2-29.8
		c-1.6-0.9-3.2-1.7-5-2.4l77.7-45.2c1.2,1,2.5,2,4.1,2.9c14.2,8.2,37.2,8.2,51.4,0c1.6-0.9,3-1.9,4.2-3l189.1,109.2
		c-2.5,0.9-4.9,1.9-7,3.2c-14.1,8.2-14.1,21.5,0.2,29.8c2.2,1.3,4.6,2.3,7.2,3.2L329.663,230.85z M352.462,189.65l-12.3,7.2
		c-1.9,1.1-5,1.1-6.9,0l-21.6-12.5c-1.9-1.1-1.9-2.9,0-4l12.3-7.2c1.9-1.1,5-1.1,6.9,0l21.6,12.5
		C354.363,186.75,354.363,188.55,352.462,189.65z M166.563,96.55c-1.9,1.1-5,1.1-6.9,0l-21.6-12.4c-1.9-1.1-1.9-2.9,0-4l12.3-7.2
		c1.9-1.1,5-1.1,6.9,0l21.6,12.5c1.9,1.1,1.9,2.9,0,4L166.563,96.55z M456.563,212.85l21.8,12.6l-174.8,101.5l-5.7-3.3l-16.1-9.3
		l-269.8-155.9l21.6-12.6l269.8,155.9L456.563,212.85z M282.063,369.05l-270.1-156.2l21.6-12.5l270.1,156.2l152.6-88.7l21.7,12.5
		l-174.1,101.2l-5.6-3.3L282.063,369.05z M456.563,321.55l21.8,12.6l-174.8,101.5l-5.7-3.3l-16.1-9.3l-269.8-155.8l21.6-12.6
		l269.8,155.9L456.563,321.55z M456.263,376.55l21.7,12.5l-174.1,101.3l-5.6-3.3l-16.1-9.3l-270.2-156.2l21.6-12.5l270.1,156.2
		L456.263,376.55z M300.462,102.65c-30.8-17.8-80.6-17.8-111.3,0c-30.6,17.8-30.4,46.6,0.4,64.4s80.6,17.8,111.2,0
		C331.363,149.35,331.263,120.45,300.462,102.65z M291.063,115.75l-7.8,4.5c3.6,3.2,5.3,6.5,4.8,9.9c-0.4,3.3-2.7,6.4-7,9.2
		c-1.1,0.7-3,0.7-4.1,0l-10.6-5.9c-1-0.6-1.2-1.6-0.3-2.2c2.1-1.4,3.2-2.8,3.5-4.1c0.3-1.6-0.5-3-2.6-4.2s-4.2-1.7-6.5-1.5
		c-2.2,0.2-4.4,0.8-6.3,2c-2,1.2-3.1,2.4-3.2,3.7c-0.2,1.3,0.5,3.2,2.2,5.6c3.9,5.5,6,10.1,6.3,13.6s-2.2,6.8-7.5,9.8
		c-5,2.9-10.6,4.4-16.9,4.3c-5.3,0-10.5-1.1-15.6-3.1l-7.3,4.3c-1,0.6-2.5,0.5-3.5,0l-6.3-3.7c-1-0.6-1-1.5,0-2l7.1-4.1
		c-4.7-3.5-7.3-7.2-7.6-11.1c-0.4-3.7,2.1-7.2,7.3-10.5c1.1-0.7,3-0.7,4.1,0l10.6,5.9c1,0.6,1.2,1.6,0.3,2.2
		c-2.4,1.7-3.6,3.2-3.6,4.7c0,1.8,1.2,3.5,3.7,4.9c2.1,1.2,4.3,1.8,6.6,1.7s4.5-0.7,6.4-1.8c2.3-1.3,3.5-2.7,3.6-4
		c0.2-1.4-0.6-3.2-2.2-5.5c-4.2-5.5-6.4-10-6.7-13.4c-0.4-3.4,2.2-6.7,7.6-9.9c4.9-2.8,10.6-4.2,17-4.1c5.9,0.1,11.6,1.3,17.1,3.7
		l7.6-4.4c1-0.6,2.6-0.6,3.5,0l6.3,3.7C292.063,114.35,292.063,115.25,291.063,115.75z'
    />
  </svg>
)
