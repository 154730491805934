import { User } from 'app/firebase'
import { PaymentType } from 'app/firebase/functions/stripe.functions'
import { Order } from 'app/stlswing/components/Accounting/finance.types'
import { add, lte, prop, propEq } from 'ramda'
import { differenceInCalendarDays } from 'date-fns/fp'
import React, { useMemo } from 'react'

interface Props {
  attendees: User<true>[]
  orders: Order[]
  productId: string
}

export const AttendeesInfo = ({ orders, attendees, ...props }: Props) => {
  const attendeeOrders = useMemo(
    () =>
      orders.filter(
        (order) =>
          attendees.map(prop('id')).includes(order.internalUserId) &&
          order.internalProductId === props.productId,
      ),
    [attendees, orders, props.productId],
  )

  const ordersOfType = (type: PaymentType) =>
    attendeeOrders.filter(propEq('internalPaymentType', type))

  const aLaCarteAmountCurrent = useMemo(
    () =>
      ordersOfType('A La Carte')
        .map((order) => new Date(order.date))
        .map(differenceInCalendarDays(new Date()))
        .filter(lte(7)).length,
    [attendeeOrders, attendees],
  )

  const paymentTypeAmount = (type: PaymentType) => ordersOfType(type).length

  const getCurrentTotalAttendeesOfTypes = (types: PaymentType[]) =>
    types.map(paymentTypeAmount).reduce(add)

  return (
    <>
      <p className='pl-2 text-lg font-semibold'>Positions</p>
      <div className='flex w-full justify-evenly border-b py-4'>
        <p>
          Leads -{' '}
          {attendees.filter((attendee) => attendee.position === 'lead').length}
        </p>
        <p>
          Follows -{' '}
          {
            attendees.filter((attendee) => attendee.position === 'follow')
              .length
          }{' '}
        </p>
        <p>
          Switch -{' '}
          {
            attendees.filter((attendee) => attendee.position === 'switch')
              .length
          }
        </p>
      </div>

      <p className='pl-2 pt-2 text-lg font-semibold'>Order Types</p>
      <div className='flex w-full justify-evenly border-b py-4'>
        <p>Full Class - {getCurrentTotalAttendeesOfTypes(['Full Class'])}</p>
        <p>
          First Half -{' '}
          {getCurrentTotalAttendeesOfTypes(['First Half Of Class'])}
        </p>
        <p>
          Second Half -{' '}
          {getCurrentTotalAttendeesOfTypes([
            'Second Half Of Class',
            'Remaining Classes',
          ])}
        </p>
      </div>

      <p className='pl-2 pt-2 text-lg font-semibold'>User Types</p>
      <div className='flex w-full justify-evenly border-b py-4'>
        <p>
          Normal -{' '}
          {
            attendees.filter(
              (attendee) => !attendee.member && !attendee.studentDiscount,
            ).length
          }
        </p>
        <p>
          Members - {attendees.filter((attendee) => attendee.member).length}{' '}
        </p>
        <p>
          Students -{' '}
          {attendees.filter((attendee) => attendee.studentDiscount).length}
        </p>
      </div>

      <p className='pl-2 text-lg font-semibold'>A La Carte</p>

      <div className='flex w-full justify-evenly border-b py-4'>
        <p>Previous - {paymentTypeAmount('A La Carte')}</p>
        <p>Next Class - {aLaCarteAmountCurrent}</p>
      </div>
    </>
  )
}
