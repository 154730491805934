import { CollectionNames, Group, useFirestore, User } from 'app/firebase'

import {
  Button,
  ExpansionPanel,
  Loading,
  Modal,
  Tabs,
  UIContext,
  useRouter,
  useUI,
} from 'app/shared'
import {
  endOfPreviousDay,
  endOfPreviousMonth,
  startOfPreviousMonth,
  thisMonthInterval,
} from 'app/shared/utils/dates'
import {
  addWeeks,
  endOfDay,
  endOfMonth,
  endOfWeek,
  isAfter,
  isBefore,
  isThisMonth,
  isWithinInterval,
  startOfMonth,
  subDays,
  subWeeks,
} from 'date-fns'
import {
  prop,
  groupBy,
  flatten,
  uniq,
  values,
  any,
  uniqBy,
  props,
  product,
} from 'ramda'
import { useContext, useEffect, useMemo, useState } from 'react'
import { Order } from '../components/Accounting/finance.types'
import { GroupClass } from '../components/GroupClass/groupClass.types'
import { QRCODES, TEACHERS_MAP } from '../stlswing.constants'
import { Event } from '../stlswing.types'
import { Image } from 'app/shared/components/Media/Image'
import { AdminMobileHeader } from './AdminPage'
import { formatDate } from '../helpers/date.helpers'
import { CalendarHeader } from 'app/shared/components/Calendar/components'
import { getGroupClassOrders } from '../helpers/ticket.helpers'
import { AttendeesTable } from '../components/Shared/AttendeesTable/AttendeesTable'
import { GroupClassFormModal } from '../components'
import { LoggedInGuard } from 'app/auth/LoggedInGuard'
import { Firestore } from 'firebase/firestore'
import { FirestoreContext } from 'app/firebase/firestore/FirestoreContext'
import * as firebase from 'firebase/firestore'
import { firebaseApp } from 'environments'
import { Breadcrumbs } from 'app/shared/components/Layout/FullPageLayout/Header/Breadcrumbs'
import { getFirestoreCollection } from 'app/firebase/firestore/firestore.helpers'
import { useQuery } from 'react-query'
import { getClassTeachers } from 'app/firebase/functions/firebase.functions'

const getProductOrders = async (productId: string) => {
  return await getFirestoreCollection<Order>('orders', {
    where: [['internalProductId', '==', productId]],
    // orderBy: 'date',
  })
}

export const AttendeesPage = () => {
  const { routeParams } = useRouter<{
    productType: 'groupClasses' | 'events'
    productId: string
  }>()
  const { getSingle: getGroupClass } = useFirestore('groupClasses')
  const { getSingle: getEvent } = useFirestore('events')
  const [product, setProduct] = useState<Event<true> | GroupClass>()

  useEffect(() => {
    if (!routeParams.productType || !routeParams.productId) return
    getClassTeachers({
      classId: routeParams.productId,
    }).then(console.log)
  }, [])

  const { setShowFooter } = useContext(UIContext)

  const { data: productOrders, isLoading } = useQuery(
    [routeParams.productId, 'productOrders'],
    async () => getProductOrders(routeParams.productId),
    { refetchOnWindowFocus: true, refetchOnReconnect: true },
  )

  useEffect(() => {
    setShowFooter(false)
    return () => {
      setShowFooter(true)
    }
  }, [])

  useEffect(() => {
    const { productType, productId } = routeParams
    if (productType === 'groupClasses') {
      // @ts-ignore
      getGroupClass(productId).then(setProduct)
    }
    if (productType === 'events') {
      // @ts-ignore
      getEvent(productId).then(setProduct)
    }
  }, [])

  return (
    <div className='w-screen sm:w-full sm:min-w-full overflow-hidden bg-gray-200'>
      <div className='h-16 w-full bg-gray-800' />

      <LoggedInGuard afterLogin={() => ''}>
        <AdminMobileHeader title=''>
          <Breadcrumbs
            breadcrumbs={[
              { title: 'Dashboard', route: '/admin/Dashboard' },
              { title: `${product?.name}` },
            ]}
          />
        </AdminMobileHeader>

        {isLoading && (
          <div className='w-full h-full flex justify-center'>
            <Loading color='red' height='500' width='500' />
          </div>
        )}

        <div className='bg-white min-h-full min-w-full border-t'>
          {product && productOrders && (
            <AttendeesTable
              attendees={productOrders.map(
                ({ internalUserId }) => internalUserId,
              )}
              productId={product?.id}
            />
          )}

          {productOrders?.length === 0 && (
            <p className='flex w-full h-full justify-center items-center text-4xl text-red-600'>
              No Attendees Yet
            </p>
          )}
        </div>
      </LoggedInGuard>
    </div>
  )
}
